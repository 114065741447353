import { Component, Input, OnInit, ViewChildren } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute  } from '@angular/router';

@Component({
  selector: 'app-goods-receipt',
  templateUrl: './goods-receipt.component.html',
  styleUrls: ['./goods-receipt.component.css']
})

export class GoodsReceiptComponent implements OnInit {

  @ViewChildren('selPriduct') selPriduct: any;
  @ViewChildren('inputQty') inputQty: any;
  supplierAll:any;
  purch_date = <any>new Date();
  stkprd_all:any;
  srch_count:any;
  previewData: any[];
  purchaseItems = <any>[{
    purchsub_prd:<any>'',
    purchsub_qty:null,
    base_qty:null,
    purchsub_rate:null,
    purchsub_unit_id:'',
    batch_code:null,
    manufacture_date:null,
    expiry_date:null,
    purchsub_tax_per:null,
    prd_tax_cat_id:null,
    taxvalperqty:'',
    item_desc:'',
    purchsub_gd_id:'',
    prod_units: [],
    prod_batches:[],
    unit:'',
    // qty: null,
    loadingImg: false,
    isSecondOptionDisabled:true,
    rate2_dsply_qty:0,
    rate: null,
    disc_amnt: 0,
    item_disc_type:2,
    excl_vat:1,
    taxpercentage:null,
    itemRate: null,
    price_vat_ttl: null,
    base_unit_rate: null,
    item_disc: 0,
    purchsub_disc:0,
    item_bill_disc: 0,
    barcode:<any>'',
    stock_qty: 0,
    purchsub_tax_amnt:0,
    selRateQty:1
  }];
  godown_all:any;
  userdefltGwn:any;
  gd_id:any;
  valErrors = <any>{};
  price_group_id = <any>1;
  item_disc_types = [
    { id: 1, name: 'Rate' },
    { id: 2, name: '%' }
  ];

  vat_types = [
    { id: 1, name: 'Excl. Vat' },
    { id: 2, name: 'Incl. Vat' }
  ];
  taxProdCategories: any;
  barcode = null;
  barcodeloadingImg = false;
  totItemprice = <any>0;
  totItempriceWithoutBillDisc = <any>0;
  totItemDisc = <any>0;
  totItemDiscWithoutBillDisc = <any>0;
  totVatAmnt = <any>0;
  totVatAmntWithoutBillDisc = <any>0;
  purch_note = null;
  gds_rec_reference =null;
  gds_rec_ord_no = null;

  purchasePayType = [
    { id: 2, name: 'Cash' },
    { id: 1, name: 'Credit' },
    { id: 3, name: 'Bank / Card' },
    // { id: 4, name: 'Multi' }
  ];
  payTypes = ['Cash','Credit','Bank / Card'];
  
  purch_pay_type=<any>1;
  cashLedgers:any;
  bankLedgers:any;
  purch_inv_date:any;
  gds_rec_supp_dlvry_no:any;
  // enable_purchase_below_mrp :any;
  show_base_unit_in_print:any;
  // inv_types = [
  //   { id: 1, name: 'Tax Invoice' },
  //   { id: 2, name: 'Simplified Tax Invoice' },
  //   { id: 3, name: 'Invoice' },
  // ];
  ref_no = '';
  purch_is_include_tax =0;
  gds_rec_inv_no = '';
  purchase_types = [
    { id: 1, name: 'Standard Rated Domestic' },
    { id: 2, name: 'Zero Rated Domestic' },
    { id: 3, name: 'Exempt Purchase' }
  ];
  zeroType = false;
  purchtypesel: any = 1;
  billDiscount = null;
  // billDiscountTtl = 0;

  display_columns = [
    { id: 'descp', name: 'Description', show: false },
    { id: 'barcode', name: 'Barcode', show: false },
    // { id: 'item_disc', name: 'Item Discount', show: false },
    { id: 'tax', name: 'Tax %', show: false },
    // { id: 'excld_vat', name: 'Exclude VAT', show: false },
    { id: 'item_total', name: 'Item Total', show: false },
    { id: 'vat_amount', name: 'Vat Amount', show: true },
    { id: 'grand_ttl', name: 'Grand Total', show: true },
    { id: 'Set_this_rate', name: 'Set This Rate', show: false },
  ];
  tblSettings = {
    'descp':true,
    'barcode':true,
    // 'item_disc':true,
    'tax':true,
    // 'excld_vat':true,
    'item_total':true,
    'vat_amount':true,
    'grand_ttl':true,
    'Set_this_rate' :true,
  };
  prdcts= <any>[{}];
  // inv_type: any;
  terms: any;
  search_type: any;
  prd_tax_cat_id: any;
  // disable_purchase_without_stock: any;
  // enable_purchase_below_mrp:any;
  // purchase_print_name: any = 1;
  purchase_duplicate_Item = false;
  supp_id: any;
  purch_supp_id: any;
  purch_acc_ledger_id: any;
  purch_bank_ledger_id:any;
  validationMsg = '';
  pageLoadingImg = false;
  resultobj:any;
  cmpny:any;
  is_prew_description:any;
  qr_inv:any;
  previewLoader = false;
  listing = false;
  searchLoadingImg = false;
  list_purchase = [];
  curpage: any;
  lastpage: any;
  from: any;
  pgend: boolean;
  pgstart: boolean;
  base_url: string;
  savedTemplate = {
    logo: false,
    header_type: 1
  };
  logoImgUrl: string;
  tempImgUrl: string;
  footImgUrl: string;
  hide_item_discount : any;
  hide_vat : any;
  sup_balance: 0;
  sup_bal_sign: '';
  sup_tin : '';
  supp_default_currency_id : '';
  private modalRef: NgbModalRef;
  closeResult: string;
  isSubmitInProg = false;
  userType: any;
  ord_id = null;
  usr_hide_purch_cost: any;
  only_brcd: any;
  srch_length: any;
  is_bill_disc = false;
  country_dec: string;
  rate_set_for =[ 
    { value: 1, name: 'For Single' },
  ];

  inv_limit: any;
  inv_limit_no: any;
  current_dues: any;
  inv_due_msg: boolean;
  settings: any;
  srch_mtd: any;
  freight_charges_on_total: any;
  purch_sub_description: any;
  gds_rec_id = '';
  customer_dt = {
    id: '',
    ledger_id: '',
    name: '',
    alias: '',
    code: '',
    email : '',
    mobile: '',
    vat_no: '',
    op_bal: 0,
    brch_id:0,
    usr_type:0,
    is_supplier : false,
    op_bal_type: true,
    add: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    del: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    due_day: '',
    van_line: 0,
    price_group: 1,
    note: ''
  };
  editMessage = false;
  up: any;
  deleteStatus= false;
  enableAddBatch =false;
  checkBatch =false;
  unit_available_stock:any;
  available_stock:any;
  selectedSbId :any;
  prod_alias: any;
  cur_lang: string;
  g_settings: any;
  generalSearch_type: any;
  usr_block_pre_date_select: any;
  minDate: Date;
  // isSecondOptionDisabled: boolean = true;
  constructor(private apiService: ApiService, private coreService: CoreService,private modalService: NgbModal,private translate: TranslateService,private ActiveRoute: ActivatedRoute) { }

  ngOnInit() {
    this.getGeneralSetting();
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.aliasChecked()
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      this.aliasChecked()
    })
    this.up = this.coreService.getUserPrivilage();
    this.apiService.getClientSettingBykey({ key: 'Batch Support' }).subscribe((res) => {
      if (res['data']) {
        this.checkBatch = (res['data']['cs_value']) ? true : false;
      }
    });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.usr_hide_purch_cost = this.coreService.getUserData('usr_hide_purch_cost');
    this.langChange();
    this.ord_id = this.ActiveRoute.snapshot.paramMap.get('ord_id');
    if (this.ord_id && !isNaN(this.ord_id)){
      this.listing = true;
      // setTimeout(() => {
      //   $('#invPopup').click();
      // }, 10000);
    }
    this.base_url = this.apiService.PHP_API_SERVER;
    this.nextRefNo();
    this.getGodownList();
    this.getTemplete();
    this.userdefltGwn = this.coreService.getUserData('usr_default_godown_id');
    this.userType = this.coreService.getUserData('user_type');
    this.gd_id = this.userdefltGwn ? Number(this.userdefltGwn) : 0;
    this.apiService.getAllTaxCategory().subscribe((resp) => {
      this.taxProdCategories = resp.data.filter((taxCat) => Number(taxCat.is_international) == 0);
    });
    this.updatePurchaseTableSettigns();
    this.getPurchSetting();
    this.purch_acc_ledger_id = this.coreService.getUserData('usr_cash_ledger_id');
    if(!this.purch_acc_ledger_id){
      this.apiService.getBranchCashAccount().subscribe((led_res) => {
        this.cashLedgers= led_res.data.ledger;
        this.purch_acc_ledger_id = led_res.data.taxled_ledger_id;
      });
    }
    this.cmpny = this.coreService.getUserData('cmpny');
    this.listPurchase(1);
    this.usr_block_pre_date_select = this.coreService.getUserData("usr_block_pre_date_select");
    if(this.usr_block_pre_date_select){
      this.minDate= new Date();
    }else{
      this.minDate= new Date(this.coreService.getUserData("branch_open_date"));
    }

    this.country_dec=this.coreService.setDecimalLength();
  }
  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      this.prod_alias = 'alias_true';
      
    }else{
      this.prod_alias =null;
      
    }
  }
  langChange(){
    this.translate.get(['Table.description', 'Table.barcode', 'Table.item_discount', 'Common.tax', 'Common.Exclude_VAT', 'Table.item_total', 'Common.Vat_Amount', 'Common.Grand_Total', 'Common.standard_rated_domestic', 'Common.zero_rated_domestic', 'Common.exempt_purchase','Common.Set_this_rate']).subscribe((res: string) => {    
      this.display_columns = [
        { id: 'descp', name: res['Table.description'], show: false },
        { id: 'barcode', name: res['Table.barcode'], show: false },
        // { id: 'item_disc', name: res['Table.item_discount'], show: false },
        { id: 'tax', name: res['Common.tax']+' %', show: false },
        // { id: 'excld_vat', name: res['Common.Exclude_VAT'], show: false },
        { id: 'item_total', name: res['Table.item_total'], show: false },
        { id: 'vat_amount', name: res['Common.Vat_Amount'], show: true },
        { id: 'grand_ttl', name: res['Common.Grand_Total'], show: true },
        { id: 'Set_this_rate', name: res['Common.Set_this_rate'], show: false },
      ];

      this.purchase_types = [
        { id: 1, name: res['Common.standard_rated_domestic']},
        { id: 2, name: res['Common.zero_rated_domestic'] },
        { id: 3, name: res['Common.exempt_purchase'] }
      ];
    });
    this.translate.get(['Common.tax_invoice','Sales.excl_vat','Sales.incl_vat','Common.simplified_tax_invoice', 'Common.invoice']).subscribe((res: string) => {    
      // this.inv_types = [
      //   { id: 1, name: res['Common.tax_invoice'] },
      //   { id: 2, name: res['Common.simplified_tax_invoice'] },
      //   { id: 3, name: res['Common.invoice'] },
      // ];
      this.vat_types = [
        { id: 1, name:res['Sales.excl_vat'] },
        { id: 2, name:res['Sales.incl_vat']}
      ];

    });
  }
  ngAfterViewInit() {
    if (this.ord_id && !isNaN(this.ord_id)){
      setTimeout(() => {
        $('#invPopup').click();
      }, 5000);
    }
    setTimeout(() => {
      this.selPriduct.last.focus();
    }, 500);
  }
  openModal(content: any, size) {
    this.modalRef = this.modalService.open(content, { size: size, backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  getPurchSetting() {
    this.apiService.getPurchSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.purch_pay_type = this.settings.ps_pay_type;
        this.purchtypesel = this.settings.ps_purch_type;
        this.srch_mtd = this.settings.ps_srch_mtd;
        this.freight_charges_on_total = this.settings.ps_freight_charges_on_total;
        this.purch_sub_description = this.settings.ps_show_purch_sub_description; 
      } else { // Default values if not setted
        this.purch_pay_type = 1;
        this.purchtypesel = 1;
        this.srch_mtd = 1;
        this.freight_charges_on_total = 0;
        this.purch_sub_description = 0;
      }
    });
  }

  // updatePayment(){
  //   if(this.inv_type == 2){
  //     this.purch_pay_type = 2;
  //   }else{
  //     this.purch_pay_type = 1;
  //   }
  //   // this.changePayType();
  // }

  updatePurchaseTableSettigns(){
    let tabeleSett = this.coreService.getLocalStorage('tds');
    let display_columns = [];
    if(tabeleSett){
      display_columns = tabeleSett;
    } else{
      display_columns = this.display_columns;
    }
   
    display_columns.forEach((val)=>{
      this.tblSettings[val.id] = !val.show;
    });
  }

  updateTableSettings(){
    this.coreService.setLocalStorage('tds', JSON.stringify(this.display_columns));
    this.updatePurchaseTableSettigns();
  }

  searchCashLedger(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    searchval.append('ledger_grp', '3');
    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.cashLedgers = res['data'];
    });
  }

  searchBankLedger(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    searchval.append('ledger_grp', '4');
    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.bankLedgers = res['data'];
    });
  }

  addNewRow() {
    if(this.validateSelectedGodown()){
      this.purchaseItems.push({
        purchsub_prd:'',
        purchsub_qty:null,
        base_qty:null,
        purchsub_rate:null,
        purchsub_unit_id:'',
        batch_code:null,
        enableAddBatch :false,
        manufacture_date:null,
        expiry_date:null,
        purchsub_tax_per:null,
        prd_tax_cat_id:null,
        taxvalperqty:'',
        item_desc:'',
        purchsub_gd_id:'',
        prod_units: [],
        prod_batches:[],
        unit: '',
        // qty: null,
        loadingImg: false,
        isSecondOptionDisabled:true,
        rate2_dsply_qty:0,
        rate: null,
        rate_org:null,
        disc_amnt: 0,
        item_disc_type:null,
        excl_vat:1,
        taxpercentage:null,
        itemRate: null,
        price_vat_ttl: null,
        base_unit_rate: null,
        item_disc: 0,
        item_bill_disc: 0,
        barcode:'',
        stock_qty:0,
        purchsub_tax_amnt:0,
        selRateQty: 1,
        purchsub_frieght : 0,
      });
      setTimeout(() => {
        this.selPriduct.last.focus();
      }, 500);
    }
  }
  remove(index) {
    this.purchaseItems.splice(index, 1);
    delete this.valErrors['rate_' + index]
    this.sumPurchaseTotal();
  }
  nextRefNo() {
    let searchval = new FormData();
    this.apiService.getGoodsRecNo(searchval).subscribe((res) => {
      this.ref_no = res['data'].goods_rec_num;
    });
  }

  // form search supplier
  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
    });
  }
  searchSupplierAlias(search: string) {
      const searchval = new FormData();
      searchval.append('supp_name', search);
      searchval.append('with-alias','1'); 
      this.apiService.searchSupplier(searchval).subscribe((res) => {
        this.supplierAll = res.data;
      });
  }
  getSupBalance(sup_id, $exchngeRate = null) {
      if(sup_id){
          this.apiService.getSupBalance({ sup_id: sup_id }).subscribe((res) => {
          this.sup_balance = res.data.bal_without_sign;
          this.sup_bal_sign = res.data.bal_sign;
          this.sup_tin = res.data.supp_tin;
          this.supp_default_currency_id = res.data.default_currency_id;
        });
      }
  }

  searchStkPrd(search: string, i) {
    let searchval = new FormData();
    if(this.prod_alias){
      searchval.append('alias_search', '2');
    }
    searchval.append("prod_name", search);
    // searchval.append('stk_stat', '1');
    searchval.append('godown_id', this.gd_id);
    this.apiService.quicPurchkSearch(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];
      this.srch_count = this.stkprd_all.length;
    });
  }

  getGodownList() {
    let searchval = new FormData();
    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godown_all = res['data'];
    });
  }

  selectedProduct(i, isBracodeSearch =false){
    if(this.purchaseItems[i].purchsub_prd && this.validateSelectedGodown()){
    // if(this.validateSelectedGodown()){
      this.getStockDetails(i, isBracodeSearch);
    } else{
      this.purchaseItems[i] = {
        purchsub_prd:'',
        purchsub_qty:null,
        base_qty:null,
        purchsub_rate:null,
        purchsub_unit_id:'',
        batch_code:null,
        enableAddBatch :false,
        manufacture_date:null,
        expiry_date:null,
        purchsub_tax_per:null,
        prd_tax_cat_id:null,
        taxvalperqty:'',
        item_desc:'',
        purchsub_gd_id:'',
        prod_units: [],
        prod_batches:[],
        unit:'',
        // qty: null,
        loadingImg: false,
        isSecondOptionDisabled:true,
        rate2_dsply_qty:0,
        rate: null,
        rate_org: null,
        disc_amnt: 0,
        item_disc_type:null,
        excl_vat:1,
        taxpercentage:null,
        itemRate: null,
        price_vat_ttl: null,
        base_unit_rate: null,
        item_disc: 0,
        item_bill_disc: 0,
         barcode:'',
         purchsub_tax_amnt:0,
         selRateQty:1
      };
    }
  }
  updateTaxPerc(i) {
    const selTax = this.taxProdCategories.filter((taxCat) => taxCat.taxcat_id === this.purchaseItems[i].prd_tax_cat_id);
    this.purchaseItems[i].purchsub_tax_per = selTax[0].taxcat_tax_per;
    this.calcRates(i);
  }

  searchStkPrdBarcode() {
    if(this.validateBarcode() && this.validateSelectedGodown()){
      let searchval = new FormData();
      searchval.append("barcode", this.barcode);
      this.barcodeloadingImg = true;
      this.apiService.getStockbyBarcodePurchase(searchval).subscribe((res) => {
        if (res.data) {
          if(!this.purchase_duplicate_Item){
            var existArrayInx = this.purchaseItems.findIndex((x =>
              x.purchsub_prd.prd_id === res.data.prd_id &&
              (x.item_desc == '' || x.item_desc == null) &&
              x.purchsub_unit_id === res.data.produnit_unit_id &&
              x.rate === res.data.bs_srate
            ));
          }
          if(!this.purchase_duplicate_Item && existArrayInx != -1){
            this.purchaseItems[existArrayInx].purchsub_qty +=1;
            this.calcRates(existArrayInx);
          } else{
             // do not add new row, if last row not selected any item
            if(this.purchaseItems[this.purchaseItems.length-1].purchsub_prd){
              this.addNewRow();
            }
            let lastRowIndex = this.purchaseItems.length-1;
            this.purchaseItems[lastRowIndex].purchsub_prd = res['data'];
            this.selectedProduct(lastRowIndex, true);
          }
        } else {
          this.valErrors.barcode = {msg : 'No product found'}
        }
        this.barcode = '';
        this.barcodeloadingImg = false;
      });
    }
  }

  getStockDetails(i, isBracodeSearch) {
    // this.purchaseItems[i].batch_code=null;
    // this.purchaseItems[i].manufacture_date=null;
    // this.purchaseItems[i].expiry_date=null;
    this.purchaseItems[i].loadingImg = true;
    let prd_id = this.purchaseItems[i].purchsub_prd.prd_id;
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    searchval.append('gd_id', this.gd_id);
    searchval.append('price_group_id', this.price_group_id);
    this.apiService.getPurchaseProduct1(searchval).subscribe((res) => {
      let selProdInfo = res['data'];
      this.purchaseItems[i].rate = selProdInfo.purchase_rate;
      this.purchaseItems[i].purchsub_qty = 1;
      this.purchaseItems[i].prod_units = selProdInfo.units;
      this.purchaseItems[i].prod_batches = selProdInfo.batches1;
      this.purchaseItems[i].loadingImg = false;
      this.purchaseItems[i].prd_tax_cat_id = selProdInfo.prd_tax_cat_id ;
       this.purchaseItems[i].purchsub_tax_per = selProdInfo.prd_tax;
     // this.purchaseItems[i].purchsub_tax_per = 4;
      this.purchaseItems[i].base_unit_rate = selProdInfo.purchase_rate;
      this.purchaseItems[i].barcode = selProdInfo.prd_barcode;
      this.purchaseItems[i].item_desc = selProdInfo.prd_remarks;
      this.purchaseItems[i].item_disc_type = 1;
      this.purchaseItems[i].purchsub_gd_id = this.gd_id;
      this.purchaseItems[i].purchsub_prd_id = selProdInfo.prd_id;
      this.purchaseItems[i].rate2_dsply_qty = selProdInfo.bs_rate2_display_qty;
      // purchsub_unit_id
      // current stock of product, actully chk on the time of save for accurate result
      // this.purchaseItems[i].stock_qty = selProdInfo.current_stock;
      this.purchaseItems[i].stock_qty = selProdInfo.current_stock;
      this.purchaseItems[i].unit_available_stock= selProdInfo.current_stock;
      this.purchaseItems[i].available_stock= selProdInfo.current_stock;
      this.purchaseItems[i].selectedSbId= 0;

      this.purchaseItems[i].prd_location = selProdInfo.pl_location;
      this.purchaseItems[i].purchsub_frieght =0;
      this.purchaseItems[i].purchsub_stock_id =selProdInfo.cmp_stock_id;
      // if(!isBracodeSearch && selProdInfo.prd_base_unit_id){
      //   this.purchaseItems[i].purchsub_unit_id =selProdInfo.prd_base_unit_id; 
      // }else{
      //   this.purchaseItems[i].purchsub_unit_id = (this.purchaseItems[i].purchsub_prd.unit_id ? this.purchaseItems[i].purchsub_prd.unit_id : selProdInfo.prd_base_unit_id);
      // }
      if(!isBracodeSearch && selProdInfo.prd_def_unit_id){
        this.purchaseItems[i].purchsub_unit_id =selProdInfo.prd_default_unit_id;
      }else{
        this.purchaseItems[i].purchsub_unit_id = (this.purchaseItems[i].purchsub_prd.unit_id ? this.purchaseItems[i].purchsub_prd.unit_id : selProdInfo.prd_base_unit_id);
      }
      this.purchaseItems[i].batch_code = this.purchaseItems[i].batch_code;
      this.purchaseItems[i].manufacture_date = this.purchaseItems[i].manufacture_date;
      this.purchaseItems[i].expiry_date = this.purchaseItems[i].expiry_date; 
      // this.barcode = this.selctedProd.prd_barcode;
      this.selectedUnit(i);
      if(!isBracodeSearch){
        setTimeout(() => {
          $('.inputQty').focus();
          $('.inputQty').select();
        }, 500);
      }
    });

  }

  focusRateInput(event){
    $('.inputRate').focus();
    $('.inputRate').select();
    return false;
  }
  selectBatch(i) {
    const prdbatches = this.purchaseItems[i].prod_batches.filter((prdbatches) => prdbatches.sb_batch_code === this.purchaseItems[i].batch_code.sb_batch_code);
    this.purchaseItems[i].manufacture_date = new Date((prdbatches[0].sb_manufacture_date));
    this.purchaseItems[i].expiry_date = new Date((prdbatches[0].sb_expiry_date));
    //this.purchaseItems[i].batchSellRate =[];
    var batch_id = this.purchaseItems[i].batch_code.sb_id;
    this.purchaseItems[i].batch_code = this.purchaseItems[i].batch_code.sb_batch_code;
    this.purchaseItems[i].selectedSbId =batch_id;
  // if(batch_id > 0){   
  //   const searchval = new FormData();
  //   searchval.append('prd_id', this.purchaseItems[i].purchsub_prd.prd_id);
  //   searchval.append('gd_id', this.gd_id);
  //   searchval.append('price_group_id', this.price_group_id);
  //   searchval.append('batch_id',batch_id);
    
  //   this.apiService.getGodownBatchStock(searchval).subscribe((resp1) => {
      
  //    this.purchaseItems[i].unit_available_stock = (resp1['data']['gdStock'].gbs_qty) ? resp1['data']['gdStock'].gbs_qty : 0 ;
  //     this.selectedUnit(i);
  //   });
  // } else{
   
  //   this.purchaseItems[i].unit_available_stock =  this.purchaseItems[i].stock_qty;
    
  // }
  this.selectedUnit(i);
  }
  
  selectedUnit(i) {
    const prdUnts = this.purchaseItems[i].prod_units.filter((ProdUnits) => ProdUnits.unit_id === this.purchaseItems[i].purchsub_unit_id);
    // var unit_rate = (this.purchaseItems[i].selRateQty == 1) ? prdUnts[0].sur_unit_rate : prdUnts[0].sur_unit_rate2;
    this.purchaseItems[i].rate = ( prdUnts[0].unit_base_qty * this.purchaseItems[i].base_unit_rate);
    this.purchaseItems[i].rate_org = ( prdUnts[0].unit_base_qty * this.purchaseItems[i].base_unit_rate);
    this.purchaseItems[i].base_qty = prdUnts[0].unit_base_qty;
    if(this.purchaseItems[i].selectedSbId > 0){
      const searchval = new FormData();
      searchval.append('prd_id', this.purchaseItems[i].purchsub_prd.prd_id);
      searchval.append('gd_id', this.gd_id);
      searchval.append('price_group_id', this.price_group_id);
      searchval.append('batch_id',this.purchaseItems[i].selectedSbId);
      this.apiService.getGodownBatchStock(searchval).subscribe((resp) => {
       this.purchaseItems[i].unit_available_stock = resp['data']['gdStock'].gbs_qty / this.purchaseItems[i].base_qty || 0;
       // this.selectedUnit(i);
      });
    }
    else{
      this.purchaseItems[i].unit_available_stock =  this.purchaseItems[i].available_stock / this.purchaseItems[i].base_qty;
    }
    var unit_name;
    unit_name =prdUnts[0].unit_name;
    this.rate_set_for =[ 
      { value: 1, name: 'For Single ' + unit_name},
    ]
    if(parseInt(this.purchaseItems[i].purchsub_qty) != 1 && !isNaN(parseInt(this.purchaseItems[i].purchsub_qty)) && this.purchaseItems[i].purchsub_qty != ''){
      this.rate_set_for.push({ value: this.purchaseItems[i].purchsub_qty, name: 'For ' + this.purchaseItems[i].purchsub_qty + ' ' + unit_name});
    }
    this.calcRates(i);
  }

  calcRates(i, isBillDiscount=false) {
    if(!isBillDiscount){
      this.billDiscount = 0;
      this.purchaseItems[i].item_bill_disc = 0;      
    }
    let qty = this.purchaseItems[i].purchsub_qty ? this.purchaseItems[i].purchsub_qty : 1;
    let qty1 = this.purchaseItems[i].purchsub_qty*this.purchaseItems[i].base_qty;
    if(qty1>=this.purchaseItems[i].rate2_dsply_qty){
      this.purchaseItems[i].isSecondOptionDisabled =false;
    }else{
      this.purchaseItems[i].selRateQty=1;
      this.purchaseItems[i].isSecondOptionDisabled =true;
      // this.selectedUnit(i);  
    }
    let rate = this.purchaseItems[i].rate ? Number(this.purchaseItems[i].rate) : 0;
    let itemDisc = this.purchaseItems[i].disc_amnt ? this.purchaseItems[i].disc_amnt : 0;
    let purchAmnt = qty * rate;
    if (this.purchaseItems[i].item_disc_type != 1) {
      itemDisc = (purchAmnt * itemDisc) / 100;
    }
    let itemDiscWithoutBillDisc = itemDisc;
    itemDisc = Number(itemDisc) + Number(this.purchaseItems[i].item_bill_disc);
    this.purchaseItems[i].item_disc = itemDisc;
    this.purchaseItems[i].purchsub_disc = itemDisc/this.purchaseItems[i].purchsub_qty;
    // let rateWithoutBillDisc = rate;
    let itemTotal = 0;
    let itemTotalWithoutBillDisc = 0;
    let rateWithoutBillDisc = rate;
    //tax zero type
    if(this.zeroType){
      this.purchaseItems[i].purchsub_tax_amnt = 0;
      // this.purchaseItems[i].purchsub_tax_amnt_WithoutBillDisc = 0;
      // itemTotal = purchAmnt;
      // itemTotalWithoutBillDisc = purchAmnt;
      this.purchaseItems[i].purchsub_tax_amnt_WithoutBillDisc = ((purchAmnt - (itemDiscWithoutBillDisc)) * this.purchaseItems[i].purchsub_tax_per) / 100;
      itemTotal = purchAmnt + this.purchaseItems[i].purchsub_tax_amnt;
      itemTotalWithoutBillDisc = purchAmnt + this.purchaseItems[i].purchsub_tax_amnt_WithoutBillDisc;
    } else {
      if (this.purchaseItems[i].excl_vat == 1) {
        this.purchaseItems[i].purchsub_tax_amnt = ((purchAmnt - (itemDisc)) * this.purchaseItems[i].purchsub_tax_per) / 100;
        this.purchaseItems[i].purchsub_tax_amnt_WithoutBillDisc = ((purchAmnt - (itemDiscWithoutBillDisc)) * this.purchaseItems[i].purchsub_tax_per) / 100;
        itemTotal = purchAmnt + this.purchaseItems[i].purchsub_tax_amnt;
        itemTotalWithoutBillDisc = purchAmnt + this.purchaseItems[i].purchsub_tax_amnt_WithoutBillDisc;
      }
    }
    this.purchaseItems[i].taxvalperqty111 = this.purchaseItems[i].purchsub_qty * this.purchaseItems[i].base_qty;
    this.purchaseItems[i].taxvalperqty222 = this.purchaseItems[i].rate / this.purchaseItems[i].base_qty;
    this.purchaseItems[i].taxvalperqty = this.purchaseItems[i].purchsub_tax_amnt / qty;
    // let itemDiscWithoutBillDisc = itemDisc;
    this.purchaseItems[i].item_disc_WithoutBillDisc = itemDiscWithoutBillDisc
    //this.purchaseItems[i].purchsub_rate = rate;
    this.purchaseItems[i].purch_price = rate * qty - itemDisc;
    this.purchaseItems[i].purchsub_rate_without_bill_disc = rateWithoutBillDisc;
    this.purchaseItems[i].price_vat_ttl = itemTotal - itemDisc;
    this.purchaseItems[i].grnd_totl_WithoutBillDisc = itemTotalWithoutBillDisc - itemDiscWithoutBillDisc;
    if(isBillDiscount==true)
    {
      this.purchaseItems[i].purchsub_rate = this.purchaseItems[i].purchsub_rate_without_bill_disc - this.purchaseItems[i].purchsub_disc;
      this.purch_is_include_tax =1;
    }
    else{
      this.purchaseItems[i].purchsub_rate = rate;
      this.purch_is_include_tax =0;
    }
    // isBillDiscount=false;

    
    // if(isBillDiscount==false){
    //   this.purchaseItems[i].purchsub_rate = rate;
    // }
    this.sumPurchaseTotal();
    this.purchaseItems[i].purchsub_gd_id = this.gd_id;
  }

  sumPurchaseTotal() {
    this.totItemprice = 0;
    this.totItempriceWithoutBillDisc = 0;
    this.totItemDisc = 0;
    this.totItemDiscWithoutBillDisc = 0;
    this.totVatAmnt = 0;
    this.totVatAmntWithoutBillDisc = 0;
    // this.billDiscountTtl = 0;
    for (var i = 0; i < this.purchaseItems.length; i++) {
      this.totItemprice = Number(this.totItemprice) + (Number(this.purchaseItems[i]['purchsub_qty']) * Number(this.purchaseItems[i]['rate']));
      this.totItempriceWithoutBillDisc = Number(this.totItempriceWithoutBillDisc) + (Number(this.purchaseItems[i]['purchsub_qty']) * Number(this.purchaseItems[i]['purchsub_rate_without_bill_disc']));
      this.totItemDisc = Number(this.totItemDisc) + Number(this.purchaseItems[i]['item_disc']);
      this.totItemDiscWithoutBillDisc = Number(this.totItemDiscWithoutBillDisc) + Number(this.purchaseItems[i]['item_disc_WithoutBillDisc']);
      this.totVatAmnt = Number(this.totVatAmnt) + Number(this.purchaseItems[i]['purchsub_tax_amnt']);
      this.totVatAmntWithoutBillDisc = Number(this.totVatAmntWithoutBillDisc) + Number(this.purchaseItems[i]['purchsub_tax_amnt_WithoutBillDisc']);
    }
  }

  discountShareToItems(){
    this.is_bill_disc = false;
    let gttl = this.totItempriceWithoutBillDisc - this.totItemDiscWithoutBillDisc ;
    let InpbillDisc = this.billDiscount || 0;
    this.purchaseItems.forEach((element,i) => {
      let grnd_totl = this.purchaseItems[i].grnd_totl_WithoutBillDisc;
      let billDiscPrec = grnd_totl * 100/ gttl;
      let billDiscountAmt = (InpbillDisc/100 * billDiscPrec);
      if(billDiscountAmt > 0){
        if (this.purchaseItems[i].excl_vat == 1) {
          this.purchaseItems[i].item_bill_disc =  (this.purchaseItems[i].rate * this.purchaseItems[i].purchsub_qty) - (((grnd_totl  - billDiscountAmt) * 100) / (this.purchaseItems[i].purchsub_tax_per + 100));
        } else {
          this.purchaseItems[i].item_bill_disc = billDiscountAmt;
        }
      } else{
        this.purchaseItems[i].item_bill_disc = 0;
      }
      this.calcRates(i,true);
    });

  }
  discountToAll(){
    this.is_bill_disc = true;
    if(this.billDiscount){
      this.totItemDisc = this.billDiscount
    }else{
      this.totItemDisc = 0;
    }  
  }
  clearAllDiscounts(){
    this.billDiscount = 0;
    this.totItemDisc = 0;
    this.clearDiscountShareToItems();
  }
  clearDiscountShareToItems(){
    let gttl = this.totItempriceWithoutBillDisc - this.totItemDiscWithoutBillDisc + this.totVatAmntWithoutBillDisc;
    let InpbillDisc = 0;
    this.purchaseItems.forEach((element,i) => {
      let grnd_totl = this.purchaseItems[i].grnd_totl_WithoutBillDisc;
      let billDiscPrec = grnd_totl * 100/ gttl;
      let billDiscountAmt = (InpbillDisc/100 * billDiscPrec);
      if(billDiscountAmt > 0){
        if (this.purchaseItems[i].excl_vat == 1) {
          this.purchaseItems[i].item_bill_disc =  (this.purchaseItems[i].rate * this.purchaseItems[i].purchsub_qty) - (((grnd_totl  - billDiscountAmt) * 100) / (this.purchaseItems[i].purchsub_tax_per + 100));
        } else {
          this.purchaseItems[i].item_bill_disc = billDiscountAmt;
        }
      } else{
        this.purchaseItems[i].item_bill_disc = 0;
      }
      this.calcRates(i,true);
    });

  }

  validateSelectedGodown(){
    this.valErrors = {};
    if (this.gd_id === "" || this.gd_id === undefined) {
      this.valErrors.gd_id = {msg : 'Required'}
      return false;
    } else{
      return true;
    }
  }
  validateBarcode(){
    this.valErrors = {};
    if (!this.barcode || this.barcode === "" || this.barcode === undefined) {
      this.valErrors.barcode = {msg : 'Enter Barcode'}
      return false;
    } else{
      return true;
    }
  }
  godonwChange(){
    delete this.valErrors.gd_id;
    this.resetProdForm();
  }

  resetProdForm(){
  }
  updateDate(cust) {
    if (cust) {
      let date = new Date(this.purch_date);
      // if (cust.due_days) {
      //   date.setDate(date.getDate() + Number(cust.due_days));
      // } else {
      //   date.setDate(date.getDate() + 30);
      // }
      this.purch_inv_date = new Date(date);
    }
  }

  selectTaxType(ptypeid) {
    // purchase_types = [
    //   { id: 1, name: 'Standard Rated Domestic' },
    //   { id: 2, name: 'Zero Rated Domestic' },
    //   { id: 3, name: 'Exempt Purchase' }
    if (ptypeid === 3 || ptypeid === 2) {
      // to set tax category with 0% tax percentage
      // this.taxListCategories = this.taxProdCategories.filter((taxCat) => Number(taxCat.taxcat_tax_per) == 0);
      // if (this.taxListCategories.length > 0) {
      //   this.prd_tax_cat_id = this.purch_type_taxcat_id = this.taxListCategories[0].taxcat_id;
      //   this.taxpercentage = this.taxListCategories[0].taxcat_tax_per
      // }
      this.zeroType = true;
    } else {
      this.zeroType = false;
    }
    this.discountShareToItems();
  }
  vaidateForm(){
    this.valErrors = {};
    let retVal = true;
    if (this.gd_id === "" || this.gd_id === undefined) {
      this.valErrors.gd_id = {msg : 'Required'}
      retVal =  false;
    }
    // if (this.purch_pay_type == 2 || this.purch_pay_type == 4){
    //   if (this.purch_acc_ledger_id === "" || this.purch_acc_ledger_id === undefined || this.purch_acc_ledger_id === null || this.purch_acc_ledger_id == "0") {

    //     this.valErrors.purch_acc_ledger_id = {msg : 'Required'}
    //     retVal =  false;
    //   }
    // }
    // if (this.purch_pay_type == 3 || this.purch_pay_type == 4){
    //   if (this.purch_bank_ledger_id === "" || this.purch_bank_ledger_id === undefined || this.purch_bank_ledger_id === null || this.purch_bank_ledger_id == "0") {
    //     this.valErrors.purch_bank_ledger_id = {msg : 'Required'}
    //     retVal =  false;
       
    //   }
    // }
    // if(this.inv_type==1 && (this.gds_rec_inv_no == '' || this.gds_rec_inv_no == null)){
    //   this.valErrors.gds_rec_inv_no = {msg : 'Required'}
    //     retVal =  false;
    // }
    let below_mrp_alert = false;
    this.purchaseItems.forEach((element, i) => {
      if(element.purchsub_prd == ''){
        this.valErrors['purchsub_prd_' + i] = {msg : 'Required'}
        retVal =  false;
      } else{
        if(element.purchsub_qty == '' || element.purchsub_qty <= 0){
          this.valErrors['purchsub_qty_' + i] = {msg : 'Required'}
          retVal =  false;
        }
        // if((element.stock_qty<=0 && this.disable_purchase_without_stock) || (this.disable_purchase_without_stock && (element.stock_qty < element.purchsub_qty))){
        //   alert("Purchase without stock is disabled");
        //   this.valErrors['purchsub_qty_' + i] = {msg : 'Purchase without stock'}
        //   retVal =  false;
        // }
        if(element.rate == ''){
          this.valErrors['rate_' + i] = {msg : 'Required'}
          retVal =  false;
        }
        // if (!this.enable_purchase_below_mrp && (element.rate < element.rate_org)  && !this.enable_purchase_below_mrp) {
        //   if(!below_mrp_alert){
        //     alert("Purchase below Purchase Rate is disabled");
        //   }
        //   this.valErrors['rate_' + i] = {msg : 'Purchase below Purchase Rate'}
        //   retVal =  false;
        //   below_mrp_alert = true;
        // }

      }
      if(element.batch_code != null)
      {
        if(element.manufacture_date == null)
        {
          this.valErrors['manufacture_date_' + i] = {msg : 'Required'}
          retVal =  false;
        }
        if(element.expiry_date == null)
        { 
          this.valErrors['expiry_date_' + i] = {msg : 'Required'}
          retVal =  false;
        }
      }
    });
    return retVal;
  }
  addGoodsReceipt(print=false) {
    this.validationMsg = '';
    this.resultobj = '';
    if(this.vaidateForm()){
      let sqdisc = 0;
      if(!this.is_bill_disc){
        sqdisc = 0;
      }else{
        sqdisc = this.billDiscount;
      }
      this.purch_date=(this.purch_date) ?this.apiService.formatDate(this.purch_date) : this.purch_date;
      this.purch_inv_date=(this.purch_inv_date) ?this.apiService.formatDate(this.purch_inv_date) : this.purch_inv_date;
      const searchval = {
        'gds_rec_ord_no':this.gds_rec_ord_no,
        'gds_rec_purch_is_include_tax':this.purch_is_include_tax,
        'gds_rec_date': this.purch_date,
        'gds_rec_inv_date': this.purch_inv_date,
        'gds_rec_supp_dlvry_no':this.gds_rec_supp_dlvry_no,
        'gds_rec_purch_amount': this.totItemprice - this.totItemDisc + this.totVatAmnt,
        'gds_rec_purch_discount': this.totItemDisc,
        //'sq_disc': sqdisc,
        'gds_rec_purch_tax': this.totVatAmnt,
        'items': this.purchaseItems,
        'terms': this.terms,
        'gds_rec_note': this.purch_note,
        'gds_rec_reference':this.gds_rec_reference,
        'gds_rec_gd_id': this.gd_id,
        //'purch_tax_type': this.purchtypesel - 1,
        'gds_rec_purch_type' : this.purchtypesel,
        'gds_rec_purch_pay_type': this.purch_pay_type,
        'gds_rec_purch_acc_ledger_id': (this.purch_pay_type == 2) ? this.purch_acc_ledger_id : this.purch_bank_ledger_id,
        // 'inv_type': this.inv_type,
        'gds_rec_inv_no': this.gds_rec_inv_no,
        'gds_rec_supp_id' : (this.supp_id) ? this.supp_id.supp_id : '',
        'gds_rec_flag' : 1,   
      }
      // if(this.cmpny != 'FJK' && this.cmpny != 'FJKIN'){
      //   this.coreService.showMessage('Quick Purchase Temporarily Blocked');
      //   return false;
      // }
      this.pageLoadingImg = true;
      this.apiService.addGoodsReceipt(searchval).subscribe((res) => {
        if (res.error != null) {
          this.resultobj = res.error;
          this.pageLoadingImg = false;
          for (var value in this.resultobj) {
            this.validationMsg += this.resultobj[value].toString() + '<br>';
          }
        }
        if (res.message) {
          if(print){
            this.previewData = res.preview;
            this.is_prew_description = this.previewData['items'].filter(x => x.purchsub_item_desc);
            this.previewLoader = false;
            $('#printviewBtn').click();
            this.coreService.showMessage(res.message);
            this.apiService.updatebatchExNotification();
          } else{
            this.coreService.showMessage(res.message);
          }
          this.pageLoadingImg = false;
          this.supp_id =null;
          this.sup_balance =null;
          this.sup_tin =null;
          this.purch_inv_date = null;
          this.gds_rec_supp_dlvry_no =null;
          this.gds_rec_inv_no = '';
          this.purch_note =null;
          this.gds_rec_reference =null;
          this.gds_rec_ord_no =null;
          this.purchtypesel =1;
          this.totItemDisc =null;
          this.billDiscount = 0;
          this.totVatAmnt = 0;
          this.totItemprice = 0;
          this.totItempriceWithoutBillDisc = 0;
          this.totItemDisc = 0;
          this.totItemDiscWithoutBillDisc = 0;
          this.totVatAmntWithoutBillDisc = 0;
          this.gd_id =0;
          this.purchaseItems = [{
            purchsub_prd:'',
            purchsub_qty:null,
            base_qty:null,
            purchsub_rate:null,
            purchsub_unit_id:'',
            batch_code:null,
            enableAddBatch :false,
            manufacture_date:null,
            expiry_date:null,
            purchsub_tax_per:null,
            prd_tax_cat_id:null,
            taxvalperqty:'',
            item_desc:'',
            purchsub_gd_id:'',
            prod_units: [],
            prod_batches:[],
            unit: '',
            // qty: null,
            loadingImg: false,
            rate: null,
            disc_amnt: 0,
            item_disc_type:null,
            excl_vat:1,
            taxpercentage:null,
            itemRate: null,
            price_vat_ttl: null,
            base_unit_rate: null,
            item_disc: 0,
            purchsub_disc:0,
            item_bill_disc: 0,
            barcode:'',
            stock_qty:0,
            selRateQty:1,
            purchsub_frieght:0,
          }];
          this.listPurchase(1);
          this.nextRefNo();
          this.sumPurchaseTotal();
          setTimeout(() => {
            this.selPriduct.last.focus();
          }, 500);
          this.searchSupplier('');
        }
      });
    }
  }

  showList(val) {
    if (val) {
      this.listing = false; // Invoice Page
      // setTimeout(() => {
      //   this.newInvoice(this.listing);
      // }, 500);
    } else {
      this.listing = true; // Listing Page
    }
    this.editMessage =false;
    this.clearForm();
  }
  listPurchase(pageNo = 1, keyword='') {
    this.deleteStatus =false;
    let searchval = new FormData();
    if(keyword != '')
    searchval.append("keyword", keyword);
    this.searchLoadingImg = true;
    this.apiService.getGoodsReceiptsList(searchval, pageNo).subscribe((res) => {
      this.list_purchase = res.data.data;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.searchLoadingImg = false;
    });
  }

  
  previewGoodsReceipt(id) {
    const searchval = new FormData();
    searchval.append('gds_rec_id', id);
    this.apiService.getGoodsReceiptDetails(searchval).subscribe((res) => {
      this.previewData = res.data;
    });
  }
  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;
  }
  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }
  validateAndSubmit(){
    this.isSubmitInProg = true;
    let callFunction = 'createSup';
    if(this.userType =='ADMIN'){
      this.customer_dt.usr_type = 1;
    }else{
      this.customer_dt.usr_type = 0;
    }
    this.apiService[callFunction](this.customer_dt).subscribe((res) => {
      this.isSubmitInProg = false;
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        this.coreService.showMessage(res.message);
        this.resultobj = {};
        this.modalRef.close();
        this.clearForm();
      }
    });
  }
  clearForm(){
    this.purchtypesel =1;
    this.supp_id =null;
    this.sup_balance =null;
    this.purch_inv_date = null;
    this.gds_rec_supp_dlvry_no = null;
    this.gds_rec_ord_no =null;
    this.gds_rec_reference = null;
    this.gds_rec_inv_no = '';
    this.gd_id =0;
    this.purchaseItems = [{
      purchsub_prd:'',
      purchsub_qty:null,
      base_qty:null,
      purchsub_rate:null,
      purchsub_unit_id:'',
      batch_code:null,
      enableAddBatch :false,
      manufacture_date:null,
      expiry_date:null,
      purchsub_tax_per:null,
      prd_tax_cat_id:null,
      taxvalperqty:'',
      item_desc:'',
      purchsub_gd_id:'',
      prod_units: [],
      prod_batches:[],
      unit: '',
      // qty: null,
      loadingImg: false,
      rate: null,
      disc_amnt: 0,
      item_disc_type:null,
      excl_vat:1,
      taxpercentage:null,
      itemRate: null,
      price_vat_ttl: null,
      base_unit_rate: null,
      item_disc: 0,
      purchsub_disc: 0,
      item_bill_disc: 0,
      barcode:'',
      stock_qty:0,
      purchsub_frieght:0,
    }];

    this.customer_dt = {
      id: '',
      ledger_id: '',
      name: '',
      alias: '',
      code: '',
      email : '',
      mobile: '',
      vat_no: '',
      op_bal: 0,
      brch_id:0,
      usr_type:0,
      is_supplier : false,
      op_bal_type: true,
      add: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      del: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      due_day: '',
      van_line: 0,
      price_group: 1,
      note: ''
    };
    
    this.nextRefNo();
    this.sumPurchaseTotal();
    setTimeout(() => {
      this.selPriduct.last.focus();
    }, 500);
  }
  searchprdBybarcode(search: any) {
    const searchval = new FormData();
    searchval.append('prd_barcd', search);
    searchval.append('godown_id', this.gd_id);
    this.apiService.searchBarcode(searchval).subscribe((res) => {
      this.prdcts = res['data'];
      this.srch_length = this.prdcts.length;
    });
  }
  getTemplete() {
    this.apiService.getSettings().subscribe((res) => {
      this.savedTemplate = {
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }
      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }
    });
  }
  editSGoodsReceipt(id){
   this.editMessage = true;
   this.listing =false;
   let searchval = new FormData();
   searchval.append("gds_rec_id", id);
   this.apiService.getGoodsReceiptDetails(searchval).subscribe((res: any) => {
    this.gds_rec_id =res.data.gds_rec_id;
      this.ref_no = res.data.gds_rec_id2;
      this.supp_id = res.data.supplier;
      this.gds_rec_inv_no = res.data.gds_rec_inv_no;
      this.gd_id =res.data.gds_rec_gd_id;
      this.purchtypesel = res.data.gds_rec_purch_type; 
      this.purch_pay_type =res.data.gds_rec_purch_pay_type;
      this.purch_note = res.data.gds_rec_note;
      this.gds_rec_reference = res.data.gds_rec_reference;
      this.gds_rec_supp_dlvry_no =res.data.gds_rec_supp_dlvry_no;
      this.gds_rec_ord_no = res.data.gds_rec_ord_no;
      this.purch_acc_ledger_id =res.data.gds_rec_purch_acc_ledger_id;
      this.selectTaxType(this.purchtypesel);
     this.purchaseItems = [];  
     var ids = res.data.items.map(v => (v.gds_rec_sub_prd_id));
     this.stkprd_all = [];
     this.searchStkPrdForEdit(ids);
     var myInterval = setInterval(()=> {
        if(this.stkprd_all.length > 0){
        // this.qt_no =res.data.sales_odr_branch_inv;
         //this.nextRefNo1();
          clearInterval(myInterval);
          this.editItemLoading(res);
        }
     }, 500);
   });
   // this.importLoader = false;
   // this.editMessage = false;
   //this.convertMessage = false;
    this.pageLoadingImg = false;
  }
  searchStkPrdForEdit(ids) {
    let searchval = new FormData();
    searchval.append("ids",ids);
    searchval.append('stk_stat', '1');
    this.apiService.getStockbyItemByIds(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];
      this.srch_count = this.stkprd_all.length;
    });
  }
  editItemLoading(res){
   var tmp ={};
   res.data.items.forEach((element,i) => {
    let selProd = this.stkprd_all.find(element1 => element1.bs_prd_id == element['gds_rec_sub_prd_id']);
    var tmp = {
        purchsub_prd:selProd,
        // purchsub_qty:element['gds_rec_sub_qty'],
        // rate: element['gds_rec_sub_rate'],
        purchsub_qty:element['purchased_qty'],
        rate: element['purchased_rate'],
        purchsub_tax_amnt: element['gds_rec_sub_tax'],
        base_qty:element['unit_base_qty'],
        salesub_rate: element['gds_rec_sub_rate'],
        salesub_unit_id:[],
        salesub_tax_per:element['gds_rec_sub_tax_per'],
        prd_tax_cat_id:element['gds_rec_sub_taxcat_id'],
        salesub_tax:element['gds_rec_sub_tax'],
        item_desc:element['gds_rec_sub_disc'],
        Item_descp:element['gds_rec_sub_item_desc'],
        salesub_gd_id:element['gds_rec_sub_gd_id'],
        purchsub_unit_id : element['gds_rec_sub_unit_id'],
        batch_code :  (element['sb_batch_code']) ? element['sb_batch_code'] : null,
        expiry_date : (element['batch']) ? new Date(element['batch']['sb_expiry_date']) : null,
       // batch_id : (element['sb_id']) ? element['sb_id'] : 0,
        manufacture_date : (element['sb_batch_code']) ? new Date(element['batch']['sb_manufacture_date']) : null,
        prod_units: [],
        prod_batches:[],
        disc_amnt: element['gds_rec_sub_disc'],
        excl_vat:1,
        taxpercentage:null,
        itemRate: null,
        grnd_totl: element['gds_rec_sub_qty'] * element['gds_rec_sub_rate'] + element['gds_rec_sub_tax'],
        base_unit_rate: null,
        item_disc: element['gds_rec_sub_disc'],
        item_bill_disc: 0,
        barcode:'',
        stock_qty:0,
        //  grnd_totl_WithoutBillDisc :element['sales_ord_sub_amnt'] + element['gds_rec_sub_tax'],
        salesub_rate_without_bill_disc : element['gds_rec_sub_rate'],
        item_disc_WithoutBillDisc : element['gds_rec_sub_disc'],
        salesub_tax_amnt_WithoutBillDisc : element['gds_rec_sub_tax'],
      }
      this.purchaseItems.push(tmp);
      this.selectedProduct(i);
      var myInterval1 = setInterval(()=> {
          if(this.purchaseItems[i].prod_units.length > 0){
            // this.qt_no =res.data.sales_odr_branch_inv;
            // this.nextRefNo1();
            clearInterval(myInterval1);
            this.purchaseItems[i].purchsub_unit_id = element['gds_rec_sub_unit_id'];
            // this.purchaseItems[i].purchsub_qty = element['gds_rec_sub_qty'];
            // this.purchaseItems[i].purchsub_rate = element['gds_rec_sub_rate'];
            // this.purchaseItems[i].rate = element['gds_rec_sub_rate'];
            this.purchaseItems[i].purchsub_qty = element['purchased_qty'];
            this.purchaseItems[i].batch_code = (element['sb_batch_code']) ? element['sb_batch_code'] : null;
            this.purchaseItems[i].purchsub_rate = element['gds_rec_sub_rate'];
            this.purchaseItems[i].rate = element['gds_rec_sub_rate'];
            this.purchaseItems[i].base_unit_rate =element['gds_rec_sub_rate'];
            this.purchaseItems[i].Item_desc = element['gds_rec_sub_disc'];
            this.selectedUnit(i);
            this.calcRates(i);
            this.sumPurchaseTotal();
          }
      }, 500);
      // this.searchStkPrd(element['prd_name'], this.salesItems.length -1);
      // this.getStockDetails(this.salesItems.length -1,false);
  });
  // this.listQtn(1);
  // this.sumSaleTotal();
  
  // this.listing = false;
  // this.importLoader = false;
  
  // // this.editMessage = false;
  // // setTimeout(() => {
  // //   this.selPriduct.last.focus();
  // // }, 500);
    }
    UpdateGoodsReceipt(id,print=false) {
      this.validationMsg = '';
      this.resultobj = '';
      if(this.vaidateForm()){
        let sqdisc = 0;
        if(!this.is_bill_disc){
          sqdisc = 0;
        }else{
          sqdisc = this.billDiscount;
        }
        this.purch_date=(this.purch_date) ?this.apiService.formatDate(this.purch_date) : this.purch_date;
        this.purch_inv_date=(this.purch_inv_date) ?this.apiService.formatDate(this.purch_inv_date) : this.purch_inv_date;
        const searchval = {
          'gds_rec_id' : id,
          'gds_rec_ord_no':this.gds_rec_ord_no,
          'gds_rec_purch_is_include_tax':this.purch_is_include_tax,
          'gds_rec_date': this.purch_date,
          'gds_rec_inv_date': this.purch_inv_date,
          'gds_rec_supp_dlvry_no':this.gds_rec_supp_dlvry_no,
          'gds_rec_purch_amount': this.totItemprice - this.totItemDisc + this.totVatAmnt,
          'gds_rec_purch_discount': this.totItemDisc,
          //'sq_disc': sqdisc,
          'gds_rec_purch_tax': this.totVatAmnt,
          'items': this.purchaseItems,
          'terms': this.terms,
          'gds_rec_note': this.purch_note,
          'gds_rec_reference':this.gds_rec_reference,
          'gds_rec_gd_id': this.gd_id,
          //'purch_tax_type': this.purchtypesel - 1,
          'gds_rec_purch_type' : this.purchtypesel,
          'gds_rec_purch_pay_type': this.purch_pay_type,
          'gds_rec_purch_acc_ledger_id': (this.purch_pay_type == 2) ? this.purch_acc_ledger_id : this.purch_bank_ledger_id,
          // 'inv_type': this.inv_type,
          'gds_rec_inv_no': this.gds_rec_inv_no,
          'gds_rec_supp_id' : (this.supp_id) ? this.supp_id.supp_id : '',
          'gds_rec_flag' : 1,
  
        }
        // if(this.cmpny != 'FJK' && this.cmpny != 'FJKIN'){
        //   this.coreService.showMessage('Quick Purchase Temporarily Blocked');
        //   return false;
        // }
        this.pageLoadingImg = true;
        this.apiService.UpdateGoodsReceipt(searchval).subscribe((res) => {
          if (res.error != null) {
            this.resultobj = res.error;
            this.pageLoadingImg = false;
            for (var value in this.resultobj) {
              this.validationMsg += this.resultobj[value].toString() + '<br>';
            }
          }
          if (res.message) {
            if(print){
              this.previewData = res.preview;
              this.is_prew_description = this.previewData['items'].filter(x => x.purchsub_item_desc);
              this.previewLoader = false;
              $('#printviewBtn').click();
              this.coreService.showMessage(res.message);
            } else{
              this.coreService.showMessage(res.message);
            }
            this.pageLoadingImg = false;
            this.supp_id =null;
            this.sup_balance =null;
            this.sup_tin =null;
            this.purch_inv_date = null;
            this.gds_rec_supp_dlvry_no =null;
            this.gds_rec_inv_no = '';
            this.purch_note =null;
            this.gds_rec_reference = null;
            this.gds_rec_ord_no = null;
            this.purchtypesel =1;
            this.totItemDisc =null;
            this.billDiscount = 0;
            this.totVatAmnt = 0;
            this.totItemprice = 0;
            this.totItempriceWithoutBillDisc = 0;
            this.totItemDisc = 0;
            this.totItemDiscWithoutBillDisc = 0;
            this.totVatAmntWithoutBillDisc = 0;
            this.gd_id =0;  
            this.purchaseItems = [{
              purchsub_prd:'',
              purchsub_qty:null,
              base_qty:null,
              purchsub_rate:null,
              purchsub_unit_id:'',
              batch_code:null,
              enableAddBatch :false,
              manufacture_date:null,
              expiry_date:null,
              purchsub_tax_per:null,
              prd_tax_cat_id:null,
              taxvalperqty:'',
              item_desc:'',
              purchsub_gd_id:'',
              prod_units: [],
              prod_batches:[],
              unit: '',
              // qty: null,
              loadingImg: false,
              rate: null,
              disc_amnt: 0,
              item_disc_type:null,
              excl_vat:1,
              taxpercentage:null,
              itemRate: null,
              price_vat_ttl: null,
              base_unit_rate: null,
              item_disc: 0,
              purchsub_disc:0,
              item_bill_disc: 0,
              barcode:'',
              stock_qty:0,
              selRateQty:1,
              purchsub_frieght:0,
            }];
            this.listPurchase(1);
            this.nextRefNo();
            this.sumPurchaseTotal();
            setTimeout(() => {
              this.selPriduct.last.focus();
            }, 500);
  
            this.searchSupplier('');
          }
        });
      }
      this.editMessage = false;
    }

    removeGoodsReceipt(id)
    {
      if (confirm("Do you wish to void this entry?")) {
        this.deleteStatus =true;

        let searchval = new FormData();
  
        searchval.append("gds_rec_id", id);
        this.apiService.voidGoodsReceipt(searchval).subscribe((res) => {
          if (res.message)
            this.coreService.showMessage('Voided Successfully');
            // this.listing = true;
            this.listPurchase(1);
        });
      }
    }
    addBatch(i){
      this.purchaseItems[i].enableAddBatch =true;
      this.purchaseItems[i].batch_code =null;
      this.purchaseItems[i].manufacture_date =null;
      this.purchaseItems[i].expiry_date =null;
      
    }
    existingBatch(i)
    {
      this.purchaseItems[i].enableAddBatch =false;
      this.purchaseItems[i].batch_code =null;
      this.purchaseItems[i].manufacture_date =null;
      this.purchaseItems[i].expiry_date =null;
    }

    // selectBatch(search,i){
    //   let prd_id = this.purchaseItems[i].purchsub_prd.prd_id;
    // }

    getGeneralSetting() {
      this.apiService.getGeneralSetting().subscribe((res) => {
        if(res['data']){
          this.g_settings = res['data'];
          this.generalSearch_type = this.g_settings.g_s_search_type;
        }else{
          this.generalSearch_type=0;
        }  
      });
    }
}

