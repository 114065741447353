import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.css']
})
export class GeneralSettingsComponent implements OnInit {

  search_types = [
    { id: 0, name: 'Normal Search' },
    { id: 1, name: 'Combined Search' }
  ];

  hide_prvw_prnt_itm_name = [
    { id: 0, name: 'Hide' },
    { id: 1, name: 'Display' }
  ];

  search_type: any;
  pageLoadingImg: boolean;
  g_settings: any;
  categories: any;
  hide_prdct_by_cat_sale: any;
  hide_prdct_by_cat_purch: any;
  hide_prnt_itm_name: any;

  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.getGeneralSetting();
    this.searchCat('');
    
  }

  searchCat(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.categories = res['data'];     
    });  
  }
  getGeneralSetting() {

    this.apiService.getGeneralSetting().subscribe((res) => {

      // console.log(res['data']);
      this.g_settings = res['data'];
      this.search_type = this.g_settings.g_s_search_type;
      this.hide_prnt_itm_name = this.g_settings.g_s_hide_prnt_itm_name;
      this.hide_prdct_by_cat_sale = this.g_settings.ss_hidden_cat_ids;
      this.hide_prdct_by_cat_purch = this.g_settings.ps_hidden_cat_ids;


    });

  }

  update(formdata: { value: any; }) {
    this.pageLoadingImg = true;

    this.apiService.updateGeneralSetting(formdata.value).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.coreService.showMessage('Setting Saved');

      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }

  langChange() {
    this.translate.get(['Common.normal_Search', 'Common.combined_Search','HOME.hide','Common.Display'
    ]).subscribe((res: string) => {


      this.search_types = [
        { id: 0, name: res['Common.normal_Search'] },
        { id: 1, name: res['Common.combined_Search'] },

      ];

      this.hide_prvw_prnt_itm_name = [
        { id: 0, name: res['HOME.hide'] },
        { id: 1, name: res['Common.Display'] },

      ];

    });

  }

}
