import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from 'src/app/service/excel.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import {LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-daily-summary',
  templateUrl: './daily-summary.component.html',
  styleUrls: ['./daily-summary.component.css']
})

export class DailySummaryComponent implements OnInit {
  fileName = 'daily_summary.xlsx';
  payType = ['Cash', 'Credit'];
  saleData: any = {
    sales_id: null,
    sales_inv_no: null,
    error: null,
    message: null,
    data: null,
  };

  resultobj: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  period_type: any;
  date1: any;
  date2: any;
  inv_filter: any;
  inv_val: any;
  time1:any;
  time2:any;
  inv_val1: any;
  purch_price: any;
  price1: any;
  price2: any;
  purch_discount: any;
  discount1: any;
  discount2: any;
  added_by: any;
  vch_type: any;
  disp_void: any;
  vch_filter: any;
  ret_ref_filter: any;



  dayList: any[];
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  supplierAll: string[];
  ModelData: boolean;
  // total_purchase:number;
  total_return: any;
  total_purchase_amount: any;

  pageLoadingImg: boolean;
  report_type: string;
  is_year_rep: any;
  filter_types: any;
  filter_refnum: any;
  filter_price: any;
  filter_discount: any;
  // filter_paytype:any;
  // filter_purchtypes:any;
  selectType: any;
  // selectRefNo:any;
  selectPrice: any;
  // selectDiscount:any;



  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];

  filter_paytype = [
    { id: '1', name: 'Credit' },
    { id: '2', name: 'Cash' },

  ];

  filter_purchtypes = [
    { id: '1', name: 'NonVoided' },
    { id: '0', name: 'Voided' },

  ];
  repDate: any;
  voucherType: any;
  ledger_all: any;
  users: any;
  show_notes: any;
  dispnote: any;
  debitAmt: any;
  crdAmt: any;
  branch_all: any;
  userType: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  Exppurchsumms: any;
  Expsumms: any;
  Expdispnote: any;
  ExpdebitAmt: number;
  ExpcrdAmt: number;
  exportLoader: boolean;
  pdf: any;
  branch_address: any;
  branch_phone: any;
  branch_mob: any;
  printPreview: any;
  recPriviewLoader: boolean;

  payPriviewLoader: boolean;
  salesPriviewLoader: boolean;
  up: any;
  showdata: boolean;
  purchaseItems: any;

  savedTemplate = {
    title_text: 'SALES INVOICE فاتورة',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: 'P',
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  };
  logoImgUrl: any = '';
  tempImgUrl: any;
  dailySummary: any;
  acc: any;
  country_dec: string;
  userAll: any;
  filter_branch_id: number;
  constructor(private apiService: ApiService, private coreService: CoreService, private excelService: ExcelService,
              private translate: TranslateService, private currencyPipe: CurrencyPipe, private datePipe: DatePipe) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.getAllBranch();
    // this.filter_branch_id = 1;
    this.pageLoadingImg = true;
    this.country_dec=this.coreService.setDecimalLength();
    this.userType = this.coreService.getUserData('user_type');
    const searchval = new FormData(); 
    //  searchval.append('filter_branch_id', this.filter_branch_id);
    // this.period_type = 't';
    // this.getTemplete();
    this.period_type='t';
    searchval.append('period_type',   this.period_type);
    
    this.apiService.listDialySummay(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      //console.log(res.data.Date);
      this.dailySummary =res.data;
      this.dayList = res.data.cashInHand;
      this.acc = res.data.bankAccount;
       this.repDate = res.data.Date;
       this.branch_display_name=res.data.company.branch;
      this.slnum = 0;

      this.ModelData = false;
    });
   

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.up = this.coreService.getUserPrivilage();
    this.branch_address = this.coreService.getUserData('branch_address');
    this.branch_phone = this.coreService.getUserData('branch_phone');
    this.branch_mob = this.coreService.getUserData('branch_mob');
    this.searchUser('');
  }


  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });

  }
  ngOnDestroy() {
    $('.close').click();
  }


  pageNext(formdata: { value: any; }, pageno: any) {

    // $('.resultdata').empty();
    this.dayList = [];
    this.acc = [];
    this.pageLoadingImg = true;

    const searchval = new FormData();
    searchval.append('pageno', pageno);
    if(formdata.value.time1){
      this.time1 = new Date(formdata.value.time1);
    }
    if(formdata.value.time2){
      this.time2 = new Date(formdata.value.time2);
    }

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
      formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
    }
    if (formdata.value.vch_type) {
      formdata.value.vch_type = Number(formdata.value.vch_type);
    }
    formdata.value.export = '';

    this.apiService.listDialySummay(formdata.value, pageno).subscribe((res: any) => {
      // console.log(res.data);
      this.pageLoadingImg = false;
      this.dailySummary =res.data;
      this.dayList = res.data.cashInHand;
      this.acc = res.data.bankAccount;
      this.branch_display_name=res.data.company.branch;
       this.repDate = res.data.Date;
     
      this.slnum = 0;

    });
  }

  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    // searchval.append("usr_type", '4');
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.userAll = res['data'];
     
    });
  }


  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res:any) => {
      this.branch_all = res.data;
      this.filter_branch_id = res.selected;

      // console.log(  this.branch_all );
    //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }

searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res:any) => {
      this.branch_all = res.data;
      // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }

  excelExport(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-file'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }

  exportExcel() {   
    this.exportLoader = true; 
    setTimeout(() => {
      this.excelExport();
  }, 3000);
}

}

