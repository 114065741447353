import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-offer-dash',
  templateUrl: './offer-dash.component.html',
  styleUrls: ['./offer-dash.component.css']
})
export class OfferDashComponent implements OnInit {
  cmpny: any;
  cust_prdwise: boolean;

  constructor(private apiService: ApiService,private coreService: CoreService) { }

  ngOnInit() {

    this.cmpny = this.coreService.getUserData('cmpny');

    this.apiService.getAllClientSettings().subscribe((res) => {
      if (res['data']) {
        this.cust_prdwise = res["data"]['cust_productwise_discount']["cs_value"] ? true : false;

      }
    });
  }

}
