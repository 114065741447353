import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx'; 
import { ExcelService } from '../../../../../service/excel.service';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
@Component({
  selector: 'app-rep-income-expense',
  templateUrl: './rep-income-expense.component.html',
  styleUrls: ['./rep-income-expense.component.css']
})
export class RepIncomeExpenseComponent implements OnInit {
  filterTypes: { id: string; name: any; }[];
  pageLoadingImg: boolean;
  report: any;
  period_type: string;
  exportLoader: boolean;
  fileName= 'income_and_expense_report.xlsx';
  branch_display_name: any;
  branch_address: any;
  country_dec: number=2;
  time1: Date;
  time2: Date;
  userType: any;
  branch_all: any;
  filter_branch_id: number;
  logoImgUrl :any = '';
  incl_stock_trnsfr: any;
  incl_ext_stock_trnsfr: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.userType = this.coreService.getUserData('user_type');
    this.period_type='t';
    let searchval =new FormData();
    searchval.append('period_type','t');
    this.apiService.incomeExpenseReport(searchval).subscribe((res) => {
      this.report=res.data;
      this.pageLoadingImg = false;
      this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    });
    this.country_dec=this.coreService.setDecimalLength();
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.this_month']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'tm', name: res['Common.this_month'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
      
    });
  }

  incomeExpenseReport(formdata: { value: any; }){
  

    if (formdata.value.period_type === 'c') {
      if(formdata.value.time1){
        this.time1 = new Date(formdata.value.time1);
      }
      if(formdata.value.time2){
        this.time2 = new Date(formdata.value.time2);
      }
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
      formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
    }
    this.pageLoadingImg = true;

  this.apiService.incomeExpenseReport(formdata.value).subscribe((res) => {
    this.report=res.data;
    this.branch_display_name=res.data.branch_data.branch;
    this.pageLoadingImg = false;

  });
  }

  exportEXCL(){
    this.exportLoader = true;
    this.exportexcel();
  }
  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }

  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res:any) => {
      this.branch_all = res.data;
      this.filter_branch_id = res.selected;

      // console.log(  this.branch_all );
    //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }

searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res:any) => {
      this.branch_all = res.data;
      // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });}
  generatePdf() {
    const headerHeight = 52, lblXPos = 18, valueXPos = 55;
   
    let doc = this.excelService.getPdfObj();
    doc.setFont('Amiri');
    doc.setFontSize(12);
    doc.text('Income And Expense Report', 85, headerHeight - 2);
    doc.setFontSize(10);
    doc.text(`${this.translate.instant('ACCOUNTS.Company_Branch')}`, lblXPos, headerHeight + 5);
    doc.text(':  ' + this.branch_display_name,  valueXPos, headerHeight + 5);
    doc.text('Period', lblXPos, headerHeight + 10);
    doc.text(':  ' +  this.report.Date.date1 + ' to ' + this.report.Date.date2,  valueXPos, headerHeight + 10);

    const heads = [
      this.translate.instant('ACCOUNTS.Income'),
       '',
    ];

    const data = [
      [this.translate.instant('HOME.SALES'), this.report.income.sales_amnt.toFixed(this.country_dec)],
      [this.translate.instant('HOME.sales_return'), this.report.income.sales_ret_amnt.toFixed(this.country_dec)],
      [this.translate.instant('Table.Net_Sales'), this.report.income.net_sales.toFixed(this.country_dec)],
    ];
    if (this.incl_stock_trnsfr) {
      data.push([this.translate.instant('Table.stl_trnsfr'), this.report.income.stock_transfer.toFixed(this.country_dec)]);
    }
    if (this.incl_ext_stock_trnsfr) {
      data.push([this.translate.instant('transaction.ext_stock_transfer'), this.report.income.external_transfer.toFixed(this.country_dec)]);
    }
    data.push([this.translate.instant('Production.cost'), this.report.income.cost.toFixed(this.country_dec)]);
    data.push([this.translate.instant('Common.profit'), this.report.income.profit.toFixed(this.country_dec) +
    ' (' + this.report.income.profit_perc.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ')%']);
    data.push([this.translate.instant('ACCOUNTS.expenses'), '']);
     
    this.report.expenses.forEach(expense => {
      data.push([this.translate.instant(expense.ledger_name), expense.amnt]);
    });
    data.push([this.translate.instant('HOME.total'), this.report.total_expense.toFixed(this.country_dec)]);
    data.push([this.translate.instant('ACCOUNTS.net_profit'), this.report.net_profit.toFixed(this.country_dec)]);

    doc = this.excelService.addTableToPdf(doc, headerHeight + 12, lblXPos, heads, data, this.logoImgUrl);
  
    doc.save(`Income And Expense Report.pdf`);
  }

}
