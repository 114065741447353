import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Repprddet } from '../../../../../model/report/repprddet.model';
import { Catagory } from '../../../../../model/catagory.model';
import { Subcatagory } from '../../../../../model/subcatagory.model';
import { DatePipe, formatDate } from '@angular/common';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import * as XLSX from 'xlsx';



@Component({
  selector: 'app-rep-stockbyperiod-old',
  templateUrl: './rep-stockbyperiod-old.component.html',
  styleUrls: ['./rep-stockbyperiod-old.component.css']
})

export class RepStockbyperiodOldComponent implements OnInit {

  mrpDef = 0;
  calc_mode: string;
  Expcalc_mode: any;
  fileName = 'stock_summary_datewise_old.xlsx';

  remark: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  prd_id: any;
  prd_cat_id: any;
  prd_manufact_id: any;
  period_type: any;
  bar_filter: any;
  bar_val: any;
  bar_val2: any;
  stkeanbarcode: any;
  ean_key: any;

  prodsumms: string[];
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  catagories: string;
  date1: any;
  date2: any;
  stkprd_all: string[];
  mfs: string[];
  uspDef = 0;
  totalproducts: number;
  totalstock: number;
  totalpurchase: any;
  supplierAll: string[];
  pageLoadingImg: boolean;
  repDate: any;
  userType: any;
  branch_all: any;
  filter_branch_id: number;
  exportLoader: boolean;
  Expprodsumms: any;
  Expremark: any;
  Exptotalproducts: any;
  Exptotalstock: any;
  Exptotalpurchase: any;
  ExprepDate: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  subcatagories: any;
  usr_hide_purch_cost: any;
  country_dec: string;
  constructor(private apiService: ApiService, private coreService: CoreService, private datePipe: DatePipe,private translate: TranslateService) { }
  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.usr_hide_purch_cost = this.coreService.getUserData('usr_hide_purch_cost');

    this.getAllBranch();
    this.filter_branch_id = 1;
    this.userType = this.coreService.getUserData('user_type');
    this.pageLoadingImg = true;
    // let searchval = new FormData();
    // this.apiService.stockRepPeriodOld(searchval, 1).subscribe((res: Repprddet) => {
    //   this.pageLoadingImg = false;
    //   this.prodsumms = res['data']['data'];
    //   this.curpage = res['data']['current_page'];
    //   this.lastpage = res['data']['last_page'];
    //   this.totalproducts = res['data']['total'].total_item;
    //   this.totalstock = res['data']['total'].total_stock;
    //   this.totalpurchase = res['data']['total'].total_amount;
    //   this.totalpurchase = this.totalpurchase.toFixed(2);
    //   this.calc_mode = res['data']['calc_mode'];
    //   this.remark = res['data']['total'].remark;
    //   this.repDate = res['data'].Date;

    //   this.pgEnd(this.curpage, this.lastpage);
    //   this.pgStart(this.curpage);
    // });
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.purchase_rate','Table.sales_rate_mrp','Common.last_purchase_rate','Common.average_purchase_rate','Common.all','HOME.CUSTOM']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.filter_rate = [
        { id: 0, name: res['Common.purchase_rate'] },
        { id: 1, name: res['Table.sales_rate_mrp'] }
      ];
      this.filter_prate = [
        { id: 0, name: res['Common.last_purchase_rate'] },
        { id: 1, name: res['Common.average_purchase_rate'] }
      ];
      this.filterPurchrateByDate = [
        { id: 1, name: 'Last Purchase Rate By Date' },
        { id: 2, name: 'Avg. Purchase Rate By Date' },
      
      ];
    
      // Barcode and Ean
      this.stk_ean_barcode = [
        { id: 'all', name: res['Common.all'] },
        { id: 'between', name: res['HOME.CUSTOM'] }
      ];
    });

  }
  selectRate(rateid: number) {
    if (rateid == 1) { $('#useprate').hide(); }
    else {
      $('#useprate').show();
    }
  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      this.filter_branch_id = res['selected'];
      $('#filterSubmit').click(); 
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
    });
  }

  ngAfterContentInit() {

    this.searchCat('');
    this.searchManf('');


  }
  ngOnDestroy() {
    $('.close').click();

  }

  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  // filter  types
  bar_filter_types = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];


  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  selectType(id: string) {
    if (id == 'c') {
      $('#val1').show();
      $('#val2').show();
    } else {
      $('#val1').hide();
      $('#val2').hide();

    }

  }

  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
    });
  }


  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }
  getGodownbybranch(bid)
  {
    console.log(bid);
    let searchval = new FormData();      
    searchval.append("bid", bid);
  }

  pageNext(formdata: { value: any; }, pageno: any) {
    $('.resultdata').empty();
    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("pageno", pageno);
    if (formdata.value.mrp == 1)
    formdata.value.avg_rate = undefined;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    console.log(this.filter_branch_id);
    if(this.userType == "ADMIN"|| this.userType == "AUDITOR"){
      formdata.value.filter_branch_id = this.filter_branch_id;
    }    
    this.apiService.stockRepPeriodOld(formdata.value, pageno).subscribe((res: Repprddet) => {
      this.pageLoadingImg = false;

      this.prodsumms = res['data']['data'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.remark = res['data']['total'].remark;
      this.repDate = res['data'].Date;

      this.totalproducts = res['data']['total'].total_item;
      this.totalstock = res['data']['total'].total_stock;
      this.totalpurchase = res['data']['total'].total_amount;
      this.totalpurchase = this.totalpurchase.toFixed(2);
      this.calc_mode = res['data']['calc_mode'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  //form search  catagory
  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });
    $('.prev-list').hide();
  }

  changeDate(dateString) {
    dateString = this.datePipe.transform(dateString, 'yy-MM-dd');
    return dateString;
  }

  //product form search 
  searchStkPrd(search: string) {
    let searchval = new FormData();
    searchval.append("keyword", search);
    this.apiService.getPrdRepkbyName(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];

    });
  }

  //form search  manufacture
  searchManf(search: string) {
    let searchval = new FormData();
    searchval.append("manftr_comp_name", search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.mfs = res['data'];
    });
  }

  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res: Subcatagory[]) => {
      this.subcatagories = res['data'];
      if (this.changeBox)
        this.changeBox = false;
      else
        this.changeBox = true;

    });
  }
  filter_rate = [
    { id: 0, name: 'Purchase Rate' },
    { id: 1, name: 'Sales Rate -(M.R. P)' }
  ];
  filter_prate = [
    { id: 0, name: 'Last Purchase Rate' },
    { id: 1, name: 'Avg. Purchase rate' }
  ];
  filterPurchrateByDate = [
    { id: 1, name: 'Last Purchase Rate By Date' },
    { id: 2, name: 'Avg. Purchase Rate By Date' },
    // { id: 'avg_by_date', name: 'Avg. Purchase Rate By Date' }
  ];

  // Barcode and Ean
  stk_ean_barcode = [
    { id: 'all', name: 'All' },
    { id: 'between', name: 'Custom' }
  ];

  selectBarcodeFilter(id: string) {
    if (id == 'between') {
      $('#bar_val2').show();
    }
    else {
      $('#bar_val2').hide();
    }
  }

  selectStkEanBarcode(id: string) {
    if (id == 'between') {
      $('#ean_key').show();
    }
    else {
      $('#ean_key').hide();
    }
  }





  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  exportEXCL(formdata: { value: any; }) {
    this.exportLoader = true;

    // debugger;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.stockRepPeriodOld(formdata.value, 1).subscribe((res: Repprddet) => {
      formdata.value.export = '';

      this.Expprodsumms = res['data']['data'];

      this.Expremark = res['data']['total'].remark;
      this.ExprepDate = res['data'].Date;

      this.Exptotalproducts = res['data']['total'].total_item;
      this.Exptotalstock = res['data']['total'].total_stock;
      this.Exptotalpurchase = res['data']['total'].total_amount;
      this.Exptotalpurchase = this.Exptotalpurchase.toFixed(2);
      this.Expcalc_mode = res['data']['calc_mode'];


      setTimeout(() => {
        this.exportexcel();
      }, 3000);


    });



  }

}
