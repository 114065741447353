import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';


import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-cusomer-category',
  templateUrl: './cusomer-category.component.html',
  styleUrls: ['./cusomer-category.component.css']
})

export class CusomerCategoryComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  @ViewChild('closeModalSub', { static: false }) closeModalSub: ElementRef;
  @ViewChild('closeModalSubView', { static: false }) closeModalSubView: ElementRef;
  resultobj: any = {};
  info = [];
  customerSubCategories = [];

  cusomerCategory = {
    cust_cat_id :'',
    cust_cat_name:'',
    cust_cat_description:'',
   
  }

  cusomerSubCategory = {
    csc_id: '',
    csc_cat_id :'',
    csc_cat_name:'',
    csc_subcat_name:'',
    csc_subcat_desc:'',
   
  }


  isSubmitInProg = false;

  curpage = 1;
  lastpage = 1;
  perpage = 20;
  totalItem = 0;
  curForm = 'Create';
  pageLoadingImg = false;

  langText = {
    create: '',
    update: ''
  };
  up: any;
  categories: any;
  isLoading: boolean;
  
  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService,private modalService: NgbModal) { }

  ngOnInit() {
    this.translate.get(['Common.create', 'Common.update']).subscribe((res: string) => {
      this.langText.create = res['Common.create'];
      this.langText.update = res['Common.update'];
      this.curForm = this.langText.create;
    
      this.up = this.coreService.getUserPrivilage();
    
    });
    this.getInfo(this.curpage);
  }

  getInfo(pageNo) {
    this.pageLoadingImg = true;
    this.apiService.getAllCustCategory(pageNo, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }


  createCustomerCategory(){
    this.curForm = this.langText.create;
    this.clearForm();
  }

  clearForm() {
    this.cusomerCategory = {
      cust_cat_id :'',
      cust_cat_name:'',
      cust_cat_description:'',
    };

    this.cusomerSubCategory = {
      csc_id:'',
      csc_cat_id :'',
      csc_cat_name: '',
      csc_subcat_name:'',
      csc_subcat_desc:'',
    };
  }

 

  editCustomerCategory(cust_cat_id){
    this.curForm = this.langText.update;
    this.clearForm();
    this.resultobj = [];
    this.apiService.getCustomerCategory({cust_cat_id: cust_cat_id}).subscribe((res) => {
      this.cusomerCategory = {
        cust_cat_id : res.cust_cat_id ,
        cust_cat_name: res.cust_cat_name,
        cust_cat_description: res.cust_cat_description,
      
      };
    });
  }

  validateAndSubmit() {


    if (confirm('Do you wish to continue?')) {
      this.isSubmitInProg = true;
      let callFunction = '';
      if (this.curForm === this.langText.create) {
        callFunction = 'createCustomerCategory';
      }
      if (this.curForm === this.langText.update) {
        callFunction = 'updateCustomerCategory';
      }
      this.apiService[callFunction](this.cusomerCategory).subscribe((res) => {
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.closeModal.nativeElement.click();
          this.getInfo(this.curpage);
          this.coreService.showMessage(res.message);
          this.clearForm();
          this.resultobj = {};
        }

      });
    }
  
}

  createCustomerSubCategory(data) {
    this.curForm = this.langText.create;
    this.clearForm();
    this.resultobj = [];
    this.cusomerSubCategory = {
      csc_id: '',
      csc_cat_id :data.cust_cat_id,
      csc_cat_name:data.cust_cat_name,
      csc_subcat_name: '',
      csc_subcat_desc: '',
    };

   
  }

  viewCustomerSubCategory(cust_cat_id) {
    this.isLoading = true;
    this.customerSubCategories = [];
    this.curForm = this.langText.create;
    this.clearForm();
    this.resultobj = [];
    this.apiService.viewCustomerSubCategory({ cust_cat_id: cust_cat_id }).subscribe((res) => {
      this.customerSubCategories = res;
      this.isLoading = false;
    });
  }


  editCustomerSubCategory(subCategory) {
    this.closeModalSubView.nativeElement.click();
    this.curForm = this.langText.update;
    this.clearForm();
    this.resultobj = [];
    this.cusomerSubCategory = {
          csc_id: subCategory.csc_id,
          csc_cat_id :subCategory.csc_cat_id,
          csc_cat_name:subCategory.customer_category.cust_cat_name,
          csc_subcat_name: subCategory.csc_subcat_name,
          csc_subcat_desc: subCategory.csc_subcat_desc,
        };

  }

  deleteCustomerSubCategory(csc_id, csc_cat_id) {
    if (confirm("Do you wish to void this entry?")) {
      let searchval = new FormData();
      searchval.append('csc_id', csc_id);
      this.apiService.deleteCustomerSubCategory(searchval).subscribe((res) => {
        if (res.message)
          this.coreService.showMessage('Voided Successfully');
        else
          this.coreService.showMessage(res.error);

        this.viewCustomerSubCategory(csc_cat_id);
      });
    }
  }
  
 
  validateAndSubmitSub() {
    if (confirm('Do you wish to continue?')) {
      this.isSubmitInProg = true;
      let callFunction = '';
      if (this.curForm === this.langText.create) {
        callFunction = 'createCustomerSubCategory';
      }
      if (this.curForm === this.langText.update) {
        callFunction = 'updateCustomerSubCategory';
      }
       this.apiService[callFunction](this.cusomerSubCategory).subscribe((res) => {
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.closeModalSub.nativeElement.click();
          this.getInfo(this.curpage);
          this.coreService.showMessage(res.message);
          this.clearForm();
          this.resultobj = {};
        }
     });
    }

}



}
