import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import * as XLSX from 'xlsx';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-quick-product-create',
  templateUrl: './quick-product-create.component.html',
  styleUrls: ['./quick-product-create.component.css']
})
export class QuickProductCreateComponent implements OnInit {
  fileName = 'products.xlsx';
  private modalRef: NgbModalRef;
  closeResult: string;
  pageFrom:any;
  curpage: number;
  pgNo: any=1;
  pgnum:any=1;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  pageLoadingImg: boolean;
  prods: any;
  totalProducts: any;
  prd_pgno: any;
  exportLoader: boolean;
  eanbarcode: string;
  systmbarcode: string;
  syncode: string;
  excel_prods: any;
  excel_totalProducts: any;
  excel: string;
  prd_name: any;
  up: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private modalService: NgbModal,private translate:TranslateService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.langChange();
    });
    this.pageLoadingImg = true;
    let searchval = new FormData();
    console.log('localStorage');
    console.log(localStorage);

    searchval.append("list", '1');
    this.prd_pgno = localStorage.prd_pgno; 
    this.eanbarcode = localStorage.eanbarcode;
    this.systmbarcode = localStorage.systmbarcode;
    this.syncode = localStorage.syncode;

    console.log(this.eanbarcode+'eanbarcode');
    console.log(this.systmbarcode+'systmbarcode');
    console.log(this.syncode+'syncode');
    
    
    if(this.prd_pgno && this.prd_pgno !=undefined ){
      this.pgnum=this.prd_pgno
     } 
     if(this.eanbarcode){
       
      this.getProdByEanBarcode(this.eanbarcode,this.pgnum);
    }else if(this.systmbarcode){
      this.selectProdDetailBySystemBarode(this.systmbarcode,this.pgnum);
    }else if(this.syncode){
      this.getProdByCode(this.syncode,this.pgnum);
    }else{
      this.apiService.prodSumPage(searchval,this.pgnum).subscribe((res: any) => {
        this.pageLoadingImg = false;
        this.prods = res['data']['data']['data'];
        this.totalProducts = res['data']['total_products'];
        this.pageFrom = res['data']['data']['from'];
        this.curpage = res['data']['data']['current_page'];
        this.pgNo=this.curpage;
       
        this.lastpage = res['data']['data']['last_page'];
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
      });
      localStorage.setItem("prd_pgno",'1');
    }
    


     
     
  }
  


  
  pageNext( pageno: any) {
    this.pgNo=pageno;
  this.pageLoadingImg = true;
  if(this.eanbarcode){
        this.getProdByEanBarcode(this.eanbarcode,pageno);
      }else if(this.systmbarcode){
        this.selectProdDetailBySystemBarode(this.systmbarcode,pageno);
      }else if(this.syncode){
        this.getProdByCode(this.syncode,pageno);
      }else{
    let searchval = new FormData();
    searchval.append("pageno", pageno);
    searchval.append("list", '1');
    this.apiService.prodSumPage(searchval, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.prods = res['data']['data']['data'];
      this.pageFrom = res['data']['data']['from'];
      this.totalProducts = res['data']['total_products'];
      this.curpage = res['data']['data']['current_page'];
     
      this.lastpage = res['data']['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }
    
   }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else 
      this.pgstart = false;
  }

  searchInp(keyword) {
    let searchval = new FormData();
    if (keyword)
      if(this.translate.currentLang == "Arabic"){
        searchval.append("withAlias", "1");
      }
      searchval.append("keyword", keyword);
    this.pageLoadingImg = true;
    this.apiService.prodSumPage(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.prods = res['data']['data']['data'];
      this.totalProducts = res['data']['total_products'];
      this.pageFrom = res['data']['data']['from'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.eanbarcode='';
      this.systmbarcode='';
      this.syncode='';
    });
  }

   /*
    Start
    Author: harsha
    Created: 12.09.2024
    Description:search with barcode and ean
    Updates: Done som updation in this api by Ajml
  */
    selectProdDetailBySystemBarode(barcode: string,pageno=1,excel=null) {
    if(barcode){
    const searchval = new FormData();
    searchval.append("systmbarcode", barcode);
    searchval.append("export", excel);
    this.pageLoadingImg = true;
    this.apiService.selectProdDetailBySystemBarode(searchval,pageno).subscribe((res) => {
      this.pageLoadingImg = false;
      this.prods = res['data']['data']['data'];
      this.syncode='';
      this.eanbarcode='';
      this.prd_name=null;
      if(excel=='export'){
        this.excel_prods = res['data']['data']['data'];
        setTimeout(() => {
          this.exportexcel();
        }, 3000);
      }
      this.pageFrom = res['data']['data']['from'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

    localStorage.setItem("eanbarcode",'');
    localStorage.setItem("syncode",'');
  }else{
    this.searchInp('');
    localStorage.setItem("systmbarcode",'');
    localStorage.setItem("syncode",'');
    localStorage.setItem("eanbarcode",'');

  }
  }
  getProdByCode(barcode: string,pageno=1,excel=null) {
    if(barcode){
    const searchval = new FormData();
    searchval.append("synccode", barcode);
    searchval.append("export", excel);
    this.pageLoadingImg = true;
    this.apiService.getProdByCode(searchval,pageno).subscribe((res) => {
      this.pageLoadingImg = false;
      this.prods = res['data']['data']['data'];
      this.eanbarcode='';
      this.systmbarcode='';
      this.prd_name=null;
      if(excel=='export'){
        this.excel_prods = res['data']['data']['data'];
        setTimeout(() => {
          this.exportexcel();
        }, 3000);
      }
      this.pageFrom = res['data']['data']['from'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

    localStorage.setItem("eanbarcode",'');
    localStorage.setItem("systmbarcode",'');
  }else{
    this.searchInp('');
    localStorage.setItem("systmbarcode",'');
    localStorage.setItem("syncode",'');
    localStorage.setItem("eanbarcode",'');
  }

  }

  getProdByEanBarcode(barcode: string,pageno=1,excel=null) {
    if(barcode){
    const searchval = new FormData();
    searchval.append("eanbarcode", barcode);
    searchval.append("export", excel);
    this.pageLoadingImg = true;
    this.apiService.getProdByEanBarcode(searchval,pageno).subscribe((res) => {
      this.pageLoadingImg = false;
      this.prods = res['data']['data']['data'];
      this.syncode='';
      this.prd_name=null;
      this.systmbarcode='';
        if(excel=='export'){
          this.excel_prods = res['data']['data']['data'];
          setTimeout(() => {
            this.exportexcel();
          }, 3000);
        }
      this.pageFrom = res['data']['data']['from'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
    localStorage.setItem("syncode",'');
    localStorage.setItem("systmbarcode",'');
  }else{
    this.searchInp('');
    localStorage.setItem("eanbarcode",'');
    localStorage.setItem("syncode",'');
    localStorage.setItem("systmbarcode",'');

  }

  }



  editproductfn(){ 
    localStorage.setItem("prd_pgno",this.pgNo);
    localStorage.setItem("eanbarcode",this.eanbarcode);
    localStorage.setItem("syncode",this.syncode);
    localStorage.setItem("systmbarcode",this.systmbarcode);
    
  }

  activate(Id) {
    console.log('Id',Id);
    
    this.apiService.changePrdctStatus(Id).subscribe((res) => {
      this.coreService.showMessage(res.message);
    });
  }

  export(){
    this.exportLoader =true;
   this.excel='export';

    if(this.eanbarcode){
      this.getProdByEanBarcode(this.eanbarcode,1,this.excel);
    }else if(this.systmbarcode){
      this.selectProdDetailBySystemBarode(this.systmbarcode,1,this.excel);
    }else if(this.syncode){
      this.getProdByCode(this.syncode,1,this.excel);
    }else{
      let searchval = new FormData();
      searchval.append("export", 'export');
      console.log('this.search_prd_name');
      console.log(this.prd_name);
      if(this.prd_name){
        searchval.append("keyword", this.prd_name);
      }
       
  this.apiService.prodSumPage(searchval,1).subscribe((res: any) => {
    this.pageLoadingImg = false;
    this.excel_prods = res['data']['data']['data'];
    this.excel_totalProducts = res['data']['total_products'];
    setTimeout(() => {
      this.exportexcel();
    }, 3000);

  });

  }

}

exportexcel(): void {
  /* table id is passed over here */
  const element = document.getElementById('export-group');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, this.fileName);
   this.exportLoader = false;

}

}
