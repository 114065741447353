import { Component, Input, OnInit } from '@angular/core';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-logistic-temp1',
  templateUrl: './logistic-temp1.component.html',
  styleUrls: ['./logistic-temp1.component.css']
})
export class LogisticTemp1Component implements OnInit {
  @Input() saleQtnData;
  @Input()hide_prnt_preview_h_f_show
  @Input() print_header_footer_display;
  @Input() print_style;
  @Input() savedTemplate;
  @Input() is_prew_description;
  @Input() payTypes;
  @Input() previewLoader;
  @Input() logoImgUrl;
  @Input() tempImgUrl;
  @Input() footImgUrl;
  @Input() sales_print_name;
  @Input() hide_item_discount;
  @Input() hide_vat;
  @Input() sales_settings;
  @Input() hide_prnt_alias;
  // @Input()hide_prnt_preview_h_f_show;
  country_dec: any;
  cmpny: any;
  noPage = [];
  noRowPerPage = 19;
  ttlPage = 1;
  data = [];
  emptyRow: any = 0;
  hide_print_header_footer_log:any;
  hide_print_header_footer:any;

  cntryWiseVat={
    '1':'VAT 15%',
    '2':'VAT 5%',
    '3':'VAT 5%',
    '4':'VAT 5%',
    '6':'VAT 10%',
  }
  
  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.cmpny = this.coreService.getUserData('cmpny');
    this.hide_print_header_footer= this.hide_prnt_preview_h_f_show == 1 ? true : false ;
  }

  ngOnChanges(changes) {
    if (changes['saleQtnData'] && this.saleQtnData) {

      this.data = this.saleQtnData.sales_sub;
      this.ttlPage = Math.ceil((this.saleQtnData.sales_sub.length) /this.noRowPerPage);
      this.noPage = this.createRange(this.ttlPage);
      
      this.emptyRow = this.ttlPage * this.noRowPerPage - this.saleQtnData.sales_sub.length - 2;


    }
  
      this.hide_print_header_footer = this.print_header_footer_display ;
         console.log('hiiiiiii');

    console.log('this.hide_print_header_footer on change');
    console.log(this.hide_print_header_footer);

  // this.hide_print_header_footer_prnt= this.hide_print_header_footer;
  
  }

  createRange(number,start=0){
    let noPage = [];
    for(var i = start; i < number; i++){
       noPage.push(i);
    }
    return noPage;
  }

}
