import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.css']
})

export class BarcodeComponent implements OnInit {
  barCode:any;
  private modalRef: NgbModalRef; closeResult: string; result = '';
  selectedData: any = {};startX:any;startY:any;addFlag:any;
  up: any;

constructor(private apiService: ApiService,private coreService: CoreService,private modalService: NgbModal) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
  this.getSettings();  
  }

  getSettings()
  {
   let searchval = new FormData();   
 this.apiService.listBarcode(searchval).subscribe((res) => {
  this.barCode= res['data'];
});     
  }

  newBarcode(content)
  { 
    this.addFlag=0; 
    $('#add').show();
    $('#update').hide(); 
   }

   private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  barCodeSel(barCodes)
  {
    this.addFlag=0;
    this.selectedData = barCodes; 
    var barset_BarCode =  barCodes.barset_barcode.split(','); 

    console.log("shameer selectedData");
    console.log(this.selectedData);

    console.log("shameer tessst");
    console.log(barset_BarCode);
    this.selectedData.start_position_x = barset_BarCode[0];
    this.selectedData.start_position_y = barset_BarCode[1];
    this.selectedData.rotation_id = Number(barset_BarCode[2]);  
    this.selectedData.narrow_bar_width = barset_BarCode[4]; 
    this.selectedData.wide_bar_width = barset_BarCode[5];  
    this.selectedData.barcode_height = barset_BarCode[6]; 
    this.selectedData.barcode_displayno = barset_BarCode[7]; 
    this.selectedData.barset_enable_ean = this.selectedData.barset_enable_ean;
    
   
    if(this.selectedData.barcode_displayno =='B')
    {
     this.selectedData.barcode_displayno = 1; 
    }else
    {
    this.selectedData.barcode_displayno = 0; 
    }
    
    var barset_Product =  barCodes.barset_product.split(','); 
    this.selectedData.sp_item_x = barset_Product[0];
    this.selectedData.sp_item_y = barset_Product[1];
    this.selectedData.rotation_item_id = Number(barset_Product[2]);
    this.selectedData.item_font = Number(barset_Product[3]);
    this.selectedData.sp_item_mult_x = Number(barset_Product[4]);
    this.selectedData.sp_item_mult_y = Number(barset_Product[5]);

    var barset_Price =  barCodes.barset_price.split(','); 
    this.selectedData.sp_price_x = barset_Price[0];
    this.selectedData.sp_price_y = barset_Price[1];
    this.selectedData.rotation_price_id = Number(barset_Price[2]);
    this.selectedData.price_font = Number(barset_Price[3]);
    this.selectedData.sp_price_mult_x = Number(barset_Price[4]);
    this.selectedData.sp_price_mult_y = Number(barset_Price[5]);

    var barset_Pdet_Epl =  barCodes.barset_pdet_epl.split(','); 
    this.selectedData.sp_price_pdet_x = barset_Pdet_Epl[0];
    this.selectedData.sp_price_pdet_y = barset_Pdet_Epl[1];
    this.selectedData.rotation_price_pdet_id = Number(barset_Pdet_Epl[2]);
    this.selectedData.price_pdet_font = Number(barset_Pdet_Epl[3]);
    this.selectedData.sp_price_pdet_mult_x = Number(barset_Pdet_Epl[4]);
    this.selectedData.sp_price_pdet_mult_y = Number(barset_Pdet_Epl[5]);

    var barset_Currency =  barCodes.barset_currency.split(','); 
    this.selectedData.sp_curr_x = barset_Currency[0];
    this.selectedData.sp_curr_y = barset_Currency[1];
    this.selectedData.rotation_curr_id = Number(barset_Currency[2]);
    this.selectedData.curr_font = Number(barset_Currency[3]);
    this.selectedData.sp_curr_mult_x = Number(barset_Currency[4]);
    this.selectedData.sp_curr_mult_y = Number(barset_Currency[5]);

    var barset_Prate =  barCodes.barset_prate_epl.split(','); 
    this.selectedData.sp_prate_x = barset_Prate[0];
    this.selectedData.sp_prate_y = barset_Prate[1];
    this.selectedData.rotation_prate_id = Number(barset_Prate[2]);
    this.selectedData.prate_font = Number(barset_Prate[3]);
    this.selectedData.sp_prate_mult_x = Number(barset_Prate[4]);
    this.selectedData.sp_prate_mult_y = Number(barset_Prate[5]);
    
    var barset_Mfg =  barCodes.barset_mfg_epl.split(','); 
    this.selectedData.sp_mfg_x = barset_Mfg[0];
    this.selectedData.sp_mfg_y = barset_Mfg[1];
    this.selectedData.rotation_mfg_id = Number(barset_Mfg[2]);
    this.selectedData.mfg_font = Number(barset_Mfg[3]);
    this.selectedData.sp_mfg_mult_x = Number(barset_Mfg[4]);
    this.selectedData.sp_mfg_mult_y = Number(barset_Mfg[5]);

    var barset_Exp =  barCodes.barset_exp_epl.split(','); 
    this.selectedData.sp_exp_x = barset_Exp[0];
    this.selectedData.sp_exp_y = barset_Exp[1];
    this.selectedData.rotation_exp_id = Number(barset_Exp[2]);
    this.selectedData.exp_font = Number(barset_Exp[3]);
    this.selectedData.sp_exp_mult_x = Number(barset_Exp[4]);
    this.selectedData.sp_exp_mult_y = Number(barset_Exp[5]);

    var barset_Shp =  barCodes.barset_shop_epl.split(','); 
    this.selectedData.sp_shop_x = barset_Shp[0];
    this.selectedData.sp_shop_y = barset_Shp[1];
    this.selectedData.rotation_shop_id = Number(barset_Shp[2]);
    this.selectedData.exp_font = Number(barset_Shp[3]);
    this.selectedData.sp_shop_mult_x = Number(barset_Shp[4]);
    this.selectedData.sp_shop_mult_y = Number(barset_Shp[5]);
    

    this.result='';
    $('#add').hide();
    $('#update').show();
  }


  createProGen(form)
  {  
  this.apiService.addProfile(form.value).subscribe((res) => {

    if (res.error != null) {    
    this.result = res.error;
    }else
    {
      this.coreService.showMessage('Added Successfully');
      this.addFlag=1; 
      console.log(this.addFlag);   
      this.barCodeSelAdd(res['data']);
    }
 
  }); 
  }

  barCodeSelAdd(barCodes)
  {
    this.selectedData = barCodes; 
    var barset_BarCode =  barCodes.barset_barcode.split(','); 
    this.selectedData.start_position_x = barset_BarCode[0];
    this.selectedData.start_position_y = barset_BarCode[1];
    this.selectedData.rotation_id = Number(barset_BarCode[2]);  
    this.selectedData.narrow_bar_width = barset_BarCode[4]; 
    this.selectedData.wide_bar_width = barset_BarCode[5];  
    this.selectedData.barcode_height = barset_BarCode[6]; 
    this.selectedData.barcode_displayno = barset_BarCode[7]; 
   
    if(this.selectedData.barcode_displayno =='B')
    {
     this.selectedData.barcode_displayno = 1; 
    }else
    {
    this.selectedData.barcode_displayno = 0; 
    }
    
    var barset_Product =  barCodes.barset_product.split(','); 
    this.selectedData.sp_item_x = barset_Product[0];
    this.selectedData.sp_item_y = barset_Product[1];
    this.selectedData.rotation_item_id = Number(barset_Product[2]);
    this.selectedData.item_font = Number(barset_Product[3]);
    this.selectedData.sp_item_mult_x = Number(barset_Product[4]);
    this.selectedData.sp_item_mult_y = Number(barset_Product[5]);

    var barset_Price =  barCodes.barset_price.split(','); 
    this.selectedData.sp_price_x = barset_Price[0];
    this.selectedData.sp_price_y = barset_Price[1];
    this.selectedData.rotation_price_id = Number(barset_Price[2]);
    this.selectedData.price_font = Number(barset_Price[3]);
    this.selectedData.sp_price_mult_x = Number(barset_Price[4]);
    this.selectedData.sp_price_mult_y = Number(barset_Price[5]);

    var barset_Pdet_Epl =  barCodes.barset_pdet_epl.split(','); 
    this.selectedData.sp_price_pdet_x = barset_Pdet_Epl[0];
    this.selectedData.sp_price_pdet_y = barset_Pdet_Epl[1];
    this.selectedData.rotation_price_pdet_id = Number(barset_Pdet_Epl[2]);
    this.selectedData.price_pdet_font = Number(barset_Pdet_Epl[3]);
    this.selectedData.sp_price_pdet_mult_x = Number(barset_Pdet_Epl[4]);
    this.selectedData.sp_price_pdet_mult_y = Number(barset_Pdet_Epl[5]);

    var barset_Currency =  barCodes.barset_currency.split(','); 
    this.selectedData.sp_curr_x = barset_Currency[0];
    this.selectedData.sp_curr_y = barset_Currency[1];
    this.selectedData.rotation_curr_id = Number(barset_Currency[2]);
    this.selectedData.curr_font = Number(barset_Currency[3]);
    this.selectedData.sp_curr_mult_x = Number(barset_Currency[4]);
    this.selectedData.sp_curr_mult_y = Number(barset_Currency[5]);

    var barset_Prate =  barCodes.barset_prate_epl.split(','); 
    this.selectedData.sp_prate_x = barset_Prate[0];
    this.selectedData.sp_prate_y = barset_Prate[1];
    this.selectedData.rotation_prate_id = Number(barset_Prate[2]);
    this.selectedData.prate_font = Number(barset_Prate[3]);
    this.selectedData.sp_prate_mult_x = Number(barset_Prate[4]);
    this.selectedData.sp_prate_mult_y = Number(barset_Prate[5]);
    
    var barset_Mfg =  barCodes.barset_mfg_epl.split(','); 
    this.selectedData.sp_mfg_x = barset_Mfg[0];
    this.selectedData.sp_mfg_y = barset_Mfg[1];
    this.selectedData.rotation_mfg_id = Number(barset_Mfg[2]);
    this.selectedData.mfg_font = Number(barset_Mfg[3]);
    this.selectedData.sp_mfg_mult_x = Number(barset_Mfg[4]);
    this.selectedData.sp_mfg_mult_y = Number(barset_Mfg[5]);

    var barset_Exp =  barCodes.barset_exp_epl.split(','); 
    this.selectedData.sp_exp_x = barset_Exp[0];
    this.selectedData.sp_exp_y = barset_Exp[1];
    this.selectedData.rotation_exp_id = Number(barset_Exp[2]);
    this.selectedData.exp_font = Number(barset_Exp[3]);
    this.selectedData.sp_exp_mult_x = Number(barset_Exp[4]);
    this.selectedData.sp_exp_mult_y = Number(barset_Exp[5]);

    var barset_Shp =  barCodes.barset_shop_epl.split(','); 
    this.selectedData.sp_shop_x = barset_Shp[0];
    this.selectedData.sp_shop_y = barset_Shp[1];
    this.selectedData.rotation_shop_id = Number(barset_Shp[2]);
    this.selectedData.exp_font = Number(barset_Shp[3]);
    this.selectedData.sp_shop_mult_x = Number(barset_Shp[4]);
    this.selectedData.sp_shop_mult_y = Number(barset_Shp[5]);
      
  
  }

  editProfile(form)
  {
  this.apiService.editProfile(form.value).subscribe((res) => {

      if (res.error != null) {    
      this.result = res.error;
      }else
      {
       this.coreService.showMessage('Updated');
       this.getSettings();
      }
   
    }); 
  }

  editItem(form)
  {
  
  this.apiService.editItem(form.value).subscribe((res) => {

      if (res.error != null) {    
      this.result = res.error;
      }else
      {
       this.coreService.showMessage('Updated');
       this.getSettings();
      }
   
    }); 
  }

  editBarcode(form)
  {  
  this.apiService.editBcode(form.value).subscribe((res) => {  
      if (res.error != null) {    
      this.result = res.error;
      }else
      {
       this.coreService.showMessage('Updated');
       this.getSettings();
      }
   
    }); 
  }


  editPrice(form)
  {    
  this.apiService.editPrice(form.value).subscribe((res) => {  
      if (res.error != null) {    
      this.result = res.error;
      }else
      {
       this.coreService.showMessage('Updated');
       this.getSettings();
      }
   
    }); 
  }


  editCurr(form)
  {  
  this.apiService.editCurr(form.value).subscribe((res) => {
      if (res.error != null) {    
      this.result = res.error;
      }else
      {
       this.coreService.showMessage('Updated');
       this.getSettings();
      }
   
    }); 
  }

  editPrate(form)
  {  
  this.apiService.editPrate(form.value).subscribe((res) => {
      if (res.error != null) {    
      this.result = res.error;
      }else
      {
       this.coreService.showMessage('Updated');
       this.getSettings();
      }
   
    }); 
  }

  editMfg(form)
  {  
  this.apiService.editMfg(form.value).subscribe((res) => {
      if (res.error != null) {    
      this.result = res.error;
      }else
      {
       this.coreService.showMessage('Updated');
       this.getSettings();
      }
   
    }); 
  }

  editExp(form)
  {  
  this.apiService.editExp(form.value).subscribe((res) => {
       if (res.error != null) {    
      this.result = res.error;
      }else
      {
       this.coreService.showMessage('Updated');
       this.getSettings();
      }
   
    }); 
  }

  editShop(form)
  {  
  this.apiService.editShop(form.value).subscribe((res) => {
      if (res.error != null) {    
      this.result = res.error;
      }else
      {
       this.coreService.showMessage('Updated');
       this.getSettings();
      }
   
    }); 
  }


   // filter  types
   sortType = [
    { id: 0, name: 'Normal' },
    { id: 1, name: '90 Degrees' },
    { id: 2, name: '180 Degrees' },
    { id: 3, name: '270 Degrees' } 
  ];

  sortTypeDarkness = [
    { id: 0, name: '0' },
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },
    { id: 8, name: '8' },
    { id: 9, name: '9' },
    { id: 10, name: '10' },
    { id: 11, name: '11' },
    { id: 12, name: '12' },
    { id: 13, name: '13' },
    { id: 14, name: '14' },
    { id: 15, name: '15' }  
  ];

  fontSize = [
    { id: 0, name: '0' },
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },
    { id: 8, name: '8' },
    { id: 9, name: '9' },
    { id: 10, name: '10' },
    { id: 11, name: '11' },
    { id: 12, name: '12' },
    { id: 13, name: '13' },
    { id: 14, name: '14' },
    { id: 15, name: '15' }  
  ];

}
