import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageComponent } from './page/page.component';
import { PayableDetaildComponent } from './payable-detaild/payable-detaild.component';
import { OutstandingRecievableComponent } from './outstanding-recievable/outstanding-recievable.component';


const routes: Routes = [
  { path: 'dashboard', component: PageComponent },
  { path: 'outstanding_Payable', component: PayableDetaildComponent },
  { path: 'outstanding_recievable', component: OutstandingRecievableComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
