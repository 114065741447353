import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Repprddet } from '../../../../../model/report/repprddet.model';
import * as XLSX from 'xlsx';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-rep-openingstock',
  templateUrl: './rep-openingstock.component.html',
  styleUrls: ['./rep-openingstock.component.css']
})
export class RepOpeningstockComponent implements OnInit {

  fileName = 'Opening_stock_summary.xlsx';

  elseBlocknostart: any;
  elseBlocknoend: any;
  prd_id: any;
  osq_type: any;
  osq_val1: any;
  osq_val2: any;
  opr_type: any;
  opr_val1: any;
  opr_val2: any;

  branch_display_name:any;
  branch_display_code:any;
  branch_address:any;
  untDef = 0;
  prodsumms: string[];
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  prodLength: number;
  dataProd: string;
  totalProducts: string;
  totalQuantity: string;
  totalPurchamt: string;
  stkprd_all: [];
  pageLoadingImg: boolean;
  userType :any;
  godown_all: any;
  branch_all: any;
  filter_branch_id: number;
  up: any;
  exportLoader: boolean;
  exp_info: any;
  country_dec: number=2;
  cur_lang: string;
  PrdName: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private translate: TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');
   this.cur_lang = sessionStorage.getItem("baseLang");
   this.apiService.cur_lang.subscribe(value =>{
     this.cur_lang = value;
   })
    this.country_dec=this.coreService.setDecimalLength();
    this.up = this.coreService.getUserPrivilage();
    this.getAllBranch();
    this.filter_branch_id = 1;
    this.userType =  this.coreService.getUserData('user_type');
    this.pageLoadingImg = true;
    let searchval = new FormData();
    this.apiService.OpStokcSum(searchval, 1).subscribe((res: Repprddet) => {
      this.pageLoadingImg = false;
      this.prodsumms = res['data']['data'];
      this.totalProducts = res['data'].total_products;
      this.totalQuantity = res['data'].total_quantity;
      this.totalPurchamt = res['data'].total_purch_amt;
      this.curpage = res['data'].current_page;
      this.lastpage = res['data'].last_page;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }
  langChange(){
    this.translate.get(['Common.base_unit','Common.default_unit',]).subscribe((res: string) => {

 
      this.filter_defalut_unit = [
        { id: 0, name: res['Common.base_unit'] },
        { id: 1, name: res['Common.default_unit']}
      ];
    });

  }

  ngOnDestroy(){
    $('.close').click();
 
  }


  filter_types = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];
  filter_defalut_unit = [
    { id: 0, name: 'Base Unit' },
    { id: 1, name: 'Default Unit'}
  ];


  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;
  
  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.userType = this.coreService.getUserData('user_type');
      this.branch_all = res['data'];
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];

    });
  }
  pageNext(formdata: { value: any; }, pageno: any) {
  $('.resultdata').empty();
  this.pageLoadingImg = true;

   
    let searchval = new FormData();
    searchval.append("pageno", pageno);
    this.apiService.OpStokcSum(formdata.value, pageno).subscribe((res: Repprddet) => {
    this.pageLoadingImg = false;

      if (res['data'] != null){

      this.prodsumms = res['data']['data'];
      this.totalProducts = res['data'].total_products;
      this.totalQuantity = res['data'].total_quantity;
      this.totalPurchamt = res['data'].total_purch_amt;
      this.curpage = res['data'].current_page;
      this.lastpage = res['data'].last_page;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    
    }else{
    
      this.prodsumms = res['data'];
      this.totalProducts = '0';
      this.totalQuantity = '0';
      this.totalPurchamt = '0';
    }

    });
  }

  // search godown
  searchGodown(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      this.godown_all.push({ "gd_id": 'Shop', 'gd_name': this.coreService.getUserData('br_default_shop_name') });
      this.godown_all.push({ "gd_id": 'ALL', 'gd_name': 'ALL' });
      
    });

  }

  //product form search 
  searchStkPrd(search: string) {
    let searchval = new FormData();
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    }
    searchval.append("keyword", search);
    this.apiService.getPrdRepkbyName(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];

    });
  } 

  counter(i: number) {
    return new Array(i);
  }


  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    //const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });


    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  exportEXCL(formdata: { value: any; }, pageno: any) {
    this.exportLoader = true;
    formdata.value.export = 'export';


    this.apiService.OpStokcSum(formdata.value, 1).subscribe((res: any) => {
   


      this.exp_info = res['data']['data'];
      this.totalProducts = res['data'].total_products;
      this.totalQuantity = res['data'].total_quantity;
      this.totalPurchamt = res['data'].total_purch_amt;
     console.log( this.totalPurchamt);
     
      setTimeout(() => {
        this.exportexcel();
      this.exportLoader = false;

      }, 2000);

    });
  }


  // pdf download
  generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;
    formdata.value.export = 'export';


    this.apiService.OpStokcSum(formdata.value, 1).subscribe((res: any) => {
   


      this.exp_info = res['data']['data'];
      this.totalProducts = res['data'].total_products;
      this.totalQuantity = res['data'].total_quantity;
      this.totalPurchamt = res['data'].total_purch_amt;
     console.log( this.totalPurchamt);
     
     const heads = [
      this.translate.instant('Common.prd_name'),
      this.translate.instant('Table.barcode'),
      this.translate.instant('HOME.PURCHASE') + '\n' + this.translate.instant('Table.rate'),
      this.translate.instant('HOME.SALES') + '\n' + this.translate.instant('Table.rate'),
      this.translate.instant('HOME.opening') + '\n' + this.translate.instant('Purchase.qty'),
      this.translate.instant('HOME.CATAGORY'),
      this.translate.instant('Common.unit_name'),
      this.translate.instant('HOME.PURCHASE') + '\n' + this.translate.instant('Table.price')
     ];
 
       const lblXPos = 10;
       const headerHeight = 15;
       const valueXPos = 55;
       const data = [];
 
       this.exp_info.forEach(item=>{
        if(this.translate.currentLang == "Arabic"){this.PrdName = item.prd_alias}else{this.PrdName = item.  prd_name}

        data.push([
          this.PrdName,
          item.prd_barcode,
          (item.purchase_price).toFixed(this.country_dec),
          (item.sales_price).toFixed(this.country_dec),
          this.currencyPipe.transform(item.open_stock_qty, '', '', '1.1-1'),
          item.cat_name,
          item.unit_name,
          (item.purch_amount).toFixed(this.country_dec)
        ])
       })
   
       let doc = this.excelService.getPdfObj();
 
       var addressLines = this.branch_address.split('\r\n');
       var addressText = addressLines.join(', ');
 
       doc.setFont('Amiri');
       doc.setFontSize(12);
       doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
 
       doc.setFont('Amiri');
       doc.setFontSize(9);
       doc.text(addressText, 71, headerHeight + 2);
 
       doc.setFontSize(10);
       doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 10);
       doc.setFontSize(10);
       doc.text(':  ' + `${this.translate.instant('Common.op_stock_summary')}`,  valueXPos, headerHeight + 10);
 
       doc.setFontSize(10);
       doc.text(`${this.translate.instant('HOME.total_no_of_products')}`, lblXPos, headerHeight + 15);
       doc.setFontSize(10);
       doc.text(':  ' + this.totalProducts,  valueXPos, headerHeight + 15);

       doc.setFontSize(10);
       doc.text(`${this.translate.instant('Common.total_quantity')}`, lblXPos, headerHeight + 20);
       doc.setFontSize(10);
       doc.text(':  ' + this.currencyPipe.transform(this.totalQuantity, '', '', '1.1-1'),  valueXPos, headerHeight + 20);

       doc.setFontSize(10);
       doc.text(`${this.translate.instant('Common.total_amount')}`, lblXPos, headerHeight + 25);
       doc.setFontSize(10);
       doc.text(':  ' + (this.totalPurchamt),  valueXPos, headerHeight + 25);
       
       doc = this.excelService.addTableToPdf(doc, headerHeight + 30, lblXPos, heads, data, null);
   
      
       doc.save(`Opening Stock Summary.pdf`);

       this.exportLoader = false;

    });
  }


}
