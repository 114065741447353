import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { ValueTransformer } from '@angular/compiler/src/util';
import { NgForm } from '@angular/forms';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  private modalRef: NgbModalRef; closeResult: string; stkprd_all: string[]; selecTed: any = {}; godown_all: any[]; trans_stocks = []; slno = 0;
  printError = []; errObjArr = {
    unitqtyErr: null, gdErr: null,
    prodErr: null, dtErr: null, unitErr: null, dmgdErr: null, dmqtyErr: null
  }; units_all: any[]; damage_all: any[]; sel_unit: any[]; unit_qty: any; unit: any; gsl_from: any;
  up: any;
  DmgselecTed: any;
  godowns: any;
  stk_unit_qty: any;
  pageLoadingImg: boolean;
  rempageLoadingImg: boolean;
  unit_base_qty: any;
  unit_name: any;
  noItem: boolean;
  stkprdsel: any;
  qty: string;
  damage_notes: string;
  noItemforBarcodeMsg: boolean;
  country_dec: string;
  prod_alias: any;
  alias: any;
  cur_lang: string;
  checkBatch=false;
  batches=[];
  selBatch: any;
  BatchCount=false;
  BatchName: null;
  constructor(private apiService: ApiService, private coreService: CoreService,
    private modalService: NgbModal,private translate: TranslateService) { }

  ngOnInit() {
    this.getDamageList('');
    this.up = this.coreService.getUserPrivilage();
    this.searchGdwn('');
    this.noItem = false;
    this.noItemforBarcodeMsg = false;

    this.country_dec=this.coreService.setDecimalLength();
    console.log(this.country_dec)
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.aliasChecked()
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      this.aliasChecked()
    })

    this.apiService.getAllClientSettings().subscribe((res) => {
      if (res['data']) {
        this.checkBatch = (res['data']['Batch Support']['cs_value']) ? true : false;
      }

    });
  }

aliasChecked(){
  if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
    this.prod_alias = 'alias_true';
    this.alias='alias_checked';
  }else{
    this.prod_alias =null;
    this.alias=null;
  }
}
  openModal(content) {

    this.modalRef = this.modalService.open(content, { size: 'lg', backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });


  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  selectStockPrdSel(stkprdsel) {
    if (stkprdsel) {
      this.unit = null;
      this.gsl_from = null;
      this.selecTed = stkprdsel;
      this.getGodown('');
      this.getUnits();
      this.getBatches();
    }
  }


  searchStkPrdBarcode(search: any) {
    let searchval = new FormData();
    searchval.append("barcode", search);
    this.apiService.getStockbyBarcode(searchval).subscribe((res) => {
      // this.stkprd_all = res['data'];
      if (res.data) {
        this.noItemforBarcodeMsg = false;
        this.unit = res.data.produnit_unit_id;
        this.gsl_from = null;
        this.selecTed = res['data'];
        this.getGodown('');
        this.getUnits();
        this.stkprdsel = {
          prd_name: res.data.prd_name

        };
        this.selecTed.prd_code = search;
        this.unit_qty = 0;

      } else {
        this.selecTed = [];
        this.noItemforBarcodeMsg = true;
        this.unit = null;
        this.stkprdsel = {
          prd_name: null

        };

      }
      //  console.log(res.data.length);

      //  console.log(res['data']);

      $('#addpurchase').hide();

    });
  }


  SelectDamagedProd(content, Dmgprdsel) {


    this.modalRef = this.modalService.open(content, { size: 'lg', backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });



    this.unit = null;
    this.gsl_from = null;
    this.DmgselecTed = Dmgprdsel;
    this.DmgselecTed.oldQty = Dmgprdsel.damage_qty;

    this.getGodown('');
    this.getUnits();
  }


  //form search product
  searchStkPrd(search: any, eventChar) {
    let searchval = new FormData();
    searchval.append("keyword", search);
    if(this.prod_alias){
      searchval.append('alias_search', '1');
    }
    this.apiService.getStockbyItem(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];
      $('#addpurchase').hide();

    });
  }

  getGodown(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    searchval.append("prd_id", this.selecTed.prd_id);
    this.apiService.getGodownItemwise(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      this.godown_all.push({ "gd_id": 0, 'gd_name': this.coreService.getUserData('br_default_shop_name') });

    });

  }

  searchGdwn(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godowns = res['data'];
      this.godowns.push({ "gd_id": 0, 'gd_name': this.coreService.getUserData('br_default_shop_name') });
    });

  }

  getUnits() {
    let searchval = new FormData();
    searchval.append("prd_id", this.selecTed.prd_id);
    this.apiService.getUnitsItemwise(searchval).subscribe((res) => {
      this.units_all = res['data'];

    });

  }

  selectGd(godown_id) {

    let searchval = new FormData();
    searchval.append("prd_id", this.selecTed.prd_id);
    searchval.append("gd_id", godown_id);
    this.apiService.getGodownStock(searchval).subscribe((res) => {
      console.log(godown_id);
      
      if (godown_id != 0) {
        this.selecTed.bs_stock_quantity_shop = res['data'][0]['gs_qty'];
      } else {
        this.selecTed.bs_stock_quantity_shop = res['data'][0]['bs_stock_quantity_shop'];
      }
      this.selectUnits(this.unit);

    });
    // this.unit = null;
    this.getBatches();
  }

  addToList(form) {
    // console.log(form.value);

    if(this.selBatch > 0){
      this.BatchCount = true;
    }
    this.BatchName =null;
    if(this.batches.length > 0){
      this.batches.forEach(element => {
           
        if(element.sb_id == this.selBatch){
          this.BatchName =element.sb_batch_code;
        }

      });
    }
    this.printError = [];
    if (form.value.qty == "" || form.value.qty == undefined) {
      this.errObjArr.unitqtyErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    // else if (form.value.qty > this.unit_qty) {
    //   this.errObjArr.unitqtyErr = "t";
    //   this.printError.push({ 'fieldErr': 'Required' });
    // }
    else {
      this.errObjArr.unitqtyErr = "f";
    }

    if (form.value.gsl_from == undefined) {
      this.errObjArr.gdErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      this.errObjArr.gdErr = "f";
    }


    if (form.value.unit == undefined) {
      this.errObjArr.unitErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      this.errObjArr.unitErr = "f";
    }

    if (form.value.damage_date == undefined) {
      this.errObjArr.dtErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      if (form.value.damage_date > new Date()) {
        this.errObjArr.dtErr = "t";
        this.printError.push({ 'fieldErr': 'Required' });
      } else {
        this.errObjArr.dtErr = "f";
      }
    }

    if (form.value.stkprdsel == undefined) {
      this.errObjArr.prodErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      this.errObjArr.prodErr = "f";
    }


    if (this.printError.length <= 0) {
      this.slno = this.trans_stocks.length + 1;
      //check duplicate
      const { length } = this.trans_stocks;
      const id = length + 1;
      const prd = this.trans_stocks.some(el => el.prd_id === form.value.prd_id);
      const gsl_from = this.trans_stocks.some(el => el.gsl_from === form.value.gsl_from);

      // if (!prd)
        this.trans_stocks.push({
          "sl_no": this.slno, "sb_id": this.selBatch||'', "sb_batch_code": this.BatchName||'', "prd_id": form.value.prd_id, "itemname": form.value.itemname,"itemalias": form.value.itemalias, "qty": form.value.qty * this.unit_base_qty, "unit_name": this.unit_name,"unit_id": this.sel_unit, "added_qty": form.value.qty, "gsl_from": form.value.gsl_from,
          "damage_date": form.value.damage_date, "damage_notes": form.value.damage_notes
        });
      this.unit = null;
      this.gsl_from = null;
      this.selBatch =null;
      // form.reset();
      this.selecTed = {};
      this.stkprdsel = this.stkprdsel[0];
      // this.stkprdsel = '';
      this.qty = '';
      this.stk_unit_qty = '';
      this.damage_notes = '';

    }

  }


  deleteProd(data) {
    var remopstk = confirm("Removing Selected Product from the table");
    if (remopstk) {
      var type = data.sl_no,
        i;
      for (i = this.trans_stocks.length - 1; i >= 0; --i) {
        if (this.trans_stocks[i].sl_no == type) {
          this.trans_stocks.splice(i, 1);
        }
      }

    }
  }

  submitDamage(form: NgForm) {

    if (this.trans_stocks.length > 0) {


      if (confirm('Do you wish to continue?')) {
        this.noItem = false;

        form.value.prdlist = this.trans_stocks;
        form.value.damage_date = (form.value.damage_date) ?
          this.apiService.formatDate(form.value.damage_date) : form.value.damage_date;
        // console.log(form.value.damage_date);
        this.pageLoadingImg = true;
        this.apiService.addDamage(form.value).subscribe((res) => {
          this.coreService.showMessage('Added Successfully');
          this.trans_stocks = [];
          form.reset();
          this.selecTed = [];
          this.modalRef.close();
          this.unit = null;
          this.pageLoadingImg = false;


          this.getDamageList('');
          this.BatchCount=false;
        });
      }

    } else {
      this.noItem = true;
    }
  }


  validateRemove(form) {
    // console.log(form);

    this.printError = [];

    if (form.value.gsl_from == undefined) {
      this.errObjArr.dmgdErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
      return false;
    }
    else {
      this.errObjArr.dmgdErr = "f";
    }
    if (form.value.rem_qty - form.value.itemqty < 0 || form.value.itemqty == "") {
      this.errObjArr.dmqtyErr = "t";
      this.printError.push({ 'fieldqtyErr': 'Qty Should Be Less Than Added Qty' });
      //  console.log(this.printError);
      return false;
    }
    else {
      this.errObjArr.dmqtyErr = "f";
      this.printError = [];

    }

    if (form.value.itemqty > form.value.itemoldqty) {
      this.errObjArr.dmqtyErr = "t";
      this.printError.push({ 'fieldqtyErr': 'Qty Should Be Less Than Added Qty' });
      return false;
    }
    else {
      this.errObjArr.dmqtyErr = "f";
      this.printError = [];

    }


    // console.log(this.printError[1].fieldqtyErr);

    return true;
  }



  getDamageList(prd_id) {
    let searchval = new FormData();
    searchval.append("keyword", prd_id);
    if(this.alias){
      searchval.append('alias_search', '1');
    }
    this.apiService.getDamageList(searchval).subscribe((res) => {
      this.damage_all = res['data'];

    });

  }


  selectUnits(unitid: string) {
    if (unitid) {
      this.sel_unit = this.units_all.find(x => x.unit_id === unitid).unit_id;
      this.unit_base_qty = this.units_all.find(x => x.unit_id === unitid).unit_base_qty;
      this.unit_name = this.units_all.find(x => x.unit_id === unitid).unit_name;
      this.unit_qty = this.selecTed.bs_stock_quantity_shop / this.units_all.find(x => x.unit_id === unitid).unit_base_qty;
      // console.log(this.units_all.find(x => x.unit_id === unitid).unit_base_qty);
      console.log(this.unit_base_qty);
    }
  }



  // removeDamage(form) {
  removeDamage(id) {
    // let ret = this.validateRemove(form);
   

    // if (ret) {

    console.log("id");
    console.log(id);
      this.rempageLoadingImg = true;

      if (confirm('Do you wish to continue?')) {

        const searchval = new FormData();
        searchval.append('damage_id', id);
      

        // this.apiService.voidDamage(form.value).subscribe((res) => {
        this.apiService.voidDamage(searchval).subscribe((res) => {
          this.coreService.showMessage('Removed Successfully');
          // this.modalRef.close();
          this.getDamageList('');
          this.rempageLoadingImg = false;

        });
      } else {
        this.rempageLoadingImg = false;

      }

    // }

  }

  getBatches(){
    if(this.checkBatch) {
      
      this.batches =[];
      this.selBatch = null;
      const searchval = new FormData();
      searchval.append('prd_id', this.stkprdsel.prd_id);
      searchval.append('gd_id', this.gsl_from);
      this.apiService.getProductBatches(searchval).subscribe((res) => {
  
  
        if(res.data.batches.length > 0 ){
  
          this.batches=res.data.batches;
          console.log("batches");
          console.log( this.batches);
        
        }
        
      });

    }
   
  }

  changeBatch(){

    if(this.stkprdsel && !this.selBatch){
       this.getGodown('');
       this.getUnits();
      //  this.getBatches();
       this.selectUnits(this.unit);
       this.selectGd(this.gsl_from);
       // this.getBatches();
    }

    if(this.stkprdsel && this.selBatch){

      const searchval1 = new FormData();
      searchval1.append('prd_id',  this.stkprdsel.prd_id);
      searchval1.append('gd_id_from', this.gsl_from);
      searchval1.append('gd_id_to', this.gsl_from);
      searchval1.append('batch_id', this.selBatch);

     
    
      this.apiService.getGodownBatchStockTotal(searchval1).subscribe((resp) => {
       
       this.stk_unit_qty = resp['data']['from_gd_stock'].gbs_qty || 0;
       this.selecTed.bs_stock_quantity_shop = resp['data']['from_gd_stock'].gbs_qty || 0;
       
      });
    }
    
    
}




}
