import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../../service/core.service';
import { ApiService } from '../../../../../../service/api.service';
import { Sales } from '../../../../../../model/report/sales.model';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import * as XLSX from 'xlsx'; 
import pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { CurrencyPipe } from '@angular/common';
import { ExcelService } from 'src/app/service/excel.service';

pdfMake.vfs = pdfFonts.pdfMake.vfs; 
pdfMake.fonts = { 'Roboto': { normal: 'Roboto-Regular.ttf', bold: 'Roboto-Medium.ttf', italics: 'Roboto-Italic.ttf', bolditalics: 'Roboto-Italic.ttf' },
 'THSarabunNew': { normal: 'THSarabunNew.ttf' }, 'code128': { normal: 'code128.ttf' } }


pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-salesandreturn',
  templateUrl: './salesandreturn.component.html',
  styleUrls: ['./salesandreturn.component.css']
})
export class SalesandreturnComponent implements OnInit {
  fileName= 'sales_and_return_summary.xlsx';
  filter_branch_id: number;
  userType: any;
  branch_all: any;
  from: any;
  vch_type: any;
  up: any;
  customer: any;
  vans: any;
  saleDiscTotal: any;
  exp_saleDiscTotal: any;
  saleInclVat: any;
  exp_saleInclVat: any;
  returnDiscTotal: any;
  exp_returnDiscTotal: any;
  retInclVat: any;
  exp_retInclVat: any;
  retVat: any;
  exp_retVat: any;
  retExclVat: any;
  exp_retExclVat: any;
  balanceExclAmt: any;
  saleVat: any;
  exp_saleVat: any;
  saleExclVat: any;
  exp_saleExclVat: any;
  balanceExclVatAmt: any;
  exp_balanceExclVatAmt: any;
  pdf: any;
  pdfalias: any;
  branch_display_name: any;
  branch_display_code: any;
  branch_address:any;
  country_dec: number=2;
  cur_lang: string;
  body_pdf: any;
  user_name: any;
  customer_category: any;
  customer_sub_category: any;
  cust_category: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private currencyPipe: CurrencyPipe,private excelService: ExcelService) { }
  pageLoadingImg:boolean;
  slnum:number;
  curpage:number;
  lastpage:number;
  pgend:boolean;
  pgstart:boolean;
  report:any;
  purchaseTotal;
  returnTotal;
  exp_returnTotal;
  balanceAmt;
  exp_balanceAmt;
  supplierAll:any;
  values:any;
  resultobj:any;
  period_type:any;
  date1:any;
  date2:any;
  time1:any;
  time2:any;
  inv_filter:any;
  voucher_filter:any;
  reportType:any;
  exp_reportType:any;
  repDate:any;
  purch_supp_id:any;

  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  filter_invno = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_salamt = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_saldic = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_servamt = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_paytype = [
    { id: 1, name: 'Credit' },
    { id: 0, name: 'Cash' },
    { id: 2, name: 'Bank' }

  ];

  filter_purchtypes = [
    { id: '1', name: 'NonVoided' },
    { id: '0', name: 'Voided' },

  ];
  voucher_type = [
    { id: '1', name: 'Sales & Sales Return' },
    { id: '2', name: 'Sales' },
    { id: '3', name: 'Sales Return' },


  ];
  filter_custtyp = [
    { id: '1', name: 'Registered' },
    { id: '0', name: 'Non Registered' },

  ];
  

  payType = ['Cash','Credit','Bank'];
  godowns:any;
  exportLoader: boolean;
  exp_report:any;
  exp_purchaseTotal;
  Expshow: any;

  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.exportLoader = false;
    this.searchUser('');
    this.searchSupplier('');
    this.up = this.coreService.getUserPrivilage();

    this.getAllBranch();
    this.getGodownList();
    this.filter_branch_id = 1;
    this.userType =  this.coreService.getUserData('user_type');
    
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('rep_type','1');
    this.vch_type = '1';
    this.apiService.salesandRetunSummary(searchval,1).subscribe((res: any) => {
      this.pageLoadingImg = false;

      this.report = res.data.data;
      this.from = res.data.from;

      // this.purchInvSum = res.data.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.purchaseTotal = res.data.totalCount.purchaseTotal;
      this.saleDiscTotal = res.data.totalCount.saleDic;
      this.saleInclVat = res.data.totalCount.saleinclVat;
      this.saleVat = res.data.totalCount.saleVat;
      this.saleExclVat = res.data.totalCount.saleExclVat;
      this.balanceAmt = res.data.totalCount.balanceAmt;
      this.reportType = res.data.reportType;
      this.user_name = res.data;

      this.returnTotal = res.data.totalCount.returnTotal;
      this.returnDiscTotal = res.data.totalCount.returnDisc;
      this.retInclVat = res.data.totalCount.retInclVat;
      this.retVat = res.data.totalCount.returnVat;
      this.retExclVat = res.data.totalCount.returnExclVat;
      this.balanceExclVatAmt = res.data.totalCount.balanceExclAmt;


      this.reportType = res.data.reportType;
      this.pdf = res.data.pdf;
      this.pdfalias = res.data.pdfalias;
      

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
  
    this.country_dec=this.coreService.setDecimalLength();
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.getAllCustomerCategory();
    // this.getAllCustomerSubCategory();
   
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','HOME.credit','Common.cash','HOME.Bank','Common.NonVoided','Production.Voided','Common.sales_and_return','HOME.SALES','HOME.sales_return','Common.registerd','Common.non_registerd']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.filter_paytype = [
        { id: 1, name: res['HOME.credit']},
        { id: 0, name: res['Common.cash']  },
        { id: 2, name: res['HOME.Bank'] }
    
      ];
    
      this.filter_purchtypes = [
        { id: '1', name: res['Common.NonVoided'] },
        { id: '0', name: res['Production.Voided'] },
    
      ];
      this.voucher_type = [
        { id: '1', name: res['Common.sales_and_return'] },
        { id: '2', name: res['HOME.SALES'] },
        { id: '3', name: res['HOME.sales_return'] },
    
    
      ];
      this.filter_custtyp = [
        { id: '1', name: res['Common.registerd'] },
        { id: '0', name: res['Common.non_registerd'] },
    
      ];
    });

  }

  ngOnDestroy(){
    $('.close').click();
 
  }
  // generatePdf() {
                 
  //   if(this.translate.currentLang == 'Arabic'){
  //     this.body_pdf=this.pdfalias;
  //      }else{
  //       this.body_pdf=this.pdf;
  //      }               
  //   var filename = 'Sales And Return Summary';
    
  
  // // Declaring your layout
  //   var myTableLayouts = {
  //   exampleLayout: {
  //     fontSize: 11,
  //      bold: false,
  //   }
  //   };
  
  
  //   var docDefinition = {
  //       content: [
  //        {
  //           text: this.branch_display_name,
  //           bold: true,
  //           fontSize: 13,
  //           alignment: 'center',
  //           margin: [0, 0, 0, 20]
  //         },
  //         {
  //           text: filename,
  //           bold: true,
  //           fontSize: 16,
  //           alignment: 'center',
  //           margin: [0, 0, 0, 20]
  //         },
  //         {
  //           columns: [
  //             [
  //               // {
  //               //   text:(this.repDate)? 'Duration : '+this.repDate.date1+' - '+this.repDate.date2:'',
  //               //   fontSize: 11,
  //               //   margin: [0, 0, 0, 12]
  //               // },
  //               {
  //                 text:(this.reportType)? this.reportType:'',
  //                 fontSize: 11,
  //                 margin: [0, 0, 0, 12]
  //               },
  //             {
  //               text: 'Net Sales Amount : '+(this.purchaseTotal).toFixed(this.country_dec),
  //               fontSize: 11,
  //               margin: [0, 0, 0, 12]
  //             },
  //             {
  //               text: 'Total Discount : '+(this.saleDiscTotal).toFixed(this.country_dec),
  //               fontSize: 11,
  //               margin: [0, 0, 0, 12]
  //             },
  //             {
  //               text: 'Total Amount Incl. Vat : '+(this.saleInclVat).toFixed(this.country_dec),
  //               fontSize: 11,
  //               margin: [0, 0, 0, 12]
  //             },
  //             {
  //               text: 'Sales VAT Amt.: '+(this.saleVat).toFixed(this.country_dec),
  //               fontSize: 11,
  //               margin: [0, 0, 0, 12]
  //             },
  //             {
  //               text: 'Total Amount Excl. VAT : '+(this.saleExclVat).toFixed(this.country_dec),
  //               fontSize: 11,
  //               margin: [0, 0, 0, 12]
  //             },
              
  //             {
  //               text: 'Balance Amount : '+(this.balanceAmt).toFixed(this.country_dec),
  //               fontSize: 11,
  //               margin: [0, 0, 0, 12]
  //             },
              
  //             {
  //               text: 'Net Return Amount  : '+(this.returnTotal).toFixed(this.country_dec),
  //               fontSize: 11,
  //               margin: [0, 0, 0, 12]
  //             },
  
  //             {
  //               text: 'Total Return Discount : '+(this.returnDiscTotal,'', '', this.country_dec),
  //               fontSize: 11,
  //               margin: [0, 0, 0, 20]
  //             },
  //             {
  //               text: 'Total Return Incl. Vat : '+(this.retInclVat,'', '', this.country_dec),
  //               fontSize: 11,
  //               margin: [0, 0, 0, 20]
  //             },
  //             {
  //               text: 'Return VAT Amount : '+(this.retVat,'', '', this.country_dec),
  //               fontSize: 11,
  //               margin: [0, 0, 0, 20]
  //             },
  //             {
  //               text: 'Total Return Amount Exclude VAT : '+(this.retExclVat,'', '', this.country_dec),
  //               fontSize: 11,
  //               margin: [0, 0, 0, 20]
  //             },
  //             {
  //               text: 'Balance Amount Exclude VAT : '+(this.balanceExclVatAmt,'', '', this.country_dec),
  //               fontSize: 11,
  //               margin: [0, 0, 0, 20]
  //             },
    
  //             ],
  //             [
  //               // Document definition for Profile pic
  //             ]
  //           ],
  //           columnStyles: { comment: { columnWidth: 'auto' } },
  //           styles: {
  //             font: 'THSarabuNew',
  //             name: {
  //               fontSize: 11,
  //             },
  //             tableExample: {
  //               fontSize: 6,
  //             },
  //           },
  //         },
        
  //         {
  //           style: 'tableExample',
  //           table: {
  //             // headers are automatically repeated if the table spans over multiple pages
  //             // you can declare how many rows should be treated as headers
             
  //             fontSize: 11,
  //             headerRows: 1,
  //             styles: {
  //               cellPadding: 0.5,
  //               fontSize: 7
  //           },
  //           // startY: 30, /* if start position is fixed from top */
  //           tableLineColor: [0, 0, 0], // choose RGB
  //           tableLineWidth: 0.5, // table border width
  //            // widths: [ '*', 'auto', '*', '100','*','*',,'*','*','*' ,'*' ],
  //             widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto','auto'],
  //             body: this.body_pdf,
  //             bodyStyles: {
  //               margin: 30,
  //               fontSize: 5,
  //               lineWidth: 0.1,
  //               lineColor: [0, 0, 0]
  //           }, 
  //           theme: 'grid',
  //           columnStyles: {
  //             text: {
  //                 cellWidth: 'auto',
  //                 fontSize: 6,
  //             }
  //         }
  //           }
  //         }
  //       ]
  //     };
  
  //   pdfMake.createPdf(docDefinition).download(filename+".pdf");
  
  
  
  //   }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      if(this.userType=='ADMIN' || this.userType == 'AUDITOR')
      {
        this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      }
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      if(this.userType=='ADMIN' ||this.userType == 'AUDITOR')
      {
        this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      }
    });
  }
  searchGdwn(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godowns = res['data'];
      this.godowns.push({ "gd_id": 0, 'gd_name': 'All' });
    });

  }
  getGodownList() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godowns = res['data'];
    });
  }


  selectType(id: string) {
    if (id == 'c') {
      $('#val1').show();

    } else {
      $('#val1').hide();


    }

  }

  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
    });
  }












  pageNext(formdata: { value: any; },newform: { value: any; }, pageno: any) {
    
    this.pageLoadingImg = true;
    formdata.value.datewise =  newform.value.datewise;
    formdata.value.is_year_rep =  newform.value.is_year_rep;
    // const searchval = new FormData();
    if (formdata.value.period_type === 'c') {
      if(formdata.value.time1){
        this.time1 = new Date(formdata.value.time1);
      }
      if(formdata.value.time2){
        this.time2 = new Date(formdata.value.time2);
      }
      
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;

      formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
      formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
    }

    var tmzn = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if(tmzn=="Asia/Calcutta"){
      tmzn="Asia/Kolkata";
    }
    formdata.value.tmzn = tmzn;
    formdata.value.export = '';
    this.apiService.salesandRetunSummary(formdata.value,pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;

      this.report = res.data.data;
      // this.purchInvSum = res.data.data;
      this.from = res.data.from;

      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.purchaseTotal = res.data.totalCount.purchaseTotal;
      this.saleDiscTotal = res.data.totalCount.saleDic;
      this.saleInclVat = res.data.totalCount.saleinclVat;
      this.saleVat = res.data.totalCount.saleVat;
      this.saleExclVat = res.data.totalCount.saleExclVat;
      this.balanceAmt = res.data.totalCount.balanceAmt;
      this.reportType = res.data.reportType;
      this.user_name = res.data;

      this.returnTotal = res.data.totalCount.returnTotal;
      this.returnDiscTotal = res.data.totalCount.returnDisc;
      this.retInclVat = res.data.totalCount.retInclVat;
      this.retVat = res.data.totalCount.returnVat;
      this.retExclVat = res.data.totalCount.returnExclVat;
      this.balanceExclVatAmt = res.data.totalCount.balanceExclAmt;
      
     
      this.repDate = res.data.Date;
      this.pdf = res.data.pdf;
      this.pdfalias = res.data.pdfalias;

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
   }

 //form search
 searchUser(search: string) {
  let searchval = new FormData();
  searchval.append("usr_name", search);
  this.apiService.getUserByName(searchval).subscribe((res) => {
    this.values = res['data'];
  });
}

searchCustomer(search: string) {
  const searchval = new FormData();
  searchval.append('cust_name', search);
   if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
    searchval.append("with-alias", "1");
  }
  this.apiService.searchCustomer(searchval).subscribe((res) => {
    this.customer = res['data'];
  });

}
getAllCustomerCategory()
  {
    this.apiService.listCustCategory().subscribe((res) => {
      this.customer_category = res['data'];
    });
  }
  onCategoryChange(){
    this.getAllCustomerSubCategory()
  }

  getAllCustomerSubCategory()
  {
    let searchval = new FormData();
    console.log('this.cust_cat_id',this.cust_category);
    
    if(this.cust_category){
      searchval.append("csc_cat_id", this.cust_category);
    }
    this.apiService.listCustSubCategory(searchval).subscribe((res) => {
      this.customer_sub_category = res['data'];
    });
  }

  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
  
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }
    
    searchVans(search: string) {
      const searchval = new FormData();
      searchval.append('van_name', search);
      this.apiService.searchVans(searchval).subscribe((res) => {
        this.vans = res['data'];
        this.vans.push({ "van_id": '', 'van_name': 'All' });

      });
  }
  exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
    
    formdata.value.datewise = true;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      if(formdata.value.time1){
        this.time1 = new Date(formdata.value.time1);
      }
      if(formdata.value.time2){
        this.time2 = new Date(formdata.value.time2);
      }
      
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;

      formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
      formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
    }

    var tmzn = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if(tmzn=="Asia/Calcutta"){
      tmzn="Asia/Kolkata";
    }
    formdata.value.tmzn = tmzn;
    this.apiService.salesandRetunSummary(formdata.value,1).subscribe((res: any) => {
      this.exportLoader = false;

      // this.Expshow = res.data;
      this.exp_report = res.data.data;
      this.exp_purchaseTotal = res.data.totalCount.purchaseTotal;
      this.exp_saleDiscTotal = res.data.totalCount.saleDic;
      this.exp_saleInclVat = res.data.totalCount.saleinclVat;
      this.exp_saleVat = res.data.totalCount.saleVat;
      this.exp_saleExclVat = res.data.totalCount.saleExclVat;
      this.exp_balanceAmt = res.data.totalCount.balanceAmt;
      this.exp_reportType = res.data.reportType;

      this.exp_returnTotal = res.data.totalCount.returnTotal;
      this.exp_returnDiscTotal = res.data.totalCount.returnDisc;
      this.exp_retInclVat = res.data.totalCount.retInclVat;
      this.exp_retVat = res.data.totalCount.returnVat;
      this.exp_retExclVat = res.data.totalCount.returnExclVat;
      this.exp_balanceExclVatAmt = res.data.totalCount.balanceExclAmt;
      
      this.repDate = res.data.Date;
      setTimeout(() => {
        this.exportexcel(); 
      }, 3000);

    });
   } 
   exportexcel(): void 
    {
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
    }

    // pdf download
    generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;
    
    formdata.value.datewise = true;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      if(formdata.value.time1){
        this.time1 = new Date(formdata.value.time1);
      }
      if(formdata.value.time2){
        this.time2 = new Date(formdata.value.time2);
      }
      
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;

      formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
      formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
    }

    var tmzn = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if(tmzn=="Asia/Calcutta"){
      tmzn="Asia/Kolkata";
    }
    formdata.value.tmzn = tmzn;
    this.apiService.salesandRetunSummary(formdata.value,1).subscribe((res: any) => {
      this.exportLoader = false;

      // this.Expshow = res.data;
      this.exp_report = res.data.data;
      this.exp_purchaseTotal = res.data.totalCount.purchaseTotal;
      this.exp_saleDiscTotal = res.data.totalCount.saleDic;
      this.exp_saleInclVat = res.data.totalCount.saleinclVat;
      this.exp_saleVat = res.data.totalCount.saleVat;
      this.exp_saleExclVat = res.data.totalCount.saleExclVat;
      this.exp_balanceAmt = res.data.totalCount.balanceAmt;
      this.exp_reportType = res.data.reportType;
      this.user_name = res.data;

      this.exp_returnTotal = res.data.totalCount.returnTotal;
      this.exp_returnDiscTotal = res.data.totalCount.returnDisc;
      this.exp_retInclVat = res.data.totalCount.retInclVat;
      this.exp_retVat = res.data.totalCount.returnVat;
      this.exp_retExclVat = res.data.totalCount.returnExclVat;
      this.exp_balanceExclVatAmt = res.data.totalCount.balanceExclAmt;
      
      this.repDate = res.data.Date;

      const heads = [
        this.translate.instant('Table.sl') + '\n' + this.translate.instant('Common.no'),
        this.translate.instant('Common.Inv_No'),
        this.translate.instant('Table.ret_no'),
        this.translate.instant('Table.date'),
        this.translate.instant('Sales.Name_Of_The_Customer'),
        this.translate.instant('Purchase.payment_type'),
        this.translate.instant('HOME.SALES') + '\n' + this.translate.instant('Table.amnt'),
        this.translate.instant('Van.Return') + '\n' + this.translate.instant('Table.amnt')
      ];
  
        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 75;
        const data = [];

        this.exp_report.forEach((item,i)=>{
          const arr=[
            i+1,
            item.date,
            item.sales_ret_pay_type
          ]

          if(item.type == '2'){
            arr.splice(1,0, ' ')
          } else if(item.type == '1'){
            arr.splice(1,0, item.inv_no)
          }

          if(item.type == '2'){
            arr.splice(2,0, item.ret_no)
          } else if(item.type == '1'){
            arr.splice(2,0, ' ')
          }

          if(this.translate.currentLang == 'English'){
            arr.splice(4,0, item.cust_name)
          } else if(this.translate.currentLang != 'English'){
            arr.splice(4,0, item.ledger_alias_AR)
          }

          if(item.type == '2'){
            arr.splice(6,0, ' ')
          } else if(item.type == '1'){
            arr.splice(6,0, (item.net_amount).toFixed(this.country_dec))
          }

          if(item.type == '2'){
            arr.splice(7,0, (item.net_amount).toFixed(this.country_dec))
          } else if(item.type == '1'){
            arr.splice(7,0, ' ')
          }

          data.push(arr)
        })
    
        let doc = this.excelService.getPdfObj();

        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');

        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);

        if(this.repDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
        }

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Common.sales_and_return_summary')}`,  valueXPos, headerHeight + 15);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.total')+ ' ' + this.translate.instant('HOME.SALES')
          + ' ' + this.translate.instant('Table.amnt') + ' ' + this.translate.instant('Common.Excl_Vat')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + (this.saleExclVat).toFixed(this.country_dec),  valueXPos, headerHeight + 20);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.Sales_VAT_Amt')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + (this.saleVat).toFixed(this.country_dec),  valueXPos, headerHeight + 25);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.total')+ ' ' + this.translate.instant('HOME.SALES')
          + ' ' + this.translate.instant('Table.amnt') + ' ' + this.translate.instant('Common.Incl_Vat')}`, lblXPos, headerHeight + 30);
        doc.setFontSize(10);
        doc.text(':  ' + (this.saleInclVat).toFixed(this.country_dec),  valueXPos, headerHeight + 30);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.total')+ ' ' + this.translate.instant('HOME.SALES')
          + ' ' + this.translate.instant('Common.Discount')}`, lblXPos, headerHeight + 35);
        doc.setFontSize(10);
        doc.text(':  ' + (this.saleDiscTotal).toFixed(this.country_dec),  valueXPos, headerHeight + 35);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.net_sale_amnt')}`, lblXPos, headerHeight + 40);
        doc.setFontSize(10);
        doc.text(':  ' + (this.purchaseTotal).toFixed(this.country_dec),  valueXPos, headerHeight + 40);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Purchase.Total_Retured_Amount') + ' ' + this.translate.instant('Common.Exclude_VAT')}`, lblXPos, headerHeight + 45);
        doc.setFontSize(10);
        doc.text(':  ' + (this.retExclVat).toFixed(this.country_dec),  valueXPos, headerHeight + 45);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Purchase.Return_VAT_Amount')}`, lblXPos, headerHeight + 50);
        doc.setFontSize(10);
        doc.text(':  ' + (this.retVat).toFixed(this.country_dec),  valueXPos, headerHeight + 50);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.totol_ret_incl_vat')}`, lblXPos, headerHeight + 55);
        doc.setFontSize(10);
        doc.text(':  ' + (this.retInclVat).toFixed(this.country_dec),  valueXPos, headerHeight + 55);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.totol_return_disc')}`, lblXPos, headerHeight + 60);
        doc.setFontSize(10);
        doc.text(':  ' + (this.returnDiscTotal).toFixed(this.country_dec),  valueXPos, headerHeight + 60);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.net_return_amnt')}`, lblXPos, headerHeight + 65);
        doc.setFontSize(10);
        doc.text(':  ' + (this.returnTotal).toFixed(this.country_dec),  valueXPos, headerHeight + 65);
        

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Balance_Amount')+ ' ' + this.translate.instant('Common.Incl_Vat')}`, lblXPos, headerHeight + 70);
        doc.setFontSize(10);
        doc.text(':  ' + (this.balanceAmt).toFixed(this.country_dec),  valueXPos, headerHeight + 70);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Balance_Amount') + ' ' + this.translate.instant('Common.Exclude_VAT')}`, lblXPos, headerHeight + 75);
        doc.setFontSize(10);
        doc.text(':  ' + (this.balanceExclVatAmt).toFixed(this.country_dec),  valueXPos, headerHeight + 75);

        if(this.reportType){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.rpt_type')}`, lblXPos, headerHeight + 80);
          doc.setFontSize(10);
          doc.text(':  ' + this.reportType,  valueXPos, headerHeight + 80);
        }

        if(this.user_name.user){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.USER')}`, lblXPos, headerHeight + 85);
          doc.setFontSize(10);
          doc.text(':  ' + this.user_name.user.usr_name,  valueXPos, headerHeight + 85);
        }
        
        doc = this.excelService.addTableToPdf1(doc, headerHeight + 90, lblXPos, heads, data, null);
    
       
        doc.save(`Sales and Return Summary.pdf`);

        this.exportLoader = false;

    });
    }

}
