import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { CurrencyPipe } from '@angular/common';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-customer-sales-and-return-summary',
  templateUrl: './customer-sales-and-return-summary.component.html',
  styleUrls: ['./customer-sales-and-return-summary.component.css']
})

export class CustomerSalesAndReturnSummaryComponent implements OnInit {

  fileName='customer_wise_sales_and_return.xlsx';
  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];
  customers: any;
  pageLoadingImg: boolean;
  exportLoader: boolean;
  total_customers: any;
  customer_category: any;
  priceGroupList: any;
  users: any;
  van_lines: any;
  total_sales: any;
  total_sales_ret: any;
  total_amnt: any;
  userType: any;
  branch_all: any;
  agents: any;
  cust_cat_id: any;
  customer_sub_category: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
   let searchval= new FormData();
   this.pageLoadingImg = true;
    this.apiService.custmerSalesAndReturnSummary(searchval, 1).subscribe((res: any) => {
      this.customers =res.data.data;
      this.total_customers =res.data.total_cust;
      this.total_sales =res.data.total_sale;
      this.total_sales_ret =res.data.total_sale_return;
      this.total_amnt =res.data.total_amnt;
      this.pageLoadingImg = false;

    });
    this.userType = this.coreService.getUserData('user_type');
    this.getAllCustomerCategory();
    this.apiService.getAllPriceGroup().subscribe((res) => {
      this.priceGroupList = res.data;
    });
    this.searchUser('');
    this.getAllVanlines();
    this.searchBranch('');
  }

  filterSearch(formdata: { value: any; }) {
    // this.search_txt="";
    this.pageLoadingImg = true;

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.custmerSalesAndReturnSummary(formdata.value, 1).subscribe((res: any) => {
      this.customers =res.data.data;
      this.total_customers =res.data.total_cust;
      this.total_sales =res.data.total_sale;
      this.total_sales_ret =res.data.total_sale_return;
      this.total_amnt =res.data.total_amnt;
      this.pageLoadingImg = false;

    });
  }

  exportexcel2(): void {
      /* table id is passed over here */
      let element = document.getElementById('export-group');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      /* save to file */
      XLSX.writeFile(wb, this.fileName);
      this.exportLoader = false;
  
    }
  
    exportEXCL() {
      this.exportLoader = true;
  
            setTimeout(() => {
              this.exportexcel2();
            }, 3000);
     
    }

    searchInp(e){

      let searchval = new FormData();
      searchval.append("search", e);
      this.apiService.custmerSalesAndReturnSummary(searchval, 1).subscribe((res: any) => {
        this.customers =res.data.data;
        this.total_customers =res.data.total_cust;
        this.pageLoadingImg = false;
  
      });

    }

    getAllCustomerCategory()
    {
      this.apiService.listCustCategory().subscribe((res) => {
        this.customer_category = res['data'];
      });
    }
   
    getAllCustomerSubCategory()
    {
      let searchval = new FormData();
      console.log('this.cust_cat_id',this.cust_cat_id);
      
      if(this.cust_cat_id){
        searchval.append("csc_cat_id", this.cust_cat_id);
      }
    
      this.apiService.listCustSubCategory(searchval).subscribe((res) => {
        this.customer_sub_category = res['data'];
      });
    }
    onCategoryChange(){
      this.getAllCustomerSubCategory()
    }
    searchUser(search: string) {
      let searchval = new FormData();
      searchval.append("usr_name", search);
      this.apiService.getUserByName(searchval).subscribe((res) => {
        this.users = res['data'];
      });
    }

    getAllVanlines() {
      this.apiService.getAllVanlines().subscribe((res) => {
        this.van_lines = res['data'];
  
      });
    }
    searchBranch(search: string) {
      let searchval = new FormData();
      searchval.append("branch_name", search);
      this.apiService.getBranchByName(searchval).subscribe((res:any) => {
        this.branch_all = res.data;
        // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      });
    }

    searchAgent(search: string)
    {
      const searchval = new FormData();
      searchval.append('ledger_name', search);
      this.apiService.searchAgents(searchval).subscribe((res) => {
        this.agents = res['data'];
  
      });
    }
}
