import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Repprddet } from '../../../../../model/report/repprddet.model';
import { Subcatagory } from '../../../../../model/subcatagory.model';
import { Feature } from '../../../../../model/feature.model';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import * as XLSX from 'xlsx'; 
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-rep-stock-summary',
  templateUrl: './rep-stock-summary.component.html',
  styleUrls: ['./rep-stock-summary.component.css']
})
export class RepStockSummaryComponent implements OnInit {
  fileName= 'stock_summary.xlsx';


  elseBlocknostart: any;
  elseBlocknoend: any;
  prd_id: any;
  prd_cat_id: any;
  prd_manufact_id: any;
  qty_filter: any;
  qty_val: any;
  qty_val1:any;
  rate_filter: any;
  rate_val: any;
  rate_val2: any;
  bar_filter: any;
  bar_val: any;
  bar_val2: any;
  stkeanbarcode: any;
  ean_key: any;
  active_stock: any;
  category_wise: boolean;
  c_wise : boolean;

  products_all: string[];
  catagories: string[];
  subcatagories: string[];
  mfs: string[];
  cat_id: number;
  prodsumms: string[];
  prodsummscat: string[];
  unit_all: string[];
  features: string[];
  baseunit: any;
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  stkprd_all: string[];
  slNo: number;
  mrpDef = 0;
  uspDef = 0;
  untDef = 0;
  mrpExp = 'all';
  calc_mode: string;
  totalproducts: number;
  totalstock: number;
  totalpurchase: any;
  totcats: number;
  remark: string;
  pageLoadingImg: boolean;
  userType: any;
  branch_all: any;
  filter_branch_id: any;
  up: any;
  exportLoader: boolean;
  Exprodsumms: any;
  Exptotalproducts: any;
  Exptotalstock: any;
  Exptotalpurchase: any;
  Exptotcats: any;
  Expcalc_mode: any;
  Expremark: any;
  branch_display_name:any;
  branch_name:any;
  branch_display_code:any;
  branch_address:any;
  usr_hide_purch_cost: any;
  country_dec: number=2;
  cmpny: any;
  cur_lang: string;
  PrdName: any;
  show_rate2: boolean;
  totalsales: any;
  Exptotalsales: any;
  prdWeightEnable: boolean;
  productcode: any;
  
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }
  ngOnInit() {

    this.apiService.getAllClientSettings().subscribe((res) => {
      if (res["data"]) { 
        this.prdWeightEnable = (res['data']['enable_product_weight']['cs_value']) ? true : false;
      }
    });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.cmpny = this.coreService.getUserData('cmpny');
    this.country_dec=this.coreService.setDecimalLength();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');
   this.cur_lang = sessionStorage.getItem("baseLang");
   this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      console.log('language-111');
      console.log(this.cur_lang);
      
    })
   this.usr_hide_purch_cost = this.coreService.getUserData('usr_hide_purch_cost');

    this.up = this.coreService.getUserPrivilage();
    this.getAllBranch();
    this.userType = this.coreService.getUserData('user_type');
    this.pageLoadingImg = true;
    let searchval = new FormData();

      this.untDef=1;
      this.show_rate2=false;
      this.filter_branch_id = 1;
      searchval.append("defalut_value",'1');

      this.apiService.stockSumPage(searchval, 1).subscribe((res: Repprddet) => {
      this.pageLoadingImg = false;
      this.prodsumms = res['data']['data'];
      this.totalproducts = res['data']['total'].total_products;
      this.totalstock = res['data']['total'].total_stock;
      this.totalpurchase = res['data']['total'].total_purchase_amount;
      this.totalsales=res['data']['total'].total_sale_amount;
      if (this.totalpurchase) { this.totalpurchase = Number(this.totalpurchase).toFixed(2); }
      this.totcats = res['data']['total'].total_categories;
      this.calc_mode = res['data']['calc_mode'];
      this.remark = res['data']['total'].remark;
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.slNo = 0;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }

  ngAfterContentInit() {

    this.searchForm('');
    this.reeadFeat();
    this.searchManf('');
    this.searchUnit('');


  }
  ngOnDestroy() {
    $('.close').click();

  }

  //form search  for category
  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  //product form search 
  searchStkPrd(search: string) {
    let searchval = new FormData();
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    }
    searchval.append("prod_name", search);
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];

    });
  }

  //Remove Name Suggestions

  removeName() {
    this.catagories = [];
    this.reeadFeat();
  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];

    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
   

    });
  }
  selectCat(cat_id: number) {
    this.cat_id = cat_id;
  }



  pageNext(formdata: { value: any; }, pageno: any) {
    this.c_wise = this.category_wise;
    
    $('.resultdata').empty();

    this.pageLoadingImg = true;


    let searchval = new FormData();
    searchval.append("pageno", pageno);
    if (formdata.value.mrp == 1)
      formdata.value.avg_rate = undefined;
    this.apiService.stockSumPage(formdata.value, pageno).subscribe((res: Repprddet) => {
      this.pageLoadingImg = false;

      this.prodsumms = res['data']['data'];
      this.totalproducts = res['data']['total'].total_products;
      this.totalstock = res['data']['total'].total_stock;
      this.totalpurchase = res['data']['total'].total_purchase_amount;
      this.totalsales=res['data']['total'].total_sale_amount;
      if (this.totalpurchase) {
        this.totalpurchase = Number(this.totalpurchase).toFixed(2);
      }
      this.totcats = res['data']['total'].total_categories;
      this.remark = res['data']['total'].remark;
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.calc_mode = res['data']['calc_mode'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res: Subcatagory[]) => {
      this.subcatagories = res['data'];
      if (this.changeBox)
        this.changeBox = false;
      else
        this.changeBox = true;

    });
  }


  //form search  manufacture
  searchManf(search: string) {
    let searchval = new FormData();
    searchval.append("manftr_comp_name", search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.mfs = res['data'];
    });
  }

  //form search unit
  searchUnit(search: string) {
    let searchval = new FormData();
    searchval.append("unit_name", search);
    this.apiService.getUnitByName(searchval).subscribe((res) => {
      this.unit_all = res['data'];
    });

  }


  // filter  types
  filter_types = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_rate = [
    { id: 0, name: 'Purchase Rate' },
    { id: 1, name: 'Sales Rate -(M.R. P)' }
  ];
  filter_prate = [
    { id: 0, name: 'Last Purchase Rate' },
    { id: 1, name: 'Avg. Purchase rate' }
  ];

  filter_defalut_unit_rate = [
    { id: 0, name: 'Base Unit Rate' },
    { id: 1, name: 'Default Unit rate'}
  ];

  expity_rate = [
    { id: 'all', name: 'All' },
    { id: 'no', name: 'No Expiry' },
    { id: 'has', name: 'Has Expiry' },
    { id: 'expired', name: 'Expired' },
    { id: 'not_expired', name: 'Not Expired' },
    { id: 'custom', name: 'Custom Date' }
  ];

  langChange(){
    this.translate.get(['Common.purchase_rate','Common.Base_Unit_Rate','Common.Default_Unit_rate', 'Table.sales_rate_mrp','Common.last_purchase_rate', 'Common.average_purchase_rate','Common.last_purchase_rate','HOME.CUSTOM','Common.all']).subscribe((res: string) => {    
     
      
      this.filter_rate = [
        { id: 0, name: res['Common.purchase_rate'] },
        { id: 1, name: res['Table.sales_rate_mrp'] }
      ];
      this.filter_prate = [
        { id: 0, name: res['Common.last_purchase_rate']},
        { id: 1, name: res['Common.average_purchase_rate'] }
      ];
    
      this.stk_ean_barcode = [
        { id: 'all', name:  res['Common.all']},
        { id: 'between', name:  res['HOME.CUSTOM'] }
      ];
     this.filter_defalut_unit_rate = [
        { id: 0, name: res['Common.Base_Unit_Rate'] },
        { id: 1, name: res['Common.Default_Unit_rate']}
      ];
    });

  }

  selectRate(rateid: number) {
    if (rateid == 1) { $('#useprate').hide(); }
    else {
      this.show_rate2=false;
      $('#useprate').show();
    }
  }

  selectExp(expid: string) {
    if (expid == 'custom') {
      $('#expiry_val').show();
      $('#expiry_val2').show();
    }
    else {
      $('#expiry_val').hide();
      $('#expiry_val2').hide();
    }
  }

  selectFqty(qtyidsel: string) {

    if (qtyidsel == 'between') {
      $('#qty_val1').show();
    }
    else {
      $('#qty_val1').hide();
    }
  }

  selectRateFilter(rateid: string) {
    if (rateid == 'between') {
      $('#rate_val2').show();
    }
    else {
      $('#rate_val2').hide();
    }
  }

  // Barcode and Ean
  stk_ean_barcode = [
    { id: 'all', name: 'All' },
    { id: 'between', name: 'Custom' }
  ];

  selectBarcodeFilter(id: string) {
    if (id == 'between') {
      $('#bar_val2').show();
    }
    else {
      $('#bar_val2').hide();
    }
  }

  selectStkEanBarcode(id: string) {
    if (id == 'between') {
      $('#ean_key').show();
    }
    else {
      $('#ean_key').hide();
    }
  }

  searchProductCode(search: string) {
    const searchval = new FormData();
    searchval.append('product_code', search);
    // if(this.eInvoiceSupport){
    //   searchval.append('einvoice', '1');
    // }
    this.apiService.ProductcodeSearch(searchval).subscribe((res) => {
      this.productcode = res['data'];
    });
    

  }


  reeadFeat() {
    this.apiService.readFeat().subscribe((res: Feature[]) => {
      this.features = res['data'];

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;
  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
			
    }


    exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
     
      // debugger;
      formdata.value.export = 'export';
      

      this.apiService.stockSumPage(formdata.value, 1).subscribe((res: Repprddet) => {
        formdata.value.export = '';

        this.Exprodsumms = res['data']['data'];
        this.Exptotalproducts = res['data']['total'].total_products;
        this.Exptotalstock = res['data']['total'].total_stock;
        this.Exptotalpurchase = res['data']['total'].total_purchase_amount;
        this.Exptotalsales=res['data']['total'].total_sale_amount;
        if (this.Exptotalpurchase) { this.Exptotalpurchase = Number(this.Exptotalpurchase).toFixed(2); }
        this.Exptotcats = res['data']['total'].total_categories;
        this.Expcalc_mode = res['data']['calc_mode'];
        this.Expremark = res['data']['total'].remark;
       
        this.slNo = 0;
   
      
     
      setTimeout(() => {
        this.exportexcel(); 
    }, 3000);
    

    });



    }

    // pdf download
    generatePdf(formdata: { value: any; }) {

      this.exportLoader = true;
     
      // debugger;
      formdata.value.export = 'export';
      

      this.apiService.stockSumPage(formdata.value, 1).subscribe((res: Repprddet) => {
        formdata.value.export = '';

        this.Exprodsumms = res['data']['data'];
        this.Exptotalproducts = res['data']['total'].total_products;
        this.Exptotalstock = res['data']['total'].total_stock;
        this.Exptotalpurchase = res['data']['total'].total_purchase_amount;
        if (this.Exptotalpurchase) { this.Exptotalpurchase = Number(this.Exptotalpurchase).toFixed(2); }
        this.Exptotcats = res['data']['total'].total_categories;
        this.Expcalc_mode = res['data']['calc_mode'];
        this.Expremark = res['data']['total'].remark;
       
        this.slNo = 0;
   
      
        const heads = [];

        if(this.calc_mode=='purchase_rate'){

          if(this.c_wise !== true){
            heads.splice(0,0, this.translate.instant('Common.prd_name'))
          } else {
            heads.splice(0,0, this.translate.instant('Common.category'))
          }

          if(this.c_wise !== true){
            heads.splice(1,0, this.translate.instant('Common.itm_code'))
            heads.splice(2,0, this.translate.instant('Table.ean_barCode'))
          }

          heads.splice(3,0, this.translate.instant('Common.available') + '\n' + this.translate.instant('HOME.STOCK'))

          if(!this.usr_hide_purch_cost){
            heads.splice(4,0, this.translate.instant('HOME.PURCHASE') + '\n' + this.translate.instant('Table.rate'))
            heads.splice(5,0, this.translate.instant('HOME.PURCHASE') + '\n' + this.translate.instant('Table.amnt'))
          }
          heads.splice(6,0, this.translate.instant('Common.sales_rate'))
          heads.splice(7,0,  this.translate.instant('Common.sales_rate_incl_tax'))
          heads.splice(8,0, this.translate.instant('Common.Sales_Rate2'))
          heads.splice(9,0,  this.translate.instant('Common.sales_rate2_incl_tax'))

        } else {
          
          if(this.c_wise !== true){
            heads.splice(0,0, this.translate.instant('Common.prd_name'))
          } else {
            heads.splice(0,0, this.translate.instant('Common.category'))
          }

          if(this.c_wise !== true){
            heads.splice(1,0, this.translate.instant('Common.itm_code'))
            heads.splice(2,0, this.translate.instant('Table.ean_barCode'))
          }

          heads.splice(3,0, this.translate.instant('Common.available') + '\n' + this.translate.instant('HOME.STOCK'))
          heads.splice(4,0, this.translate.instant('Table.sales_rate_mrp'))
          heads.splice(5,0,  this.translate.instant('Common.sales_rate_incl_tax'))
          heads.splice(6,0, this.translate.instant('Table.Sales_Rate2'))
          heads.splice(7,0,  this.translate.instant('Common.sales_rate2_incl_tax'))
          heads.splice(8,0, this.translate.instant('Common.sales_price') + '\n' + '(M.R.P)')
        }

          const lblXPos = 10;
          const headerHeight = 15;
          const valueXPos = 55;
          const data = [];

          if(this.calc_mode=='purchase_rate'){
            this.Exprodsumms.forEach(item=>{
              item.category.forEach(item1=>{

                const arr=[
                  item1.name,
                  (item1.stock).toFixed(this.country_dec)
                ];
  
                if(this.c_wise !== true){
                  arr.splice(1,0, ' ')
                  arr.splice(2,0, ' ')
                }
  
                if(!this.usr_hide_purch_cost){
                  arr.splice(4,0, (item1.rate).toFixed(this.country_dec))
                  arr.splice(5,0, (item1.amount).toFixed(this.country_dec))
                }
  
                data.push(arr)
  
              })
  
              if(this.c_wise !== true){
                item.products.forEach(item1=>{
                  if(this.translate.currentLang == "English"){this.PrdName = item1.name}else{this.PrdName = item1.prd_alias}

                  const arr=[
                    this.PrdName,                  ];
                  
                  if(this.c_wise !== true){
                    arr.splice(2,0, item1.ean_barcode)
                    arr.splice(1,0, item1.itemcode)
                  }
  
                  if(this.untDef){
                    arr.splice(3,0, (item1.default_unit_stock).toFixed(this.country_dec))
                    if(!this.usr_hide_purch_cost){
                      arr.splice(4,0, (item1.default_unt_rate).toFixed(this.country_dec))
                      arr.splice(5,0, (item1.default_unt_amount).toFixed(this.country_dec))
                    }
                    arr.splice(6,0, (item1.default_sales_rate).toFixed(this.country_dec))
                    arr.splice(7,0, Number(item1.default_sales_rate_with_tax).toFixed(this.country_dec))
                    arr.splice(8,0, (item1.default_sales_rate2).toFixed(this.country_dec))
                    arr.splice(9,0, Number(item1.default_sales_rate2_with_tax).toFixed(this.country_dec))
                  } else {
                    arr.splice(3,0, (item1.stock).toFixed(this.country_dec))
                    if(!this.usr_hide_purch_cost){
                      arr.splice(4,0, (item1.rate).toFixed(this.country_dec))
                      arr.splice(5,0, (item1.amount).toFixed(this.country_dec))
                    }
                    arr.splice(6,0, (item1.mrp_amt).toFixed(this.country_dec))
                    arr.splice(7,0, (item1.sales_rate_with_tax).toFixed(this.country_dec))
                    arr.splice(8,0, (item1.rate2).toFixed(this.country_dec))
                    arr.splice(9,0, (item1.sales_rate2_with_tax).toFixed(this.country_dec))
                  }
  
                  data.push(arr)
                })
              }
  
            })
          } else {
            this.Exprodsumms.forEach(item=>{
              item.category.forEach(item1=>{
                const arr=[
                  item1.name,
                  (item1.stock).toFixed(this.country_dec),
                  (item1.rate).toFixed(this.country_dec),
                  '',
                  '',
                  '',
                  (item1.amount).toFixed(this.country_dec)
                ]

                if(this.c_wise !== true){
                  arr.splice(1,0, ' ')
                  arr.splice(2,0, ' ')

                }

                data.push(arr)

              })

              if(this.c_wise !== true){
                item.products.forEach(item1=>{
                  const arr=[
                    item1.name
                  ];
                  
                  if(this.c_wise !== true){
                    arr.splice(2,0, item1.ean_barcode)
                    arr.splice(1,0, item1.itemcode)

                  }
  
                  if(this.untDef){
                    arr.splice(3,0, (item1.default_unit_stock).toFixed(this.country_dec))
                    arr.splice(4,0, (item1.default_unt_rate).toFixed(this.country_dec))
                    arr.splice(5,0, (item1.default_sales_rate_with_tax).toFixed(this.country_dec))
                    arr.splice(6,0, (item1.default_sales_rate2).toFixed(this.country_dec))
                    arr.splice(7,0, (item1.default_sales_rate2_with_tax).toFixed(this.country_dec))
                    arr.splice(8,0, (item1.default_unt_amount).toFixed(this.country_dec))
                  } else {
                    arr.splice(3,0, (item1.stock).toFixed(this.country_dec))
                    arr.splice(4,0, (item1.rate).toFixed(this.country_dec))
                    arr.splice(5,0, (item1.sales_rate_with_tax).toFixed(this.country_dec))
                    arr.splice(6,0, (item1.rate2).toFixed(this.country_dec))
                    arr.splice(7,0, (item1.sales_rate2_with_tax).toFixed(this.country_dec))
                    arr.splice(8,0, (item1.amount).toFixed(this.country_dec))
                  }
  
                  data.push(arr)
                })
              }

            })
          }
  
          let doc = this.excelService.getPdfObj();
  
          var addressLines = this.branch_address.split('\r\n');
          var addressText = addressLines.join(', ');
  
          doc.setFont('Amiri');
          doc.setFontSize(12);
          doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
          doc.setFont('Amiri');
          doc.setFontSize(9);
          doc.text(addressText, 71, headerHeight + 2);
  
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + `${this.translate.instant('Common.stock_summary')}`,  valueXPos, headerHeight + 10);
  
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.total_no_of_products')}`, lblXPos, headerHeight + 15);
          doc.setFontSize(10);
          doc.text(':  ' + this.totalproducts,  valueXPos, headerHeight + 15);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.STOCK')}`, lblXPos, headerHeight + 20);
          doc.setFontSize(10);
          doc.text(':  ' + (this.totalstock).toFixed(this.country_dec),  valueXPos, headerHeight + 20);
          
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.total_no_of_categories')}`, lblXPos, headerHeight + 25);
          doc.setFontSize(10);
          doc.text(':  ' + this.totcats,  valueXPos, headerHeight + 25);

          if((this.calc_mode!='purchase_rate' ||!this.usr_hide_purch_cost )){
            if(this.calc_mode=='purchase_rate'){
              doc.setFontSize(10);
              doc.text(`${this.translate.instant('Production.total_purchase_amount')}`, lblXPos, headerHeight + 30);
            } else if(this.calc_mode!='purchase_rate'){
              doc.setFontSize(10);
              doc.text(`${this.translate.instant('Sales.Total_Sale_Amount')}`, lblXPos, headerHeight + 30);
            }

            doc.setFontSize(10);
            doc.text(':  ' + Number(this.totalpurchase).toFixed(this.country_dec),  valueXPos, headerHeight + 30);

          }

          if(this.calc_mode=='purchase_rate'){
            doc.setFontSize(10);
            doc.text(this.remark, lblXPos, headerHeight + 35);
          }

          doc = this.excelService.addTableToPdf(doc, headerHeight + 40, lblXPos, heads, data, null);
      
         
          doc.save(`Stock Summary.pdf`);
  
          this.exportLoader = false;

    });
    }

}
