import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProductionService } from '../../../../service/production.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {
  

  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  // alias: any;
  prodctnId :any;
  prdn_date = new Date();
  step3 = false;
  step2 = false;
  step1 = false;
  searchExisting = true;
  calculationDisplay = true;
  netcost: any;
  prodform_id: any;
  ttlCommAmount: any;
  output_qty: number;
  prdAll = [];
  filterProdAll = [];
  selProdIds: any = {};
  formula_all: any;
  prdIngAll = [];
  filterIngrdProd = [];
  selIngProdIds: any = {};

  staffs = [];
  filterStaffs = [];
  selStaffs: any = {};

  commAll = 0;
  comsnCommAmnt = 0;
  resultobj: any = {};

  prodFormula = {
    prdn_date:new Date(),
    productnId:0,
    productnDate:0,
    formula_id: '',
    formula_name: '',
    productOut: [],
    productIngd: [],
    isProdCommsn: false,
    // 0 for all and 1 for individual productIngd: [{
    staffs: [],
    insp_staff: null,
    ttlComm: 0,
    ttlPurchase: 0,
    miscExp: 0,
  };
  formula_list=[];
  info = [];
  isSubmitInProg = false;
  pageLoadingImg = false;

  curpage = 1;
  lastpage = 1;
  perpage = 5;
  totalItem = 0;
  godown_all = [];
  curForm = 'Create';
  searchStkBatch:any;purch_note:any;vantran_formid:any;
  up: any;
  constructor(private productionService: ProductionService,  private datePipe: DatePipe,private apiService: ApiService,
    private coreService: CoreService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
    this.prodform_id ='';
    this.getAllSatff();
    this.getInfo(this.curpage);
    this.listProductFormula();

    this.listGodowns();
    this.setProdId();
    //this.listProductionFinished();
  }

  ngOnDestroy(){
    $('.close').click();
 
  }

  setProdId()
  {
    let searchval = new FormData();
    this.apiService.geProdctnId(searchval).subscribe((res) => {
      this.prodctnId = res['data']['prod_id'];
    });
  }
  listGodowns() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      this.godown_all.push({ "gd_id": 0, 'gd_name': this.coreService.getUserData('br_default_shop_name') });


    });

  }

  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      this.godown_all.push({ "gd_id": 0, 'gd_name': this.coreService.getUserData('br_default_shop_name') });

    });

  }
  clearForm() {
    this.curForm = 'Create';
    this.clearPopUp();
  }
  displayStep2() {
    this.step2 = true;
    this.calculationDisplay = false;
  }
  displayStep1() {
    this.step2 = false;
    this.calculationDisplay = true;
  }

  displayStep3() {

    
    this.step3 = true;
    this.step1 = this.step2 = false;
    this.calculationDisplay = false;

  }

  clearPopUp() {
    this.resultobj = {};
    this.prodFormula = {
      prdn_date:new Date(),
      productnId:0,
      productnDate:0,
      formula_id: '',
      formula_name: '',
      productOut: [],
      productIngd: [],
      isProdCommsn: false,
      // 0 for all and 1 for individual productIngd: [{
      staffs: [],
      insp_staff: null,
      ttlComm: 0,
      ttlPurchase: 0,
      miscExp: 0,
    };
    this.prodFormula.ttlPurchase = 0;
    this.prodFormula.miscExp = 0;
    this.filterProdAll = [];
    this.selProdIds = {};
    this.filterIngrdProd = [];
    this.selIngProdIds = {};
    this.filterStaffs = [];
    this.selStaffs = {};
    this.setProdId();
  }
  productionPreview(prdn_id) {
    this.prodFormula = {
      prdn_date:new Date(),
      productnId:0,
      productnDate:0,
      formula_id: '',
      formula_name: '',
      productOut: [],
      productIngd: [],
      isProdCommsn: false,
      // 0 for all and 1 for individual productIngd: [{
      staffs: [],
      insp_staff: null,
      ttlComm: 0,
      ttlPurchase: 0,
      miscExp: 0,
    };
    let searchval = new FormData();
    searchval.append("prdn_id", prdn_id);
    this.apiService.productionPreview(searchval).subscribe((res) => {
      this.prodFormula = res['data'];
      

    });

  }
  getSingleItem(data, output_qty = 1) {

    if (data.prodform_id) {
      this.clearPopUp();
      this.step1 = true;
      this.output_qty = output_qty;
      this.prodFormula.formula_id = data.prodform_id;
     // this.isSubmitInProg = true;
      this.curForm = 'Update';
      this.clearPopUp();
      this.productionService.getProductionFormula(data.prodform_id).subscribe((res) => {
        //this.isSubmitInProg = false;
        const data = res.data;

        this.prodFormula.prdn_date = new Date();
        this.prodFormula.formula_id = data.prodform_id;
        this.prodFormula.formula_name = data.prodform_name;
        this.prodFormula.isProdCommsn = (data.prodform_commission === 1) ? true : false;
        this.prodFormula.ttlComm = data.prodform_comm_amount;
        this.prodFormula.miscExp = data.prodform_misc;
        this.prodFormula.insp_staff = data.staff;
        this.prodFormula.productOut = [];
        data.prod.forEach((element, index) => {
          this.prodFormula.productOut.push({
            product: {
              prd_name: element.prd_name,
              prd_id: element.prd_id,
              unit_display: element.unit_display,
              prd_base_unit_id: element.prd_base_unit_id,
              bs_prate: element.bs_prate,
              branch_stock_id: element.branch_stock_id,
              bs_stock_id: element.bs_stock_id,
              gd_id: 0,
              batches: element.batches,

            },
            qty: element.prodformprod_qty,
            qty_expected: element.prodformprod_qty * (output_qty / data.prod[0]['prodformprod_qty']),
            percntg: element.prodformprod_perc,
            qty_received: element.prodformprod_qty * (output_qty / data.prod[0]['prodformprod_qty']),
            rate_expected: 0,
            net_rate: 0,
            exp_date: 0,
            manf_date: 0,
            batch_code: '',
            gd_id:0,
          });
          this.selProdIds[index] = element.prd_id;
        });
        this.filterProducts();
        this.prodFormula.productIngd = [];


        data.sub.forEach((element, index) => {
      
          this.prodFormula.productIngd.push({
            product: {
              prd_name: element.prd_name,
              prd_id: element.prd_id,
              unit_display: element.unit_display,
              prd_base_unit_id: element.prd_base_unit_id,
              bs_prate: element.bs_prate,
              shop: element.bs_stock_quantity_shop,
              shop_available: element.bs_stock_quantity_shop,
              branch_stock_id: element.branch_stock_id,
              bs_stock_id: element.bs_stock_id,
              units: element.units,
             // default_unit: { "unit_name": element.unit_display, "sur_unit_id": element.prd_base_unit_id },
              default_unit: { "unit_name": element.unit_display, "sur_unit_id": element.prd_base_unit_id,"unit_base_qty":1 },

            },
            // qty: element.prodformsub_qty * output_qty,
            // qty_show: element.prodformsub_qty * output_qty,
            qty: (element.prodformsub_qty/data.prod[0]['prodformprod_qty'])*output_qty,
            qty_show:  (element.prodformsub_qty/data.prod[0]['prodformprod_qty'])*output_qty,
            amount: ( (element.prodformsub_qty/data.prod[0]['prodformprod_qty'])*output_qty) * element.bs_prate
          });
          this.selIngProdIds[index] = element.prd_id;
        });
        this.updatePurchaseTtl();
        this.filterIngProducts();
        this.prodFormula.staffs = [];
        let isCommComsn = true;
        data.commision.forEach((element, index) => {
          this.prodFormula.staffs.push({
            staff: { ledger_id: element.ledger_id, ledger_name: element.ledger_name },
            comm: element.prodformcomm_amount
          });
          if (data.commision[index + 1]) {
            if (element.prodformcomm_amount !== data.commision[index + 1].prodformcomm_amount) {
              isCommComsn = false;
            }
          }
          this.selStaffs[index] = element.ledger_id;
        });
        this.filterStaff();
        if (isCommComsn && (this.prodFormula.staffs.length === this.staffs.length)) {
          this.commAll = 1;
          this.comsnCommAmnt = this.prodFormula.staffs[0].comm;
        } else {
          this.commAll = 0;
        }
      });

    }
  }

  selectBatch(Batchcode, index) {


    this.prodFormula.productOut[index]['batch_code'] = Batchcode.sb_batch_code;
    this.prodFormula.productOut[index]['exp_date'] = new Date(Batchcode.sb_expiry_date);
    this.prodFormula.productOut[index]['manf_date'] = new Date(Batchcode.sb_manufacture_date);
    this.prodFormula.productOut[index]['gd_id'] = 0;
  }



  changeUnit(qty, index) {
 
    this.prodFormula.productIngd[index]['qty'] *= qty;
  }
  getInfo(pageNo) {
    this.pageLoadingImg = true;
    this.productionService.getAllProductions(pageNo, this.perpage).subscribe((res) => {
      this.formula_list = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }

  listProductionFinished() {
    this.pageLoadingImg = true;
    this.productionService.getAllProductions(1, 5000).subscribe((res) => {
      this.formula_list = res.data.data;

      // console.log(res.data);
      // console.log("here");
    });
  }

  listProductFormula() {
    this.pageLoadingImg = true;
    this.productionService.getAllProductionFormula(1, 5000).subscribe((res) => {
      this.formula_all = res.data.data;
    });
  }


  searchProducts(search: string) {
    const searchval = new FormData();
    searchval.append('prod_name', search);
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.prdAll = res.data;
      this.filterProducts();
    });
  }

  setValues(prodId, index) {
    if (prodId == null) {
      delete this.selProdIds[index];
    } else {
      this.selProdIds[index] = prodId;
    }
    this.filterProducts();
  }

  filterProducts() {
    this.filterProdAll = this.prdAll.filter(e => {
      if (Object.values(this.selProdIds).indexOf(e.prd_id) < 0) {
        return true;
      }
    });
  }

  addMore() {
    this.prodFormula.productOut.push({
      product: null,
      qty: 0,
      qty_expected: 0,
      percntg: 0,
      qty_received: 0,
      rate_expected: 0,
      net_rate: 0,
      exp_date: 0,
      manf_date: 0,
      batch_code: ''
    });
  }

  removeItem(index) {
    this.prodFormula.productOut.splice(index, 1);
    delete this.selProdIds[index];
    this.filterProducts();
  }
  searchIngProducts(search: string) {
    const searchval = new FormData();
    searchval.append('prod_name', search);
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.prdIngAll = res.data;
      this.filterIngProducts();
    });
  }

  setIngValues(prodId, index) {
    if (prodId == null) {
      delete this.selIngProdIds[index];
    } else {
      this.selIngProdIds[index] = prodId;
    }
    this.filterIngProducts();
  }

  filterIngProducts() {
    this.filterIngrdProd = this.prdIngAll.filter(e => {
      if (Object.values(this.selIngProdIds).indexOf(e.prd_id) < 0) {
        return true;
      }
    });
  }

  addMoreIngrd() {
    this.prodFormula.productIngd.push({
      product: null,
      qty_show:0,
      qty: 0,
      amount: 0,
    });
  }

  removeIngd(index) {
    if (this.prodFormula.productIngd.length > 1) {
      this.prodFormula.productIngd.splice(index, 1);
    } else {
      // error atleast 1 item required
    }
    delete this.selIngProdIds[index];
    this.filterIngProducts();
  }

  commissionChk(value) {
    if (value === 0) {
      this.prodFormula.staffs = [];
      this.prodFormula.ttlComm = 0;
    }
  }

  setStaff(staffId, index) {
    if (staffId == null) {
      delete this.selStaffs[index];
    } else {
      this.selStaffs[index] = staffId;
    }
    this.filterStaff();
  }

  filterStaff() {
    this.filterStaffs = this.staffs.filter(e => {
      if (Object.values(this.selStaffs).indexOf(e.ledger_id) < 0) {
        return true;
      }
    });
  }

  getAllSatff() {
    this.apiService.listAllStaff().subscribe((res) => {
      this.staffs = res.data;
      this.filterStaff();
    });
  }

  addMoreStaff() {
    this.prodFormula.staffs.push({
      staff: null,
      comm: 0,
    });
  }

  removeStaff(index) {
    // if (this.prodFormula.staffs.length > 1) {
    //   this.prodFormula.staffs.splice(index, 1);
    // } else {
      // error atleast 1 item required
    // }
    this.prodFormula.staffs.splice(index, 1);
    delete this.selStaffs[index];
    this.filterStaff();
    this.updateCommsnTtl();
  }

  showAllStaff() {
    this.selStaffs = {};
    this.prodFormula.staffs = [];
    this.staffs.forEach((element, index) => {
      this.prodFormula.staffs.push({
        staff: element,
        comm: 0,
      });
      this.setStaff(element.ledger_id, index);
    });
    this.updateCommsnTtl();
  }

  showIndivStaff() {
    this.selStaffs = {};
    this.filterStaff();
    this.prodFormula.staffs = [{
      staff: {},
      comm: 0,
    }];
  }
  updateOutPutProducts(qty, index) {

    // this.prodFormula.ttlComm = 0;
    // this.prodFormula.staffs.forEach(element => {
    //   this.prodFormula.ttlComm += parseFloat(element.comm.toString());
    // });
  }
  updateCommsnTtl() {
    this.prodFormula.ttlComm = 0;
    this.prodFormula.staffs.forEach(element => {
      if(parseFloat(element.comm.toString())>0)
      {
        this.prodFormula.ttlComm += parseFloat(element.comm.toString());
      }
    
    });
  }
  updatePurchaseTtl() {
    this.prodFormula.ttlPurchase = 0;
    this.prodFormula.productIngd.forEach(element => {
      this.prodFormula.ttlPurchase += element.amount;
    });
  }

  setIndvComsn() {

    this.prodFormula.staffs.forEach((element, index) => {
      this.prodFormula.ttlComm += parseFloat(element.comm.toString());
      this.prodFormula.staffs[index].comm = this.comsnCommAmnt;
    });
    this.updateCommsnTtl();
  }
  validateAndSubmit() {
    this.isSubmitInProg = true;
    let callFunction = '';
    if (this.curForm === 'Create') {
      callFunction = 'createProduction';
    }
    if (this.curForm === 'Update') {
      callFunction = 'updateProduction';
    }

    this.prodFormula.productOut.forEach((element, index) => {
      this.prodFormula.productOut[index]['rate_expected'] =  ((this.prodFormula.ttlComm*1)+(this.prodFormula.ttlPurchase)+(this.prodFormula.miscExp*1))*(this.prodFormula.productOut[index].percntg/(100*this.prodFormula.productOut[index].qty_expected));
      this.prodFormula.productOut[index]['net_rate'] =  ((this.prodFormula.ttlComm*1)+(this.prodFormula.ttlPurchase)+(this.prodFormula.miscExp*1))*(this.prodFormula.productOut[index].percntg/(100*this.prodFormula.productOut[index].qty_received));
    });

    this.productionService[callFunction](this.prodFormula).subscribe((res) => {

      this.isSubmitInProg = false;
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        //this.closeModal.nativeElement.click();
        //this.clearPopUp();
        this.prodFormula.productnId = res.preview.prdn_id;
        this.prodFormula.productnDate = res.preview.prdn_date;
        this.getInfo(this.curpage);
        this.coreService.showMessage(res.message);
        this.displayStep3();
        this.resultobj = {};
      }

    });
  }

  updateToatalPurchaseAmount(qty, rate,base_qty, index) {

    this.prodFormula.productIngd[index]['product']['bs_prate'] = rate;
    this.prodFormula.productIngd[index]['qty'] = qty*base_qty;
    this.prodFormula.productIngd[index]['amount'] = qty * rate * base_qty;
    this.updatePurchaseTtl();

  }
  expectedQuantityChange(qty,index)
  {
    
    this.prodFormula.productOut[index]['qty_received'] = qty;
    this.prodFormula.productOut[index]['net_rate'] =  ((this.prodFormula.ttlComm*1)+(this.prodFormula.ttlPurchase)+(this.prodFormula.miscExp*1))*(this.prodFormula.productOut[index].percntg/(100*this.prodFormula.productOut[index].qty));
  // console.log(  this.prodFormula.productOut[index]['qty_received']);
  // console.log(  this.prodFormula.productOut[index]['net_rate']+")))");
  
  }
  expectedRateChange(rate,index)
  {

    this.prodFormula.productOut[index]['net_rate'] = rate;
  }

  newProduction() {
    this.closeModal.nativeElement.click();
    this.step3 = false;
    this.step2 = false;
    this.calculationDisplay = true;
    this.clearPopUp();
  }


}
