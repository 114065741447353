import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-van-transfer-item',
  templateUrl: './van-transfer-item.component.html',
  styleUrls: ['./van-transfer-item.component.css']
})
export class VanTransferItemComponent implements OnInit {
  fileName= 'van_transfer_item.xlsx';
  elseBlocknostart: any;
  elseBlocknoend: any; 
  rep_type:any;
  date1: any;
  date2: any;
  period_type: any;
  van_tran_filter: any;
  trans_val1: any;
  trans_val2: any;
  prd_name: any;
  prd_cat_id: any;
  manfact_id: any;
  van_id: any;
  usersel: any;
  filter: any;
  modal: any;
  reportType:any;
  tran_id: number;
  vantran_purch_price: number;
  prd_barcode: String;
  vantransub_qty: number;
  vantran_purch_amount: number;
  vantransub_unit_id: number;
  total_qty = 0;
  NgForm: any;
  total_amount = 0;
  sel_gd_id = 0;
  tranArray: any;
  slno = 0;
  prd_units = [];
  van_all = [];
  prd_all = [];
  selectedItems = [];
  resDup: any;
  resultobj: {};
  printError = [];
  vantran_date: any;
  prd_name_search: any;
  alias: any;
  vanlines: any[];
  VanLine: any[];
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  category:string[];
  proditems:any;
  totalProducts:number;
  totalCats:number;
  totalQty:number;
  totalRate = 0;
  categories:string[];
  pageLoadingImg:boolean;
  categorytotal:string[];
  company:string[];
  vans:string[];
  values:string;
  dataLength :number;
  items=[];
  searchProducts:any;
  alldetais:any;
  prd_id:any;
  reportBy:any;
  repDate:any;
  godown_all: any;
  subcatagories: any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;

  exp_alldetais:any;
  exp_proditems:any;
  exp_totalCats:number;
  country_dec: string;
  tablewise: boolean=false;
  table_data: any;
  van_list: any;
  pagefrom: any;
  items_count: any;
  total_trnsfer: any;
  exptable_data: any;
  expvan_list: any;
  expitems_count: any;
  exptotal_trnsfer: any;
  filter_branch_id: number;
  userType: any;
  branch_all: any;
  cur_lang: string;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }
  ngOnInit() {
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })

    this.filter_branch_id = 1;
    this.getAllBranch();
    this.userType = this.coreService.getUserData('user_type');
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.exportLoader = false;
    this.readVanTransfers();  
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Table.Transfer_returns','Common.transfer','Purchase.Returned','Common.this_month']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'tm', name: res['Common.this_month'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.rept_type = [
        { id: 1, name: res['Table.Transfer_returns'] },
        { id: 2, name: res['Common.transfer'] },
        { id: 3, name: res['Purchase.Returned'] },
      ];
    });
  }
  readVanTransfers() {
    this.pageLoadingImg = true;
    let searchval = new FormData();
    this.apiService.readVanTransfersItemReport(searchval, 1).subscribe((res) => {
      this.pageLoadingImg = false; 
      this.alldetais = res['alldetais']; 
      this.proditems = res['data']; 
      this.curpage = res['current_page'];  
      this.lastpage = res['last_page']; 

      this.totalCats = res['total_cats'];    
      
      this.reportType = res['reportType']; 
      this.reportBy = res['reportBy'];       
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);   
    });
  }


  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element:any;
     if(this.tablewise){
       element = document.getElementById('export-group1');
     }else{
       element = document.getElementById('export-group');
     }
      
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }


  exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
    formdata.value.filter =1;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    if(this.tablewise){
      formdata.value.table_view =1;
    }
    this.apiService.readVanTransfersItemReport(formdata.value,1).subscribe((res) => {
      this.exportLoader = false;
      this.exp_alldetais = res['alldetais'];     
      this.exp_proditems = res['data']; 
      this.exp_totalCats = res['total_cats'];    
      this.reportType = res['reportType']; 
      this.reportBy = res['reportBy'];   
      this.repDate = res['Date'];
      this.exptable_data=res['data'];
      this.expvan_list=res['van_list'];
      this.reportType=res['reportType'];
      this.expitems_count=res['count'];
      this.exptotal_trnsfer=res['total_transfer_qty'];
      setTimeout(() => {
        this.exportexcel();
      }, 3000);
      
    });
  }



// filter  types

  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];

  rept_type = [
    { id: 1, name: 'Transfer & Returns' },
    { id: 2, name: 'Transfer' },
    { id: 3, name: 'Returns' },
  ];

  ngAfterContentInit(){
    this.searchCat('');
    this.searchVans('');
    this.searchComp('');
    this.searchGdwn('');
  }
  ngOnDestroy(){
    $('.close').click();
 
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  pageNext(formdata: { value: any; }, pageno:any)
  {  
   formdata.value.filter =1;
  this.pageLoadingImg = true;

  if(this.tablewise){
    formdata.value.table_view =1;
  }
  if (formdata.value.period_type === 'c') {
    formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
    formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
  }
  this.apiService.readVanTransfersItemReport(formdata.value,pageno).subscribe((res) => {
    this.pageLoadingImg = false;             
    this.alldetais = res['alldetais'];     
    this.proditems = res['data']; 
    this.curpage = res['current_page'];  
    this.lastpage= res['last_page']; 
    this.totalCats = res['total_cats'];    
    this.reportType = res['reportType']; 
    this.reportBy = res['reportBy'];   
    this.repDate = res['Date'];
    this.table_data=res['data'];
    this.pagefrom=res['from'];
    this.van_list=res['van_list'];   
    this.items_count=res['total'];
    this.total_trnsfer=res['total_transfer_qty'];
    this.pgEnd(this.curpage, this.lastpage);
    this.pgStart(this.curpage);      
  });
  }
  filter_refnum = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];
    //form search  for category
    searchCat(search: string) {
      let searchval = new FormData();
      searchval.append("cat_name", search);
      this.apiService.getCatbyName(searchval).subscribe((res) => {
        this.categories = res['data'];     
      });  
    }

    searchComp(search: string) {
      const searchval = new FormData();
      searchval.append('manftr_comp_name', search);
      this.apiService.getManfbyName(searchval).subscribe((res) => {
        this.company = res['data'];
      });
  
    }

    searchVans(search: string) {
      const searchval = new FormData();
      searchval.append('van_name', search);
      this.apiService.searchVans(searchval).subscribe((res) => {
        this.vans = res['data'];
      });
  
    }

 // search product
 selectProd(name: string) {

  const searchval = new FormData();
  searchval.append('prod_name', name);
  if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
    searchval.append('alias_search', '1');
  }
  this.apiService.getProdByName(searchval).subscribe((res) => {
    this.searchProducts = res.data;
  });
}

selectSubCats(catid: number) {
  this.apiService.getAllSubCatbyId(catid).subscribe((res: any) => {
    this.subcatagories = res['data'];
    
  });
}

      //form search
 searchUser(search: string)
 {
  let searchval = new FormData();      
       searchval.append("usr_name", search);    
        this.apiService.getUserByName(searchval).subscribe((res) => {
         this.values = res['data'];                  
        });
 }

 searchGdwn(search: string) {
  let searchval = new FormData();
  searchval.append("gd_name", search);
  this.apiService.getGodownByName(searchval).subscribe((res) => {
    this.godown_all = res['data'];
    this.godown_all.push({ "gd_id": 0, 'gd_name': this.coreService.getUserData('br_default_shop_name') });
  });

}

tableView(formdata: { value: any; }, pageno:any){

  console.log('shoooper');
  
  this.tablewise=true;
  formdata.value.filter =1;
  if(this.tablewise){
    formdata.value.table_view =1;
  }
  
  this.pageLoadingImg = true;
  if (formdata.value.period_type === 'c') {
    formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
    formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
  }
  this.apiService.readVanTransfersItemReport(formdata.value,pageno).subscribe((res) => {
    this.pageLoadingImg = false;  
    this.table_data=res['data'];
    this.van_list=res['van_list'];
    this.reportType=res['reportType'];           
    this.reportBy = res['reportBy'];   
    this.repDate = res['Date'];
   
    this.pagefrom=res['from'];
    this.items_count=res['count'];
    this.total_trnsfer=res['total_transfer_qty'];
    this.pgEnd(this.curpage, this.lastpage);
    this.pgStart(this.curpage);      
  });
}

listView(formdata: { value: any; }, pageno:any){
 
  console.log('heeeheh');
  this.tablewise=false;
  formdata.value.filter =1;
 
    formdata.value.table_view ='';
  
  this.pageLoadingImg = true;
  if (formdata.value.period_type === 'c') {
    formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
    formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
  }
  this.apiService.readVanTransfersItemReport(formdata.value,pageno).subscribe((res) => {
    this.pageLoadingImg = false;
    this.alldetais = res['alldetais'];     
    this.proditems = res['data']; 
    this.curpage = res['current_page'];  
    this.lastpage= res['last_page']; 
    this.totalCats = res['total_cats'];    
    this.reportType = res['reportType']; 
    this.reportBy = res['reportBy'];    
    this.table_data=res['data'];
    this.van_list=res['van_list'];           
    this.reportBy = res['reportBy'];   
    this.repDate = res['Date'];
    console.log(this.van_list);
    this.pagefrom=res['from'];
    this.items_count=res['count'];
    this.total_trnsfer=res['total_transfer_qty'];
    this.pgEnd(this.curpage, this.lastpage);
    this.pgStart(this.curpage);      
  });

}
getAllBranch() {
  this.apiService.readAllBranch().subscribe((res:any) => {
    this.branch_all = res.data;
    this.filter_branch_id = res.selected;

    // console.log(  this.branch_all );
  //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
  });
}

searchBranch(search: string) {
  let searchval = new FormData();
  searchval.append("branch_name", search);
  this.apiService.getBranchByName(searchval).subscribe((res:any) => {
    this.branch_all = res.data;
    // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
  });
}

}
