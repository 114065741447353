import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-client-settings',
  templateUrl: './client-settings.component.html',
  styleUrls: ['./client-settings.component.css']
})
export class ClientSettingsComponent implements OnInit {
  offer: boolean;
  cust_code_prefix: any;
  last_code: any;
  

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService ) { }
  clientSettings = [];
  pageLoadingImg = false;
  pagesubLoadingImg = false;
  ngOnInit() {
    this.getClientSetting();
    this.cust_code_prefix = this.coreService.getUserData('cust_code_prefix');
    console.log('this.cust_code_prefix'+this.cust_code_prefix);
    
  }

  getClientSetting() {
    this.pageLoadingImg = true;
    this.apiService.getClientSetting().subscribe((res) => {
      if (res['data']) {
        this.clientSettings = res['data'];

        // for offer module
        this.clientSettings.forEach((item, index) => {
          // this.clientSettings[index].disble_autogen=false;
          if (item.cs_key == 'cust_code_auto_generate'){
            this.clientSettings[index].cust_code_prefix=this.cust_code_prefix;
            if(item.cs_value==1){
              this.clientSettings[index].disble_autogen=true;
            }
            
          }
          if (item.cs_settings_type == 1 &&item.cs_value==1) {
            this.offer = true;
          }
        });
      } 
      this.last_code=res.last_insert_code;
      this.pageLoadingImg = false;
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }

  update(){
    this.pagesubLoadingImg = true;
    this.apiService.updateClientSettings({settings: this.clientSettings}).subscribe((res) => {
      this.pagesubLoadingImg = false;
      if (res.data == 'success') {
        if(this.translate.currentLang == "Arabic"){ this.coreService.showMessage('تم تحديث الإعدادات بنجاح');}else{ this.coreService.showMessage('Settings Updated Successfully');}
       
      } else if (res.data == 'falied') {
        if(this.translate.currentLang == "Arabic"){ this.coreService.showMessage('فشل تحديث الإعدادات');}else{ this.coreService.showMessage('Failed to Update Settings');}
      }
    }, (error) => {
      this.pagesubLoadingImg = false;
    });
  }

  isNumber(value: any): boolean {
    return !isNaN(Number(value));  // Convert to number and check
  }
}
