import { Component, Input, OnInit } from '@angular/core';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-sales-order-with-fixed-header-footer',
  templateUrl: './sales-order-with-fixed-header-footer.component.html',
  styleUrls: ['./sales-order-with-fixed-header-footer.component.css']
})
export class SalesOrderWithFixedHeaderFooterComponent implements OnInit {

  @Input()saleQtnData;
  @Input()print_style;
  @Input()qr_inv;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()payTypes;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()footImgUrl;
  @Input()sales_print_name;
  @Input()hide_item_discount;
  @Input()hide_vat;
  @Input()sales_settings;
  @Input()g_settings;
  
  country_dec: any;
  noPage = [];
  noRowPerPage = 10;
  ttlPage = 1;
  lastpageIteemCount =0;
  data = [];
  hide_prnt_itm_name: any;

  constructor(private coreService: CoreService) { }

  ngOnInit() {
  
    
    this.country_dec=this.coreService.setDecimalLength();
  }

  ngOnChanges(changes) {
    if (changes['saleQtnData'] && this.saleQtnData) {
      this.data = this.saleQtnData.sales_order_sub;
      console.log(this.data);
      console.log(this.saleQtnData.sales_order_sub.length);
      
      
      this.ttlPage = Math.ceil(this.saleQtnData.sales_order_sub.length /this.noRowPerPage);
      this.lastpageIteemCount = this.saleQtnData.sales_order_sub.length % this.noRowPerPage;

      this.hide_prnt_itm_name = this.g_settings.g_s_hide_prnt_itm_name;
      
      if(this.lastpageIteemCount == 0 || this.lastpageIteemCount > 6){
        this.ttlPage = this.ttlPage +1;
      }
      this.noPage = this.createRange(this.ttlPage);
      console.log(this.noPage);
      
    }
  }
  createRange(number,start=0){   
    let noPage = [];
    for(var i = start; i < number; i++){
       noPage.push(i);
    }
    return noPage;
  }

}
