import { Component, OnInit,Input } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-price-group-popup',
  templateUrl: './price-group-popup.component.html',
  styleUrls: ['./price-group-popup.component.css']
})
export class PriceGroupPopupComponent implements OnInit {
  @Input()prd_id;
  @Input()chngecheck;

  pageLoadingImg: boolean;
  info: any;
  curpage: any;
  lastpage: any;
  totalItem: any;
  prd_data: any[];
  unit_rates: any;
  usr_hide_purch_cost: any;
  checkBoxErr: string;

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {

   
    
    this.usr_hide_purch_cost = this.coreService.getUserData('usr_hide_purch_cost');
  }
  ngOnChanges(changes) {
    if (changes['chngecheck'] && this.chngecheck) {
      this.getPriceGroupInfo()
          
    }
   }

  getPriceGroupInfo() {
    this.pageLoadingImg = true;
    let searchval = new FormData();
    searchval.append("prd_id", this.prd_id);
    this.apiService.getPriceGroupsInfo(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      this.info = res.data;

      this.info.forEach((element) => {   
          element.is_update =true;   
          element.unit_rates.forEach((element_sub) => {
            if (element_sub.unit_type == 1) {
              element_sub.unitratechk =1;
            } 
            element_sub.sur_unit_rate_incl=element_sub.sur_unit_rate+ (element_sub.sur_unit_rate * element.prd_tax) / 100;
            element_sub.sur_unit_rate2_incl=element_sub.sur_unit_rate2+ (element_sub.sur_unit_rate2 * element.prd_tax) / 100;
          });
        });
       
     
    });
  }

 

  checkBoxClick(j) {
    const isChecked = this.info[j].chk_all;
    this.info[j].unit_rates.forEach((element) => {
        if (element.unit_type == 1) {
            element.unitratechk =1;
        } else {
            element.unitratechk = isChecked ? 1 : 0;
        }
    });

  
    
}

inc_tax(keyword,i,j,rate){
  if(!isNaN(keyword)){
    let t=0;
    let p=0;
     t = keyword- ((keyword * 100) / (this.info[j].prd_tax + 100));
     p = keyword-t;

     if(rate==1){
      this.info[j].unit_rates[i].sur_unit_rate=p;
     }
     
     if(rate==2){
      this.info[j].unit_rates[i].sur_unit_rate2=p;
     }
     
  }
}
exl_tax(keyword,i,j,rate){
  if(!isNaN(keyword)){
    let t=0;
    keyword = Number (keyword);
     t = keyword+ (keyword * this.info[j].prd_tax) / 100;
     if(rate==1){
      this.info[j].unit_rates[i].sur_unit_rate_incl=t;
     }
    
     if(rate==2){
      this.info[j].unit_rates[i].sur_unit_rate2_incl=t;
     }
      
  }
}



updatePriceGroup(formdata: { value: any; }){

    
  if(this.validateForm()){
    this.pageLoadingImg=true;
    formdata.value.data=this.info;
    this.apiService.updatePriceGroupNew(formdata.value).subscribe((res: any) => {
      this.pageLoadingImg=false;
      this.coreService.showMessage(res.message);
       });
  }
   

}

validateForm(){

  var retVal=true;
  let checkcount=0;
  this.checkBoxErr='';
  var confrm=true;
  this.info.forEach((element) => {   
   
    if(element.is_update){
      checkcount++;
      element.unit_rates.forEach((element_sub) => {
        if (element_sub.unitratechk) {
            element_sub.sur_unit_rate_err='';
  
            if(element_sub.sur_unit_rate < element.bs_prate * element_sub.unit_base_qty){
               confrm = confirm("Rate is Less Than Purch.Rate");
              element_sub.sur_unit_rate_err='Rate is Less Than Purch.Rate';
              retVal=confrm;
            }
          if(element_sub.sur_unit_rate <=0 ||element_sub.sur_unit_rate==''){
            element_sub.sur_unit_rate_err='Required';
            retVal=false;
          }
  
          if(element.is_rate_2){
            element_sub.sur_unit_rate_err2='';
  
            if(element_sub.sur_unit_rate2 < element.bs_prate * element_sub.unit_base_qty){
               confrm = confirm("Rate is Less Than Purch.Rate");
              element_sub.sur_unit_rate2_err='Rate is Less Than Purch.Rate';
              retVal=confrm;
            }
            if(element_sub.sur_unit_rate2 <=0 ||element_sub.sur_unit_rate2==''){
              element_sub.sur_unit_rate2_err='Required';
              retVal=false;
            }
          }
        } 
      });
    
    }
  });

  if(checkcount==0){
      this.checkBoxErr='Please Select Atleast 1 PRICE GROUP';
      retVal=false;
  }

  return retVal;

}

}
