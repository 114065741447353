import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../service/core.service';
import { ApiService } from '../../../../service/api.service';
import { MatStepper } from '@angular/material/stepper';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-remove-unused-products',
  templateUrl: './remove-unused-products.component.html',
  styleUrls: ['./remove-unused-products.component.css']
})
export class RemoveUnusedProductsComponent implements OnInit {
  fileName= 'unused_products.xlsx';

  prodDetails: any;
  analysLoadingImg: boolean;
  totalProds: any;
  unWantedProdIds: any;
  removeLoadingImg: boolean;
  start: any;
  limit: any;
  deletePrd:boolean=false;
  fileName2 = 'Sample Remove Doccument.xlsx';
  pageLoadingImg: boolean;
  removeList: any;
  failed_products: any;
  fileName1 = 'Failed Products.xlsx';



  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.analysLoadingImg = false;
    this.prodDetails = [];
    this.removeLoadingImg = false;
    this.start = '';
    this.limit = '';


  }

  goBack(stepper: MatStepper){
    stepper.previous();
}

goNext(stepper: MatStepper){
  stepper.next();
}

analyseProducts(type){
  
  this.prodDetails = [];
  this.totalProds = 0;
    this.analysLoadingImg = true;

    let searchval = new FormData();
    searchval.append('start',this.start);
    searchval.append('limit',this.limit);
    this.apiService.analyseUnusedProds(searchval).subscribe((res: any) => {
    this.analysLoadingImg = false;
      
      this.prodDetails = res.data;
      this.totalProds = res.total_products;
      this.unWantedProdIds = res.unwanted_prods;
    
      if(type == 'export'){
        setTimeout(() => {
          this.exportexcel();
        }, 3000);
      }
    
    });

    

}


removeUnusedProducts(stepper){
  var voidstk = confirm("Are you sure you want to truncate all products ? ");
    if (voidstk) {
  this.prodDetails = [];
  this.totalProds = 0;
    this.removeLoadingImg = true;

    let searchval = new FormData();
    searchval.append('unwanted_prods',this.unWantedProdIds);
    this.apiService.removeUnusedProds(searchval).subscribe((res: any) => {
    // console.log(res);
    this.removeLoadingImg = false;
    this.coreService.showMessage('All Products Removed Successfully');
    stepper.reset();
    });

  }

}

resetData(){
  
  this.prodDetails = [];
  this.totalProds = 0;
  
}


exportexcel(): void {
  /* table id is passed over here */
  let element = document.getElementById('export-group');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, this.fileName);
  // this.exportLoader = false;

}
showDelete(){
  this.deletePrd = !this.deletePrd;
}

delete(i){
  this.removeList.splice(i, 1);
}

removeLog(){
    this.apiService.removeProductsByLog({data:this.removeList}).subscribe((res) => {
    if (res.message) {
      this.coreService.showMessage(res.message);
    }
    if (res.error) {
       this.failed_products=res.data;
      setTimeout(() => {
       this.downloadErrorSheet();
        }, 2000);
     
      this.coreService.showMessage(res.error,6000);
    } else {
      this.removeList = [];
    }
  });

}

  downloadSheet1() {
    /* table id is passed over here */
    let element = document.getElementById('export-group2');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName2);
  }
  downloadErrorSheet2() {
   /* table id is passed over here */
    let element = document.getElementById('export-group3');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName1);
   }
  downloadErrorSheet() {
    /* table id is passed over here */
    let element = document.getElementById('export-group1');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName1);
  }


  uploadLogFileToRemove(form) {
    var cnfrm = confirm("Are You Sure To Continue ?");
    if (cnfrm) {
      this.pageLoadingImg = true;
  
      let searchval = new FormData(form);
      
   
      this.apiService.importProductsByLogtoRemove(searchval).subscribe((res) => {
          this.pageLoadingImg = false;
          
          this.removeList = res.data;
           if (res.error) {
           this.failed_products=res.error;
           if (res.error && res.error.length > 0) {
            setTimeout(() => {
              this.downloadErrorSheet2();
              }, 1000);
            this.coreService.showMessage(res.message,1000);
          }
          }
          });
   }
  }
}
