
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
// import * as jspdf from 'jspdf';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Sales } from '../../../../../model/report/sales.model';
import * as XLSX from 'xlsx';
import { Router, ActivatedRoute, Params } from '@angular/router';
import pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from 'src/app/service/excel.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs; 
pdfMake.fonts = { 'Roboto': { normal: 'Roboto-Regular.ttf', bold: 'Roboto-Medium.ttf', italics: 'Roboto-Italic.ttf', bolditalics: 'Roboto-Italic.ttf' },
 'THSarabunNew': { normal: 'THSarabunNew.ttf' }, 'code128': { normal: 'code128.ttf' } }
 pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-invoice-due',
  templateUrl: './invoice-due.component.html',
  styleUrls: ['./invoice-due.component.css']
})
export class InvoiceDueComponent implements OnInit {

  @ViewChild('exportall', { static: true }) reportContent: ElementRef;
  fileName = 'inv_due_summary.xlsx';

  pageLoadingImg: boolean;
  value1: any;
  value2: any;
  inv_val1: any;
  inv_val2: any;
  sales_val1: any;
  sales_val2: any;
  disc_val1: any;
  agents: any;

  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  filter_invno = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_salamt = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_saldic = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_servamt = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_paytype = [
    { id: '1', name: 'Credit' },
    { id: '2', name: 'Cash' },

  ];

  filter_purchtypes = [
    { id: '1', name: 'NonVoided' },
    { id: '0', name: 'Voided' },

  ];

  filter_custtyp = [
    { id: '1', name: 'Registered' },
    { id: '0', name: 'Non Registered' },

  ];


  payType = ['Cash', 'Credit'];

 
 tempImgUrl:any = '';
  godowns: any;
  salesSum: any;
  totalCat: any;
  totalProducts: any;
  sup_id: any;
  cust_id: any;
  cust_type: any;
  pay_type: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  pgend: any;
  pgstart: any;
  sales_disc_filter: any;
  sales_filter: any;
  inv_filter: any;
  period_type: any;
  gd_id: any;
  agent_id: any;
  company: any;
  supplier: any;
  users: any;
  customer: any;
  added_by: any;
  sales_flags: any;
  summary: any;
  repDate: any;
  totalData: number;
  date1: any;
  date2: any;
  time1: any;
  time2: any;
  vat_reg_no: any;
  ModelData: boolean;
  show: any;
  userType: any;
  filter_branch_id: number;
  branch_all: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  Expshow: any;
  ExpsalesSum: any;
  Expsummary: any;
  total_bal_amnt: any;
  total_inv_amnt: any;
  vanline_all: any;
  vans: any;
  custid: any;
  aging: any;
  exportLoader: boolean;
  ExprepDate: any;
  Exptotal_bal_amnt: any;
  Exptotal_inv_amnt: any;
  pdf: any;
  //ascending
  sortDueDate = '1';
  total_recieved_amnt: any;
  Exptotal_recievd_amnt: any;
  country_dec: number=2;
  cur_lang: string;
  disply_monthly: any;

  constructor(private apiService: ApiService, private coreService: CoreService, private activatedRoute: ActivatedRoute,private currencyPipe: CurrencyPipe,private translate: TranslateService,private datePipe: DatePipe,private excelService: ExcelService) { 
   
  }

  // subscribe to router event


  searchVanlines(search: string) {
    let searchval = new FormData();
    searchval.append("vanline_name", search);
    this.apiService.getVanlinesName(searchval).subscribe((res) => {
      this.vanline_all = res['data'];

    });
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.country_dec=this.coreService.setDecimalLength();
    // this.period_type = 'ld';
    let searchval = new FormData();


    this.activatedRoute.params.subscribe(params => {
      this.custid = params['id'];
      this.aging = params['aging'];
      if (this.custid && this.aging) {
        searchval.append("cust_id", this.custid);
        searchval.append("aging", this.aging);
      }
      searchval.append("cust_id", this.custid);
      searchval.append("aging", this.aging);
      // console.log(params['id']);
      // console.log(params['aging']);
    });
    // console.log("===");
    // this.getSalesSetting();
    this.getHeaderImage();
    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    this.filter_branch_id = 1;
    this.totalData = 0;
    this.pageLoadingImg = true;

    searchval.append("sort_due_date", this.sortDueDate);
    this.apiService.InvoicedueSummary(searchval, 1).subscribe((res: Sales) => {
      this.pageLoadingImg = false;


      this.show = res['data'];



      this.summary = res['data']['data'];
      this.repDate = res['data'].Date;
      this.total_bal_amnt = res['data']['total_bal_amnt'];
      this.total_inv_amnt = res['data']['total_inv_amnt'];
      this.total_recieved_amnt = res['data']['total_recieved_amnt'];

      // console.log(this.total_recieved_amnt);

      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.totalData = res['data']['total'];

      this.pdf = res['data']['pdf'];
      // console.log(this.totalData);

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
    this.ModelData = false;

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');

    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    
    

    
  }
  langChange(){
    this.translate.get(['Common.today','Common.Disable','Common.Enable', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','HOME.credit','Common.cash','HOME.Bank','settings.Multi','Common.this_month']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'tm', name: res['Common.this_month'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

     
    });

  }
  ngAfterContentInit() {

    this.searchVans('');
    this.searchUser('');

  }

  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];

      // console.log(  this.branch_all );
      this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
    });
  }


  ngOnDestroy() {
    $('.close').click();

  }

  pageNext(formdata: { value: any; }, newform: { value: any; }, pageno: any) {
    this.pageLoadingImg = true;
    formdata.value.datewise = newform.value.datewise;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
      formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
    }
    // formdata.value.hide_zero =  formdata.value.hide_zero;
    formdata.value.sort_due_date =this.sortDueDate;
    this.apiService.InvoicedueSummary(formdata.value, pageno).subscribe((res: Sales) => {
      this.pageLoadingImg = false;
      // if(res['data']['data']){
      this.show = res['data'];



      this.summary = res['data']['data'];
      this.repDate = res['data'].Date;
      this.total_bal_amnt = res['data']['total_bal_amnt'];
      this.total_inv_amnt = res['data']['total_inv_amnt'];
      this.total_recieved_amnt = res['data']['total_recieved_amnt'];
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.totalData = res['data']['total'];
      // console.log(this.totalData);
      this.pdf = res['data']['pdf'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      // }
    });

  }



  searchComp(search: string) {
    const searchval = new FormData();
    searchval.append('manftr_comp_name', search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.company = res['data'];
    });

  }


  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }


  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplier = res['data'];
    });

  }

  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }

  searchAgent(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.searchAgents(searchval).subscribe((res) => {
      this.agents = res['data'];
    });
  }

  searchGdwn(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godowns = res['data'];
      this.godowns.push({ "gd_id": 0, 'gd_name': 'All' });
    });

  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
      this.vans.push({ "van_id": 'All', 'van_name': 'All' });

    });

  }



  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  export() {
    // const doc = new jspdf('p', 'pt', 'A4');
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };

    const content = this.reportContent.nativeElement;

    // doc.fromHTML(content.innerHTML, 5, 5, {
    //   'width': 1000,
    //   'elementHandlers': specialElementHandlers
    // });
    // doc.setFontSize(5);


    // doc.save('asdfghj' + '.pdf');
  }




  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  exportEXCL(Expformdata: { value: any; }) {

    // debugger;
    Expformdata.value.datewise = true;
    Expformdata.value.export = 'export';
    if (Expformdata.value.period_type === 'c') {
      Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
      Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
      Expformdata.value.time1 = (Expformdata.value.time1) ? this.apiService.formatTime(Expformdata.value.time1) : Expformdata.value.time1;
      Expformdata.value.time2 = (Expformdata.value.time2) ? this.apiService.formatTime(Expformdata.value.time2) : Expformdata.value.time2;
    }
    this.exportLoader = true;
    Expformdata.value.sort_due_date =this.sortDueDate;
    this.apiService.InvoicedueSummary(Expformdata.value, 1).subscribe((res: Sales) => {

      // if(res['data']['data']){
      this.Expshow = res['data'];

      this.Expsummary = res['data']['data'];
      this.ExprepDate = res['data'].Date;
      this.Exptotal_bal_amnt = res['data']['total_bal_amnt'];
      this.Exptotal_inv_amnt = res['data']['total_inv_amnt'];
      this.Exptotal_recievd_amnt = res['data']['total_recieved_amnt'];

      setTimeout(() => {
        this.exportexcel();
      }, 3000);


    });
    Expformdata.value.export = '';

  }


  generatePdf() {
                 
    this.exportLoader = true;    


    const rows = [];
    const headerHeight = 52;
    const lblXPos = 18;
    const valueXPos = 55;

    if(this.disply_monthly){

      var heads = [
        this.translate.instant('Table.sl_no'),
        this.translate.instant('HOME.months'),  
        this.translate.instant('Common.Inv_Amt'),
        this.translate.instant('Table.recieved_amnt'),
        this.translate.instant('HOME.Balance_Amt'),
      
      ];
    }else{
      var heads = [
        this.translate.instant('Table.sl_no'),
        this.translate.instant('Common.Inv_No1'),
        this.translate.instant('transaction.Branch_Ref_No'),
        this.translate.instant('Common.Ledger_name'),
        this.translate.instant('Common.Inv_Date'),
        this.translate.instant('Common.Due_Date'),
        this.translate.instant('Common.Due_Days'),
        this.translate.instant('Common.Inv_Amt'),
        this.translate.instant('Table.recieved_amnt'),
        this.translate.instant('HOME.Balance_Amt'),
        this.translate.instant('Common.reference')
      ];
    }
   

    if (this.pdf && this.summary) {
      // this.Expdispnote = res.data.show_notes;
      this.pdf.forEach((data: any) => {
        rows.push([data[0], data[1], data[2], data[3],
          (this.disply_monthly)?data[4]:this.datePipe.transform(data[4], 'dd/MM/yyyy'),
          this.datePipe.transform(data[5], 'dd/MM/yyyy'),data[10],
        (data[6]).toFixed(this.country_dec), (data[7]).toFixed(this.country_dec),(data[8]).toFixed(this.country_dec),data[9] ]);
      });
    }
    let doc = this.excelService.getPdfObj();

    doc.setFont('Amiri');
    doc.setFontSize(12);
    doc.text('Invoice Due Summary', 85, headerHeight - 2);

    doc.setFontSize(10);
    doc.text('Branch', lblXPos, headerHeight + 5);
    doc.setFontSize(11);
    doc.text(':  ' + this.branch_display_name,  valueXPos, headerHeight + 5);

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('Common.total_inv_amnt')}`, lblXPos, headerHeight + 10);
    doc.text(':  ' + (this.total_inv_amnt).toFixed(this.country_dec),  valueXPos, headerHeight + 10);

    doc.text(`${this.translate.instant('Table.total_recieved_amnt')}`, lblXPos, headerHeight + 15);
    doc.text(':  ' + (this.total_recieved_amnt).toFixed(this.country_dec),  valueXPos, headerHeight + 15);

    doc.text(`${this.translate.instant('Common.total_bal_amnt')}`, lblXPos, headerHeight + 20);
    doc.text(':  ' + (this.total_bal_amnt).toFixed(this.country_dec),  valueXPos, headerHeight + 20);

    doc = this.excelService.addTableToPdf(doc, headerHeight + 22, lblXPos, heads, rows, this.tempImgUrl, this.branch_display_name);
    doc.save(`Invoice Due Summary.pdf`);
    this.exportLoader = false;  
  
    }

    refreshData(newVal){
      this.sortDueDate = newVal;
    }

    // To get Header image which will be the part of pdf report
    getHeaderImage() {
      this.apiService.getSettings().subscribe((res) => {

        if(res.data.prnt_common_header_img){
          this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_common_header_img;
        }
        else if (res.data.prnt_header_logo) {
          this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
        } else {
          this.tempImgUrl = ''; // default
        }
  
        if (res.data.prnt_header_type === 2) {
          if(res.data.prnt_common_header_img){
            this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_common_header_img;
          }else if(res.data.prnt_header_img_path){
            this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
          }else{
            this.tempImgUrl = '';
          }
          
        } else {
          this.tempImgUrl = '';
        }
      });
    }

    toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    public dayBetweenDates(sdate, edate = new Date()) {
      // Get 1 day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;
      // Convert both dates to milliseconds
      const sdateMs = new Date(sdate).getTime();
      const edateMs = new Date(edate).getTime();
      // Calculate the difference in milliseconds
      const differenceMs = edateMs - sdateMs;
      // Convert back to days and return
      return Math.round(differenceMs / oneDay);
    }
}
