import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { ActivatedRoute  } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Router } from "@angular/router";
// modal
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-van-transfer',
  templateUrl: './van-transfer.component.html',
  styleUrls: ['./van-transfer.component.css']
})


export class VanTransferComponent implements OnInit {

  @ViewChild('fieldName4', { static: false }) fieldName4: ElementRef;
  @ViewChild('fieldName5', { static: false }) fieldName5: NgSelectComponent;
  @ViewChild('fieldName3', { static: false }) fieldName3: ElementRef;

  taxCategories = [];
  unit_qty: any;
  display_qty: any;
  product_summary: any;
  purch_no: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  vantran_godown_id: any;
  vantran_van_id: any;
  vantran_request_id =0;
  brcode: any;
  prd_stock_statusChecked: any;
  prd_stock_status: any;

  prd_id: any;
  tran_id: number;
  vantran_purch_price: any;
  prd_barcode: String;
  vantransub_qty: any;
  vantran_purch_amount: number;
  vantransub_unit_id: any;
  default_unit: any;
  total_qty = 0;
  NgForm: any;
  total_amount: any;
  sel_gd_id = 0;
  tranArray: any;
  sl_no = 0;
  prd_units = [];
  van_all = [];
  prd_all = [];
  selectedItems = [];
  resDup: any;
  resultobj: any = {};
  printError = [];
  vantran_date: any;
  prd_name_search: any;
  alias: any;
  vanlines: any[];
  VanLine: any[];
  preview_tran: any[];
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  box = 0;
  preview_id = 0;
  preview_date: any[];
  preview_gd: any[];
  preview_van: any[];
  preview_prize: any;
  preview_items: any[];
  preview_items1:any[];
  base_rate: number;
  avg_rate:number;
  pageLoadingImg: boolean;
  sale_rate: any;
  salestatus: any; data: any;
  showrate: any;
  godown_all = [
    { "gd_id": 0, 'gd_name': this.coreService.getUserData('br_default_shop_name') }
  ];
  gdid: number;
  errObjArr = {
    tandateErr: null, tanvanErr: null,
    tangdErr: null
  };
  errObjTran = {
    fromErr: null, toErr: null,
    sameErr: null
  };
  rate_status: any;
  rate_status_type: any;
  preview_branch: any;
  base_url: any;
  preview_time: any;
  gddef: any;
  preview_tot_qty: any;
  preview_van_users:any;
  userEncpType: any;
  godownStocksTotal = 0;
  godownStocks: any;
  selctedProd: any;
  total_base_qty: any;
  total_derived_qty: any;
  pageLoadingImg1 = false;
  showPurchImportSection = false;
  showTransfCont = false;
  purch_id_no:any;
  vanTransBtn = false;
  settings: any;
  van_searchType: any;
  usr_hide_purch_cost: any;
  srch_count: number;
  van_notes: any;
  country_dec: string;
  pageLoadingImg2: boolean;
  edit_trnsfer: boolean=false;
  existingArray: any;
  edit_transfer_display: any;
  vanTransBtn1: boolean=false;
  van_difault_search: any; 
  barcodesrch: boolean=false;
  van_difault_rate: any;
  total_drvd_qty= <any>0;
  rate_show: boolean;
  unit_wise_avlbleqty: any;
  van_sale_byunit: any;
  van_rate_validation: any;
  cmpny: any;
  no_transfr: string='';
  copyLoading: boolean;
  copyRef: any;
  cur_lang: string;
  prd_stock_status_type: string;
  saleEditLogLoaded: any;
  logLoadingImg: boolean;
  log_pgend: boolean;
  log_pgstart: boolean;
  vanTranEditLog: any;
  log_curpage: any;
  log_lastpage: any;
  log_from: any;
  up: any;
  g_settings: any;
  generalSearch_type: any;
  van_transfer_ptype: any;
  vantran_notes: any;
  purchaseValidation =false;
  vanReqList: any;
  isEdit =false;
  pendingVanTran: any;
  vtrq_date: any;
  vtrq_request_id: any;
  vtrq_van: any;
  vtrq_branch: any;
  vtrq_notes: any;
  vtrq_van_name: any;
  vanTranLogId: any;
  logList: any;
  show_balance: any=0;
  balanceloading: boolean;
  van_balance: any;

  constructor(private apiService: ApiService, private coreService: CoreService,
    private datePipe: DatePipe, private modalService: NgbModal, private ActiveRoute: ActivatedRoute,private translate: TranslateService,public router: Router,) { }
  ngOnInit() {
    this.logList =[];
    this.pendingVanReqCount();
    this.getGeneralSetting();
   // this.getVanTransferLogs();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.langChange();
    });
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.aliasChecked()
  this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      this.aliasChecked()
    })
    this.up = this.coreService.getUserPrivilage();
    this.cmpny = this.coreService.getUserData('cmpny');
    this.purch_id_no = this.ActiveRoute.snapshot.paramMap.get('purch_id');
    if (this.purch_id_no && !isNaN(this.purch_id_no)){
      document.getElementById("btnImportPurchase").click();
      this.puch_search();
    }
    this.apiService.getAllTaxCategory().subscribe((resp) => {
      this.taxCategories = resp.data;
    });
   
    this.showrate = localStorage.van_rate_display&&localStorage.van_rate_display=='1' ? true : false;
    this.rate_show = localStorage.van_trnsfr_rate_disply_type&&localStorage.van_trnsfr_rate_disply_type=='1' ? true : false;
    this.vantran_date = new Date();

    this.prd_name_search = 0;
    this.getNxtVanTranId();
    this.listGodowns();
    this.readVanTransfers();
    this.searchVans(null);
    this.searchProducts(null, null);
    this.base_url = location.origin;
    this.gddef = this.coreService.getUserData('usr_default_godown_id');
    this.userEncpType = this.coreService.getUserData('usr_enc_rate_code');
    this.usr_hide_purch_cost = this.coreService.getUserData('usr_hide_purch_cost');
    if (this.gddef > 0) {
      this.vantran_godown_id = Number(this.gddef);
    }

this.getSalesSetting();
this.country_dec=this.coreService.setDecimalLength();
  }

  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      this.prd_stock_status=false;
      // this.prd_stock_status_type = 'purch';
    }else{
      this.prd_stock_status=true;
      // this.prd_stock_status_type = 'purch';
    }
  }
  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.van_searchType = this.settings.van_searchType;
        this.van_difault_search = this.settings.van_default_search; 
        this.van_difault_rate = this.settings.van_default_rate;
        this.van_transfer_ptype = this.settings.van_transfer_rate_is_avg_prate;
        this.van_sale_byunit = this.settings.van_sales_by_unit;
        this.van_rate_validation = this.settings.van_item_rate_validation;
        this.show_balance = this.settings.ss_van_show_balance;

        if(this.van_difault_rate){
          this.rate_status = true;
          this.rate_status_type = 'sale';
          }else{
        
          this.rate_status=false;
          this.rate_status_type = 'purch';
        }
      } else { 
        this.van_searchType = 0;
        this.van_sale_byunit = 0;

      }
    });

  }

  listGodowns() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godown_all = res['data'];


    });

  }
  // search godown
  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
     

    });

  }

  searchTransfer(key) {
    let searchval = new FormData();
    searchval.append("status", '1');
    searchval.append("keyword", key);
    this.apiService.readVanTransfers(searchval, 1).subscribe((res) => {
      this.vanlines = res['data'];

      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }




  readVanTransfers() {
    this.pageLoadingImg2=true;
    let searchval = new FormData();
    searchval.append("status", '1');
    this.apiService.readVanTransfers(searchval, 1).subscribe((res) => {
      this.pageLoadingImg2=false;
      this.vanlines = res['data'];

      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }
  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  pageNext(formdata: { value: any; }, pageno: any) {
    this.pageLoadingImg2=true;
    let searchval = new FormData();
    this.apiService.readVanTransfers(searchval, pageno).subscribe((res) => {
      this.pageLoadingImg2=false;
      this.vanlines = res['data'];
      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
  }
  selectGodown(gdid: number) {

    this.gdid = gdid;
  }

  changeRate(rate) {

    if (rate == 'purch') {
     
      // this.vantran_purch_price = this.base_rate;
      this.rate_status_type = 'purch';
      this.convertRate(this.default_unit);

    } else if (rate == 'sale') {
     

      // this.vantran_purch_price = this.sale_rate;
      this.rate_status_type = 'sale';
      this.convertRate(this.default_unit);


    }

  }


  searchVans(search: string) {
    this.van_all = [];
    let searchval = new FormData();
    if (search) {
      searchval.append("van_name", search);
    }

    this.apiService.searchVans(searchval).subscribe((res) => {
      this.van_all = res['data'];

    });
  }

  resetItemsearch() {
    this.vantran_purch_price = 0;

    this.prd_barcode = '';
    this.brcode = '';
    this.default_unit = [];
    this.vantransub_qty = 0;
  }
  barcodeSearch(brcode: string) {
    this.resetItemsearch();
    this.prd_all = [];
    let searchval = new FormData();
    searchval.append("brcode", brcode);
    this.apiService.searchVanProducts(searchval).subscribe((res) => {
      // this.prd_all = res['data'];
      this.prd_id = res['data'][0];
      this.setValuesbarcode(res['data'][0]);
      if(this.prd_id){
        this.getStockByUnit(this.prd_id.prd_id,this.prd_id.eanunit?this.prd_id.eanunit.sur_unit_id:this.prd_id.c_unit);
      }
      
     
      
      this.getStockDetails(this.prd_id.prd_id,this.vantran_godown_id);
    });
   
  }

  searchProducts(search: string, brcode: string) {
    this.resetItemsearch();
    this.prd_all = [];
    let searchval = new FormData();
    if (search) {
      if(this.prd_stock_status==false){
        searchval.append('alias_search', '1');
      }
      searchval.append("keyword", search);
      searchval.append("van_id", this.vantran_godown_id);
    }

    this.apiService.searchVanProducts(searchval).subscribe((res) => {
      this.prd_all = res['data'];
      this.srch_count = this.prd_all.length;
    });
  }

  getNxtVanTranId() {
    let searchval = new FormData();
    this.apiService.getNxtVanTranId(searchval).subscribe((res) => {
      this.tran_id = res['data'].tran_id;
    });

  }


  setValues(prd_data) {

  
    if (prd_data) {
    // if(prd_data.prd_id){
      this.getStockDetails(prd_data.prd_id, this.vantran_godown_id);
    }else{
      this.selctedProd=[];
      return false;
    }
    
    this.base_rate = prd_data.bs_prate;
    this.avg_rate=prd_data.bs_avg_prate
    if (this.userEncpType) {
      if (this.rate_status_type == 'purch') {
        this.vantran_purch_price = this.coreService.splitToDigit(prd_data.bs_srate); // Encrypted Rate
        if (this.van_transfer_ptype ==2) { 
          console.log('hello');
          
          this.vantran_purch_price = this.coreService.splitToDigit(prd_data.bs_avg_prate);
        } 
      } else {
        this.vantran_purch_price = prd_data.bs_srate;
        if (this.van_transfer_ptype ==2) { 
          this.vantran_purch_price = prd_data.bs_avg_prate;
        }

      }
      this.sale_rate = prd_data.bs_srate;

    } else {
      this.vantran_purch_price = this.sale_rate = prd_data.bs_srate;
    }
    this.prd_barcode = prd_data.prd_barcode;
    this.prd_units = prd_data.prd_units;
    this.vantransub_qty = '';

    if(prd_data.prd_default_unit){
      this.default_unit = prd_data.prd_default_unit;
      
      
      this.convertRate(this.default_unit)
    }else{
      if (!prd_data.eanunit) {
        this.default_unit = { "unit_code": prd_data.base_unit_code, "unit_name": prd_data.base_unit_name, "sur_unit_id": prd_data.prd_base_unit_id, "unit_base_qty": 1 ,"purch_rate":prd_data.bs_prate,"sale_rate":prd_data.bs_srate};

      
        
      }
      else {
        this.default_unit = prd_data.eanunit;

      

        this.base_rate = prd_data.eanunit.purch_rate;
        if (this.userEncpType) {
          if (this.rate_status_type == 'purch') {
            this.vantran_purch_price = this.coreService.splitToDigit(prd_data.eanunit.sale_rate); // Encrypted Rate
          } else {
            this.vantran_purch_price = prd_data.eanunit.sale_rate;
  
          }
          this.sale_rate = prd_data.eanunit.sale_rate;
        } else {
          this.vantran_purch_price = this.sale_rate = prd_data.eanunit.sale_rate;
        }
        
      }
      this.convertRate(this.default_unit);
    }


    this.fieldName3.nativeElement.focus();
  }

  setValuesbarcode(prd_data) {


    this.barcodesrch=true;
    
      if (this.rate_status_type == 'purch') {
        if (this.userEncpType) {
          this.vantran_purch_price = this.coreService.splitToDigit(prd_data.eanunit.purch_rate); // Encrypted Rate
          if (this.van_transfer_ptype ==2) { 
            console.log('hello');
            
            this.vantran_purch_price = this.coreService.splitToDigit(prd_data.eanunit.purchavrg_rate);
           
            
          } 
        }else{
          console.log('hi');
          
          this.vantran_purch_price=prd_data.eanunit.purch_rate;
          console.log(' this.vantran_purch_price', this.vantran_purch_price);
          
          if (this.van_transfer_ptype ==2) { 
            console.log('hi');
            this.vantran_purch_price = prd_data.eanunit.purchavrg_rate;
            console.log(' this.vantran_purch_price ', this.vantran_purch_price );
          }
        
        }
        
      } else {
        this.vantran_purch_price = prd_data.eanunit.sale_rate;
  
      }

      if(prd_data.prd_default_unit){
        this.default_unit = prd_data.prd_default_unit;
        if (this.rate_status_type == 'purch') {
          if (this.userEncpType) {
            this.vantran_purch_price = this.coreService.splitToDigit(this.default_unit.purch_rate); // Encrypted Rate
            if (this.van_transfer_ptype ==2) { 
              console.log('hello');
              
              this.vantran_purch_price = this.coreService.splitToDigit(this.default_unit.purchavrg_rate);
             
              
            } 
          }else{
            console.log('hello');
            
            this.vantran_purch_price=this.default_unit.purch_rate;
            console.log(' this.vantran_purch_price', this.vantran_purch_price);
            if (this.van_transfer_ptype ==2) { 
              console.log('hi');
              this.vantran_purch_price = this.default_unit.purchavrg_rate;
              console.log(' this.vantran_purch_price ', this.vantran_purch_price );
            }
            
          }
          
        } else {
          this.vantran_purch_price = this.default_unit.sale_rate;
    
        }

      }else{
        this.default_unit = prd_data.eanunit;
      }
      this.prd_barcode = prd_data.prd_barcode;
      this.prd_units = prd_data.prd_units;


    this.fieldName3.nativeElement.focus();
  }
  deleteItem(data: any,vantranformadata) {
    var rmvitem = confirm("Are you sure you want to remove this item from list ? ");
    if (rmvitem) {
      this.total_qty = this.selectedItems.length;
      var type = data.sl_no;
      var i;
      for (i = this.selectedItems.length - 1; i >= 0; --i) {
        if (this.selectedItems[i].sl_no == type) {
          this.selectedItems.splice(i, 1);
        }
      }
      if (this.selectedItems.length == 0){
        this.vanTransBtn1 = false;
        this.vanTransBtn = false;
      }
        $('#gdtran-btn').hide();
        // this.vanTransBtn = false;
      this.findTotalSum();
      this.isEdit =false;
      this.vanTransferLog(vantranformadata);

    }
  }

  validateForm() {

    this.printError = [];
    if ($('#prd_barcode').val() == "" || $('#prd_barcode').val() == undefined) {
      this.printError.push({ 'prd_barcodeErr': 'Required' });
      $('#prd_barcode').css("border", "1px solid red");
    } else {
      $('#prd_barcode').css("border", "1px solid #dedede");
    }
    if (this.vantransub_qty == undefined || this.vantransub_qty == null || this.vantransub_qty <= 0) {
      this.printError.push({ 'vantransub_qtyErr': 'Required' });
      $('#vantransub_qty').css("border", "1px solid red");
    } else {
      $('#vantransub_qty').css("border", "1px solid #dedede");
    }

    if(this.van_sale_byunit){
      if (this.unit_wise_avlbleqty < this.vantransub_qty) {
        this.printError.push({ 'vantransub_qtyErr': 'Required' });
        $('#vantransub_qty').css("border", "1px solid red");
      } else {
        $('#vantransub_qty').css("border", "1px solid #dedede");
      }
    }
    
    if(this.van_rate_validation){
      if ($('#vantran_purch_price').val() == "" || $('#vantran_purch_price').val() == undefined) {
        this.printError.push({ 'vantran_purch_priceErr': 'Required' });
        $('#vantran_purch_price').css("border", "1px solid red");
      } else {
        if ($('#vantran_purch_price').val() > 0) {
          $('#vantran_purch_price').css("border", "1px solid #dedede");
        } else {
          this.printError.push({ 'vantran_purch_priceErr': 'Required' });
          $('#vantran_purch_price').css("border", "1px solid red");
        }
      }
    }
   

  }

  validateTransfer(formData) {

    this.printError = [];
    if (formData.vantran_date == "" || formData.vantran_date == undefined) {
      this.errObjArr.tandateErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {

      // if (formData.vantran_date > new Date()) {
      //   this.errObjArr.tandateErr = "t";
      //   this.printError.push({ 'fieldErr': 'Required' });
      // } else {
        this.errObjArr.tandateErr = "f";
      // }
    }
    
    if (formData.vantran_van_id == "" || formData.vantran_van_id == undefined) {
      this.errObjArr.tanvanErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      this.errObjArr.tanvanErr = "f";
    }

    if (formData.vantran_godown_id == undefined) {
      this.errObjArr.tangdErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      this.errObjArr.tangdErr = "f";
    }
  }

  convertRate(vantransub_unit_id) {

     console.log("unitttttt");
     console.log(vantransub_unit_id);

    this.getStockByUnit(this.prd_id.prd_id,vantransub_unit_id.unit_id?vantransub_unit_id.unit_id:vantransub_unit_id.sur_unit_id);
    if(this.barcodesrch){

      if (this.rate_status_type == 'purch') {

        if (this.userEncpType) {
          this.vantran_purch_price = this.coreService.splitToDigit(vantransub_unit_id.purch_rate); // Encrypted Rate
          if (this.van_transfer_ptype ==2) { 
            console.log('hello');
            
            this.vantran_purch_price = this.coreService.splitToDigit(vantransub_unit_id.purchavrg_rate);
           
            
          } 
        
         
        } else {
          this.vantran_purch_price = vantransub_unit_id.purch_rate;
          console.log('hello');
          
          console.log(' this.vantran_purch_price ', this.vantran_purch_price );
          if (this.van_transfer_ptype ==2) { 
            console.log('hi');
            this.vantran_purch_price = vantransub_unit_id.purchavrg_rate;
            console.log(' this.vantran_purch_price ', this.vantran_purch_price );
          }
        
          
        }
      } else {
        this.vantran_purch_price = vantransub_unit_id.sale_rate;
        // this.rate_status_type = 'sale';
  
      }

    }else{
    if (this.rate_status_type == 'purch') {
      if (this.userEncpType) {
        this.vantran_purch_price = this.coreService.splitToDigit(this.base_rate * (vantransub_unit_id.unit_base_qty)); // Encrypted Rate
       
        if (this.van_transfer_ptype ==2) { 
          console.log('hello');
          console.log('avgrate',this.avg_rate);
          
          
          this.vantran_purch_price = this.coreService.splitToDigit(this.avg_rate * (vantransub_unit_id.unit_base_qty));
          console.log('this.vantran_purch_price',this.vantran_purch_price);
          console.log('vantransub_unit_id.unit_base_qty',vantransub_unit_id.unit_base_qty);
        } 
       
      } else {
       
        this.vantran_purch_price = this.base_rate * (vantransub_unit_id.unit_base_qty);
        if (this.van_transfer_ptype ==2) { 
          console.log('hello');
          
          this.vantran_purch_price = this.avg_rate * (vantransub_unit_id.unit_base_qty);
          console.log('this.vantran_purch_price',this.vantran_purch_price);
          
        }
      }
    } else {
      this.vantran_purch_price = this.sale_rate * (vantransub_unit_id.unit_base_qty);
      // this.rate_status_type = 'sale';

    }
    }

    console.log("check unit");
    console.log(this.default_unit);
  }

  appendItem(prd_data, prd_barcode, vantransub_unit_id,vantranformadata) {

    // console.log(this.brcode+"gim0");
    if (prd_data && !prd_data.branch_stock_id) {

      let searchval = new FormData();
      searchval.append("brcode", prd_barcode);
      this.apiService.searchVanProducts(searchval).subscribe((res) => {

        prd_data = res['data'][0];

      });

    }
    if (!vantransub_unit_id) {
      this.printError.push({ 'vantransub_unit_idErr': 'Required' });
      $('#vantransub_unit_id').css("border", "1px solid red");
    }
    else {
      $('#vantransub_unit_id').css("border", "1px solid #dedede");
    }
    this.validateForm();

    if (this.printError.length <= 0) {
      this.box = this.vantransub_qty;
      // this.display_qty = '';
      this.display_qty = vantransub_unit_id.unit_code;
      this.unit_qty = '';
      if (vantransub_unit_id.unit_base_qty != 1) {

        this.unit_qty = this.box;
        this.display_qty = vantransub_unit_id.unit_code;

        this.vantransub_qty = this.box * (vantransub_unit_id.unit_base_qty);
        this.vantran_purch_price = this.vantran_purch_price / (vantransub_unit_id.unit_base_qty);

      }


      this.sl_no = (this.selectedItems.length) + 1;

      this.vantransub_qty = Number(this.vantransub_qty);
      this.vantran_purch_amount = this.vantran_purch_price * this.vantransub_qty;
      // var existingArray: any;
      if (this.selectedItems.length < 0) {
        // var existingArray = [];
      }
      else {
       
        
        this.existingArray = this.coreService.checkTransferExist(this.selectedItems,
              prd_data.branch_stock_id,
              vantransub_unit_id.sur_unit_id,
              this.vantran_purch_price,
              prd_data.prd_barcode,
              vantransub_unit_id.unit_code, prd_data.prd_id);
        
       
      }


      if (this.existingArray && this.existingArray.length > 0) {

        this.selectedItems.find(x => x.sl_no === this.existingArray[0].sl_no).vantransub_qty += this.vantransub_qty;
        this.selectedItems.find(x => x.sl_no === this.existingArray[0].sl_no).item_amount = this.vantransub_qty;
        this.selectedItems.find(x => x.sl_no === this.existingArray[0].sl_no).vantran_purch_amount += this.vantran_purch_amount;
      }
      else {

        this.selectedItems.push({
          "sl_no": this.sl_no, "prd_barcode": prd_data.prd_barcode,
          "prd_id": prd_data.prd_id,
          "prd_name": prd_data.prd_name,
          "prd_alias": prd_data.prd_alias,
          "vantransub_qty": this.vantransub_qty,
          "box": this.box,
          "purchase_rate": this.vantran_purch_price,
          "purchase_amount": this.vantran_purch_amount,
          "vantransub_prod_id": prd_data.prd_id,
          "vantransub_stock_id": prd_data.bs_stock_id,
          "vantransub_branch_stock_id": prd_data.branch_stock_id,
          "vantransub_unit_id": vantransub_unit_id.sur_unit_id,
          "vantransub_purch_rate": this.vantran_purch_price,
          "unit_name": vantransub_unit_id.unit_name,
          "unit_base": vantransub_unit_id.unit_base_qty,
          "unit_code": prd_data.base_unit_code,
          "base_unit_name": prd_data.base_unit_name,
          "display_qty": this.display_qty,
          "item_unit_code": vantransub_unit_id.unit_code,
          "rate_status":this.rate_status,

        });
      }
      console.log('insert...');
      
      console.log(this.selectedItems);
      
      this.vanTransferLog(vantranformadata);
      //  this.vantransub_qty = Number(this.vantransub_qty);
      this.total_qty = this.selectedItems.length;
      this.vantransub_qty = this.box;
      this.vanTransBtn = true;
      this.vanTransBtn1 = true;

      this.vantran_purch_price = 0;
      this.prd_barcode = '';
      this.brcode = '';
      this.prd_id = '';
      this.default_unit = [];
      this.vantransub_qty = 0;
      this.findTotalSum();
      $('#fieldName4').val('');

      if(this.van_difault_search){
        this.fieldName5.focus();
      }else{
        this.fieldName4.nativeElement.focus();
      }

      this.selctedProd=[];

      
    }
  }

  findTotalSum() {
    this.total_amount = 0;
    this.total_drvd_qty = 0;
    this.selectedItems.forEach(element => {

      this.total_amount += Number(element.purchase_rate * element.vantransub_qty);
      this.total_drvd_qty += Number(element.box);
    });
  }
  checkEnterClicked2(e) {
    if (e.keyCode === 13) {
      $("#plusButton2").click();
    }
  }
  vanTransfer(formData) {
    var confrm = confirm("Are you sure?");
    if (confrm) {
      this.validateTransfer(formData.value);
      if (this.printError.length <= 0) {
        this.pageLoadingImg = true;
        
        formData.value.vantran_request_id =this.vantran_request_id;
        formData.value.items = this.selectedItems;
        formData.value.vantran_price = this.total_amount;
        formData.value.vantran_date = (formData.value.vantran_date) ?
          this.apiService.formatDate(formData.value.vantran_date) : formData.value.vantran_date;
        this.apiService.vanTransfer(formData.value).subscribe((res) => {
          this.pageLoadingImg = false;

          console.log("ddddd old");
          console.log(formData.value.vanTranLogId);

          if (formData.value.vanTranLogId) {
            this.removeVanTranLog(formData.value.vanTranLogId);
          }

          this.coreService.createfunct(formData, this.resultobj, res.message);
        
          this.selectedItems = [];
          this.total_qty = 0;
          this.total_amount = 0;
          

          

          


          this.vanTransBtn = false;
          this.ngOnInit();
          this.isEdit =false;

          if(res){

            this.getVanTrnfReqList(1);
            this.pendingVanReqCount();
          }

        });


        
      }
      else {

      }
    }
  }

  removeVanTranLog(vanTranLogId) {
    let searchval = new FormData();
    searchval.append("vanTranLogId", vanTranLogId);
    this.apiService.removeFromVanTransferLog(searchval).subscribe((res1) => {
      this.logList =[];
      this.apiService.getVanTransferLogs('').subscribe((res) => {
         this.logList = res;
      });

    });
  }

  vanTransferLog(formdata) {
  
    this.findTotalSum();
    // this.updateRebateAmount();
    
     console.log("ggggf");
     console.log(this.vantran_request_id);
    formdata.value.vantrn_log_request_id =this.vantran_request_id;
    formdata.value.items = this.selectedItems;
    formdata.value.vantrn_log_price = this.total_amount;
    formdata.value.vantrn_log_notes = this.vantran_notes;
    formdata.value.vantrn_log_date = (this.vantran_date) ?
          this.apiService.formatDate(this.vantran_date) : this.vantran_date;
    formdata.value.vantrn_log_van_id = this.vantran_van_id;
    formdata.value.vantrn_log_godown_id = this.vantran_godown_id;



   
    formdata.value.items = this.selectedItems;
    this.apiService.vanTransferLog(formdata.value).subscribe((res) => {
      console.log("ssssssssssaaa");
      console.log(res);
      this.vanTranLogId = res;
      this.pageLoadingImg = false;
      this.getVanTransferLogs();

      

    });

  }

  tabClick(tab: { index: number; }) {
    
    if(tab.index==1){
      this.getVanTrnfEditLogList(1);
    }

    if(tab.index==2){
      this.getVanTrnfReqList(1);
    }
  }
  getVanTrnfEditLogList(pageNo = 1) {
    let searchval = new FormData();
    this.logLoadingImg = true;
    this.apiService.getvanTransferLogList(searchval, pageNo).subscribe((res) => {
      this.saleEditLogLoaded = true;
      this.vanTranEditLog = res.data.data;
      this.log_curpage = res.data['current_page'];
      this.log_lastpage = res.data['last_page'];
      this.log_from = res.data['from'];
      this.log_pgEnd(this.log_curpage, this.log_lastpage);
      this.log_pgStart(this.log_curpage);
      this.logLoadingImg = false;
    });
  }


  getVanTrnfReqList(pageNo = 1) {
    let searchval = new FormData();
    this.logLoadingImg = true;
    this.apiService.getVanTrnfReqList(searchval, pageNo).subscribe((res) => {
      this.saleEditLogLoaded = true;
      this.vanReqList = res.data.data;
      console.log("vanReqList");
      console.log(res.data.data);
      this.log_curpage = res.data['current_page'];
      this.log_lastpage = res.data['last_page'];
      this.log_from = res.data['from'];
      this.log_pgEnd(this.log_curpage, this.log_lastpage);
      this.log_pgStart(this.log_curpage);
      this.logLoadingImg = false;
    });
  }
  rejectRequest(data){
    
    console.log("data");
    console.log(data);

    var confrm = confirm("Are you sure?");
    if (confrm) {

      const searchval = new FormData();
      searchval.append('vtrq_id', data.vtrq_id);
      
      this.apiService.getVanTranReqReject(searchval).subscribe((res) => {
          
        console.log(res);
        console.log(res.message);
        this.coreService.showMessage(res.message);
         
       
         
        this.getVanTrnfReqList(1);
        this.pendingVanReqCount();
  
      });

    }
  }

  acceptRequest(data){

    console.log("accept");
    console.log(data);
    this.isEdit =false;
    this.edit_trnsfer =false;

    const searchval = new FormData();
    searchval.append('vtrq_id', data.vtrq_id);
    
    this.apiService.getVanTranReq(searchval).subscribe((res) => {
         console.log("res");
         console.log(res);

         console.log("edit dataaa");
         console.log(res['data'].VanTransferRequestSub);
        let data =res['data'];
         this.showTransfCont=true;
         this.vanTransBtn =true;
        //  this.tran_id=data.vantran_id;
         this.getNxtVanTranId();
         this.vantran_date=new Date(data.VanTransferRequest.vtrq_date);
         this.vantran_godown_id=data.VanTransferRequest.vtrq_godown_id;
         this.vantran_van_id=data.VanTransferRequest.vtrq_van_id;
         this.vantran_request_id = data.VanTransferRequest.vtrq_id;
         this.vantran_notes =data.VanTransferRequest.vtrq_notes;
         this.vantran_purch_price =0;
         // this.selectedItems=data.items;
         // console.log(this.selectedItems.length +'  helooooo');
         
        //  this.total_amount=data.vantran_price;
         this.selectedItems=[];
         data.VanTransferRequestSub.forEach((element, index) => {
     
           if(element.unit_type){
             this.display_qty='';
           }else{
             this.display_qty=element.prod.prd_default_unit.unit_code;
           }
           this.selectedItems.push({
     
             "sl_no": index+1, "prd_barcode": element.prod.prd_barcode,
             "prd_id": element.prod.prd_id,
             "prd_name": element.prod.prd_name,
             "prd_alias": element.prod.prd_alias,
             "vantransub_qty": element.vtrqsub_qty * element.prod.prd_default_unit.unit_base_qty,
             "box": element.vtrqsub_qty,
             "purchase_rate": element.prod.rate,
             "purchase_amount": element.prod.rate,
             "vantransub_prod_id": element.prod.prd_id,
             "vantransub_stock_id": element.prod.bs_stock_id,
             "vantransub_branch_stock_id": element.prod.branch_stock_id,
             "vantransub_unit_id": element.vtrqsub_unit_id,
             "vantransub_purch_rate": element.prod.rate,
             // "unit_name": vantransub_unit_id.unit_name,
              "unit_base": element.prod.prd_default_unit.unit_base_qty,
             "unit_code": element.prod.unit_code,
             "base_unit_name": element.prod.unit_name,
             "display_qty": this.display_qty,
             "item_unit_code": element.unit_display,
             "rate_status":element.prod.rate_status,
             
     
           });
         });
     
         // code to get total derived qty
         this.total_drvd_qty = 0;
         this.selectedItems.forEach(element => {
           this.total_drvd_qty += Number(element.box);
         });
         
         this.total_qty = this.selectedItems.length;
         if(this.selectedItems.length !=0){
           this.vanTransBtn1 = true;
         }
         this.existingArray=this.selectedItems;
         this.findTotalSum();

        

    });




    
  }

  log_pgEnd(curr, end) {
    if (curr == end)
      this.log_pgend = true;
    else
      this.log_pgend = false;

  }

  log_pgStart(curr) {
    if (curr == 1)
      this.log_pgstart = true;
    else
      this.log_pgstart = false;
  }
  rateDisplay(value){
    if(value){
      localStorage.setItem("van_rate_display",'1');
    }else{
      localStorage.setItem("van_rate_display",'0');
    }
   
  }

  
// code to disply rate type save in storage
  rateDisplayType(value){
    if(value){
      localStorage.setItem("van_trnsfr_rate_disply_type",'1');
    }else{
      localStorage.setItem("van_trnsfr_rate_disply_type",'0');
    }
  }
  getPreview(data) {

    if(!this.rate_show){
      this.preview_prize=0;
    data.items.forEach((element, index) => {
     
        this.preview_prize += element.sales_rate*element.vantransub_qty;
    });
    }else{
    this.preview_prize = data.vantran_price;
     }

    this.preview_id = data.vantran_id;
    this.preview_date = data.vantran_date;
    this.preview_time = data.created_at;
    this.preview_gd = data.gd;
    this.preview_van = data.van;
    this.preview_branch = data.branch;
    // console.log(this.preview_branch);
    // this.preview_prize = data.vantran_price;
    this.preview_tot_qty = data.tot_qty;
    this.total_base_qty = data.total_base_qty;
    this.total_derived_qty = data.total_derived_qty;
    this.van_notes = data.vantran_notes;

    this.preview_items = data.items;
    this.preview_van_users = data.van_users;
   
    // van balance fetch
    this.vanChange(data.van_id,data.van_cash_ledger_id);
  }


  previewRequest(data) {

    console.log("gfffffff");
    console.log(data);
    this.vtrq_request_id = "";
    this.vtrq_date =  "";
    this.vtrq_van = "";
    this.vtrq_branch = "";
    this.vtrq_van_name ="";
    this.vtrq_notes ="";
    this.preview_items1 =[];

    const searchval = new FormData();
    searchval.append('vtrq_id', data.vtrq_id);
    
    this.apiService.getVanTranReq(searchval).subscribe((res) => {
         console.log("res");
         console.log(res['data']);
        
   
    this.vtrq_request_id = data.vtrq_request_id;
    this.vtrq_date =  data.vtrq_date;
    this.vtrq_van = res['data'].van;
    this.vtrq_branch = res['data'].branch;
    this.vtrq_van_name = this.vtrq_van.van_name;
    this.vtrq_notes = data.vtrq_notes;
    this.preview_items1 = res['data'].VanTransferRequestSub;
 
  });

  }

  

  edittransfer(data){

    console.log("dffffff");
    console.log("edit dataaa");
    console.log(data);
    this.edit_trnsfer=true;
    this.showTransfCont=true;
    this.tran_id=data.vantran_id;
    this.vantran_date=new Date(data.vantran_date);
    this.vantran_godown_id=data.vantran_godown_id;
    this.vantran_van_id=data.vantran_van_id;
    this.vantran_request_id =0;
    this.vantran_notes =data.vantran_notes;
    // this.selectedItems=data.items;
    // console.log(this.selectedItems.length +'  helooooo');
    
    this.total_amount=data.vantran_price;
    this.selectedItems=[];
    data.items.forEach((element, index) => {

      if(element.unit_type){
        this.display_qty='';
      }else{
        this.display_qty=element.unit_code_edit;
      }
      this.selectedItems.push({

        "sl_no": index+1, "prd_barcode": element.prd_barcode,
        "prd_id": element.vantransub_prod_id,
        "prd_name": element.prd_name,
        "prd_alias": element.prd_alias,
        "vantransub_qty": element.vantransub_qty,
        "box": element.unit_base_qty,
        "purchase_rate": element.vantransub_purch_rate,
        "purchase_amount": element.vantransub_purch_rate,
        "vantransub_prod_id": element.vantransub_prod_id,
        "vantransub_stock_id": element.vantransub_stock_id,
        "vantransub_branch_stock_id": element.vantransub_branch_stock_id,
        "vantransub_unit_id": element.vantransub_unit_id,
        "vantransub_purch_rate": element.vantransub_purch_rate,
        // "unit_name": vantransub_unit_id.unit_name,
        "unit_base": element.cubq,
        "unit_code": element.unit_show_code,
        "base_unit_name": element.base_unit_name,
        "display_qty": this.display_qty,
        "item_unit_code": element.unit_show_code,
        "rate_status":element.rate_status,
        

      });
    });

    // code to get total derived qty
    this.total_drvd_qty = 0;
    this.selectedItems.forEach(element => {
      this.total_drvd_qty += Number(element.box);
    });
    
    this.total_qty = this.selectedItems.length;
    if(this.selectedItems.length !=0){
      this.vanTransBtn1 = true;
    }
    this.existingArray=this.selectedItems;

  // van balance fetch
   this.vanChange(data.vantran_van_id,data.van_cash_ledger_id)
  }

  vanTransferUpddate(formData) {
    var confrm = confirm("Are you sure?");
    if (confrm) {
      this.validateTransfer(formData.value);

        this.pageLoadingImg = true;

        formData.value.items = this.selectedItems;
        formData.value.vantran_price = this.total_amount;
        formData.value.vantran_date = (formData.value.vantran_date) ?
          this.apiService.formatDate(formData.value.vantran_date) : formData.value.vantran_date;
        this.apiService.vanTransferEdit(formData.value).subscribe((res) => {
          this.pageLoadingImg = false;
          this.edit_trnsfer=false;
          this.coreService.createfunct(formData, this.resultobj, res.message);
          this.selectedItems = [];
          this.total_qty = 0;
          this.total_amount = 0;

          this.vanTransBtn1 = false;
          this.ngOnInit();
          this.isEdit;

        });
   
    }
  }

  getGodownWiseStock(prd_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    this.godownStocksTotal = 0;

    this.apiService.getGodownWiseStock(searchval).subscribe((res) => {
      this.godownStocks = res['data']['data'];
      this.godownStocksTotal = res['data']['total'];
    });

  }

  getStockDetails(prd_id, gd_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    searchval.append('gd_id', gd_id);
    this.apiService.getsaleProdDetails(searchval).subscribe((res) => {
      this.selctedProd = res['data'];
    });

  }

  getStockByUnit(prd_id, unit_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    searchval.append('unit_id', unit_id);
    searchval.append('gd_id', this.vantran_godown_id);
    this.apiService.stockByUnit(searchval).subscribe((res) => {
      this.unit_wise_avlbleqty = res.avialable_qty;
    });

  }
  gdChange(){
    if(this.prd_id){
      this.getStockDetails(this.prd_id.prd_id, this.vantran_godown_id);
      if(this.vantransub_unit_id){
        this.getStockByUnit(this.prd_id.prd_id,this.vantransub_unit_id.unit_id?this.vantransub_unit_id.unit_id:this.vantransub_unit_id.sur_unit_id);
      }
     
    }
   
  }
  puch_search() {
    this.pageLoadingImg1 = true;
    const searchval = new FormData();
    searchval.append('purch_id', this.purch_id_no);
    this.clearForm();
    this.apiService.getpurchaseDetails(searchval).subscribe((res) => {
     
      this.pageLoadingImg1 = false;
      this.showTransfCont = true;

      console.log("purchase data");
      console.log(res.data);

      if(res.data.purch_vantran_id){
        this.showTransfCont =false;
       // this.coreService.showMessage('Purchase  Already Imported.');
        this.purch_id_no ='';
        this.purchaseValidation = true;
      }
      else{

        res.data.items.forEach((element,i) => {
          this.vantran_godown_id = element.purchsub_gd_id;
          this.selectedItems.push({
            "sl_no": i, 
            "prd_barcode": element.prd_barcode,
            "prd_id": element.prd_id,
            "prd_name": element.prd_name,
            "prd_alias": element.prd_alias,
            "vantransub_prod_id": element.prd_id,
            "vantransub_stock_id": element.purchsub_stock_id,
            "vantransub_branch_stock_id": element.purchsub_branch_stock_id,
            "vantransub_qty": element.purchsub_qty,
            "box": element.purchsub_qty / element.punit_base_qty,
            "purchase_rate": element.purchsub_rate,
            "purchase_amount": element.purchsub_rate * element.purchsub_qty,
            "vantransub_unit_id": element.purchsub_unit_id,
            "vantransub_purch_rate": element.purchsub_rate,
            // unit name actually not found in purchase sub please chk if any mistake
            "unit_name": element.unit_name,
            "unit_base": element.punit_base_qty,
            "unit_code": element.unit_code,
            "base_unit_name": element.unit_name,
            "display_qty": (element.punit_base_qty != 1) ? element.punit_code : '',
            "item_unit_code": element.punit_code,
            "rate_status":this.rate_status,
  
          });
        });
        if(res.data.items.length > 0){
          this.vanTransBtn = true;
        } 
        this.findTotalSum();

      }
    

    });
  }

  clearForm(){

    //this.router.navigate(['/van/transfer']);
   
    this.getNxtVanTranId();
    
    this.vantran_godown_id = Number(this.gddef);

    console.log("clearGd Id");
    console.log(this.vantran_godown_id);
    this.vantran_van_id=0;
    // this.vantran_godown_id=0;
    this.purchaseValidation = false;
    this.vantran_request_id =0;
    this.brcode ='';
    this.prd_id ='';
    this.prd_barcode ='';
    this.vantransub_qty ='';
    this.default_unit =[];
    this.vantran_purch_price =0;
    this.vantran_notes='';
    this.no_transfr='';
    this.copyRef='';
    this.selectedItems = [];
    this.vanTransBtn = false;
    this.findTotalSum();
    this.edit_trnsfer=false;
    this.vantran_date = new Date();
    if(this.van_difault_rate){
      this.rate_status = true;
      this.rate_status_type = 'sale';
    }else{
      this.rate_status=false;
      this.rate_status_type = 'purch';
    
    }

    setTimeout(() => {
      if(this.van_difault_search){
        this.fieldName5.focus();
      }else{
        this.fieldName4.nativeElement.focus();
      }
    }, 500);
    this.selctedProd=[];
  }

  copyTransfer(refno){

    const searchval = new FormData();
    searchval.append('purch_id', refno);
    this.copyLoading=true;
  
    this.apiService.getTranDetails(searchval).subscribe((res) => {

    if(res){

      this.copyLoading=false;
      this.vanTransBtn = true;
      this.edit_trnsfer=false;
      
      this.vantran_purch_price = 0;
      this.vantran_godown_id=res[0].gd[0].gd_id;
      this.vantran_van_id=res[0].van[0].van_id;
      this.vantran_request_id =0;
      // this.selectedItems=data.items;
      // console.log(this.selectedItems.length +'  helooooo');
      this.total_amount=res[0].vantran_price;
     
      this.selectedItems=[];
      res[0].items.forEach((element, index) => {
  
        if(element.unit_type){
          this.display_qty='';
        }else{
          this.display_qty=element.unit_code_edit;
        }
        this.selectedItems.push({
  
          "sl_no": index+1, "prd_barcode": element.prd_barcode,
          "prd_id": element.vantransub_prod_id,
          "prd_name": element.prd_name,
          "prd_alias": element.prd_alias,
          "vantransub_qty": element.vantransub_qty,
          "box": element.unit_base_qty,
          "purchase_rate": element.vantransub_purch_rate,
          "purchase_amount": element.vantransub_purch_rate,
          "vantransub_prod_id": element.vantransub_prod_id,
          "vantransub_stock_id": element.vantransub_stock_id,
          "vantransub_branch_stock_id": element.vantransub_branch_stock_id,
          "vantransub_unit_id": element.vantransub_unit_id,
          "vantransub_purch_rate": element.vantransub_purch_rate,
          // "unit_name": vantransub_unit_id.unit_name,
          "unit_base": element.cubq,
          "unit_code": element.unit_show_code,
          "base_unit_name": element.base_unit_name,
          "display_qty": this.display_qty,
          "item_unit_code": element.unit_show_code,
          "rate_status":this.rate_status,
          
  
        });
      });
  
      // code to get total derived qty
      this.total_drvd_qty = 0;
      this.selectedItems.forEach(element => {
        this.total_drvd_qty += Number(element.box);
      });
      
      this.total_qty = this.selectedItems.length;
      if(this.selectedItems.length !=0){
        this.vanTransBtn1 = true;
      }
      this.existingArray=this.selectedItems;

      this.no_transfr='';
    }else{
      this.copyLoading=false;
      this.no_transfr='No Transfer Found ID';
    }
    });
  }

  pendingVanReqCount() {

    this.apiService.pendingVanReqCount().subscribe((res) => {

       console.log(res['data']);
      this.pendingVanTran = res['data'];
     
      
    });

  }

  getGeneralSetting() {

    this.apiService.getGeneralSetting().subscribe((res) => {

      // console.log(res['data']);
      this.g_settings = res['data'];
      this.generalSearch_type = this.g_settings.g_s_search_type;
      console.log(this.generalSearch_type);
      
    });

  }
  

  editItem(data, i) {

    console.log("dataaa");
    console.log(data);
    this.sl_no =data.sl_no;

    $('.edit_row').removeClass('sel_row');
    $('#myrow' + i).addClass('sel_row');
    if(this.selectedItems.length >0)
    {
     
        const searchval = new FormData();
        searchval.append('prd_id', data.vantransub_prod_id);
        searchval.append('gd_id', this.vantran_godown_id);

        this.apiService.getsaleProdDetails(searchval).subscribe((res) => {
          this.selctedProd = res['data'];
          console.log("product");
          console.log(this.selctedProd);
          this.base_rate =this.selctedProd.bs_prate;
          this.avg_rate = this.selctedProd.bs_avg_prate;
          this.sale_rate =this.selctedProd.bs_srate;

          this.prd_id =this.selctedProd;
          this.prd_barcode =data.prd_barcode;
          this.vantransub_qty =data.vantransub_qty /data.unit_base;
          this.vantran_purch_price=data.purchase_rate *data.unit_base;
          this.rate_status = data.rate_status;



                 this.prd_units = this.selctedProd.prod_units;
                 console.log("units");
                 console.log(this.selctedProd.prod_units);
  
                 this.selctedProd.prod_units.forEach((value, i) => {
            if (value.unit_id == data.vantransub_unit_id) {

               this.default_unit = { "unit_code": value.unit_code, "unit_name": value.unit_name, "sur_unit_id": value.unit_id, "unit_base_qty": value.unit_base_qty ,"purch_rate":0,"sale_rate":value.sur_unit_rate};

            };
          });
    
         
        });
        this.isEdit =true;

    }

    
  
  }


  editSaleItem(prd_data, prd_barcode, vantransub_unit_id,vantranformadata)
  { 

    console.log("prd_data");
    console.log(prd_data);

    console.log("prd_barcode");
    console.log(prd_barcode);

    console.log("vantransub_unit_id");
    console.log(vantransub_unit_id);



    $('.edit_row').removeClass('sel_row');
   
    if (prd_data && !prd_data.branch_stock_id) {


      let searchval = new FormData();
      searchval.append("brcode", prd_barcode);
      this.apiService.searchVanProducts(searchval).subscribe((res) => {

        prd_data = res['data'][0];

      });

    }


    if (!vantransub_unit_id) {
      this.printError.push({ 'vantransub_unit_idErr': 'Required' });
      $('#vantransub_unit_id').css("border", "1px solid red");
    }
    else {
      $('#vantransub_unit_id').css("border", "1px solid #dedede");
    }
    this.validateForm();

    if (this.printError.length <= 0) {


     
      this.box = this.vantransub_qty;
      // this.display_qty = '';
      this.display_qty = vantransub_unit_id.unit_code;
      this.unit_qty = '';
      if (vantransub_unit_id.unit_base_qty != 1) {

        this.unit_qty = this.box;
        this.display_qty = vantransub_unit_id.unit_code;

        this.vantransub_qty = this.box * (vantransub_unit_id.unit_base_qty);
        this.vantran_purch_price = this.vantran_purch_price / (vantransub_unit_id.unit_base_qty);

      }


      //this.sl_no = (this.selectedItems.length) + 1;


      this.vantransub_qty = Number(this.vantransub_qty);
      this.vantran_purch_amount = this.vantran_purch_price * this.vantransub_qty;
      var existingArray: any;
      if (this.selectedItems.length < 0) {
        // var existingArray = [];
      }
      else {

        existingArray = this.coreService.checkTransferExist(this.selectedItems,
          prd_data.branch_stock_id,
          vantransub_unit_id.sur_unit_id,
          this.vantran_purch_price,
          prd_data.prd_barcode,
          vantransub_unit_id.unit_code, prd_data.prd_id);
      }


      if (existingArray && existingArray.length > 0) {

        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).vantransub_qty += this.vantransub_qty;
        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).item_amount = this.vantransub_qty;
        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).vantran_purch_amount += this.vantran_purch_amount;
      }
      else {

        if (!prd_data.prd_id) {
          $('#prdctSearch').css("border", "1px solid red");
        }
         
        else {
         
         
             this.selectedItems.find(x => x.sl_no === this.sl_no).sl_no = this.sl_no;
             this.selectedItems.find(x => x.sl_no === this.sl_no).prd_barcode = prd_data.prd_barcode;
             this.selectedItems.find(x => x.sl_no === this.sl_no).prd_id = prd_data.prd_id;

              // this.selectedItems.find(x => x.sl_no === this.sl_no).stocktrsub_prd_id = prd_data.prd_id;
             this.selectedItems.find(x => x.sl_no === this.sl_no).prd_name = prd_data.prd_name;
             this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_qty = this.vantransub_qty;
             this.selectedItems.find(x => x.sl_no === this.sl_no).box = this.box;
             this.selectedItems.find(x => x.sl_no === this.sl_no).purchase_rate = this.vantran_purch_price;
             this.selectedItems.find(x => x.sl_no === this.sl_no).purchase_amount = this.vantran_purch_amount;
             this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_prod_id = prd_data.prd_id;
             this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_stock_id = prd_data.bs_stock_id;
             this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_branch_stock_id = prd_data.branch_stock_id;
             this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_unit_id = vantransub_unit_id.sur_unit_id;
             this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_purch_rate = this.vantran_purch_price;

             this.selectedItems.find(x => x.sl_no === this.sl_no).unit_name = vantransub_unit_id.unit_name;

             this.selectedItems.find(x => x.sl_no === this.sl_no).unit_base = vantransub_unit_id.unit_base_qty;
              this.selectedItems.find(x => x.sl_no === this.sl_no).unit_code = prd_data.base_unit_code;
             //this.selectedItems.find(x => x.sl_no === this.sl_no).unit_code = vantransub_unit_id.unit_code;
             this.selectedItems.find(x => x.sl_no === this.sl_no).base_unit_name = prd_data.base_unit_name;
             this.selectedItems.find(x => x.sl_no === this.sl_no).display_qty = this.display_qty;
             this.selectedItems.find(x => x.sl_no === this.sl_no).item_unit_code = vantransub_unit_id.unit_code;
             this.selectedItems.find(x => x.sl_no === this.sl_no).rate_status = this.rate_status;
     
     
        }
      }

      this.total_qty = this.selectedItems.length;
      this.vantransub_qty = this.box;
      this.vanTransBtn = true;
      this.vanTransBtn1 = true;

      this.vantran_purch_price = 0;
      this.prd_barcode = '';
      this.brcode = '';
      this.prd_id = '';
      this.default_unit = [];
      this.vantransub_qty = 0;
      this.findTotalSum();
      $('#fieldName4').val('');

      if(this.van_difault_search){
        this.fieldName5.focus();
      }else{
        this.fieldName4.nativeElement.focus();
      }
      this.selctedProd=[];

    }
    // this.fieldName2.nativeElement.focus();
    this.isEdit =false;
    this.vanTransferLog(vantranformadata);
  }

  getVanTransferLogs() {
      this.logList =[];
    this.apiService.getVanTransferLogs('').subscribe((res) => {
       this.logList = res;
      // this.purch_amt = Number(res.purch_amount);
      // this.prch_tax = Number(res.purch_tax);

    });
  }

 

  getTempLog(vanTranLogId) {
    let searchval = new FormData();
    searchval.append("vanTranLogId", vanTranLogId);

    this.apiService.getVanTransferLog(searchval).subscribe((res: any) => {

      console.log("Log Dataaaa");
      console.log(res);
      console.log(res.data);
      let data =res.data;




      this.showTransfCont=true;
      this.vanTransBtn =true;
     //  this.tran_id=data.vantran_id;
      this.getNxtVanTranId();
      this.vantran_date=new Date(data.vantrn_log_date);
      this.vantran_godown_id=data.vantrn_log_godown_id;
      this.vantran_van_id=data.vantrn_log_van_id;
      this.vantran_request_id = data.vantrn_log_request_id;
      this.vantran_notes =data.vantrn_log_notes;
      this.vantran_purch_price =0;
    

      this.selectedItems=[];
      data.items.forEach((element, index) => {
  
      
          this.display_qty=element.display_qty;
       
        this.selectedItems.push({
  
          "sl_no": index+1, "prd_barcode": element.prd_barcode,
          "prd_id": element.prd_id,
          "prd_name": element.prd_name,
          "prd_alias": element.prd_alias,
          "vantransub_qty": element.vantransub_qty,
          "box": element.box,
          "purchase_rate": element.purchase_rate,
          "purchase_amount": element.purchase_amount,
          "vantransub_prod_id": element.vantransub_prod_id,
          "vantransub_stock_id": element.vantransub_stock_id,
          "vantransub_branch_stock_id": element.vantransub_branch_stock_id,
          "vantransub_unit_id": element.vantransub_unit_id,
          "vantransub_purch_rate": element.vantransub_purch_rate,
          // "unit_name": vantransub_unit_id.unit_name,
           "unit_base": element.unit_base,
          "unit_code": element.unit_code,
          "base_unit_name": element.unit_name,
          "display_qty": this.display_qty,
          "item_unit_code": element.item_unit_code,
          "rate_status":element.rate_status,
          
  
        });
      });
  
      // code to get total derived qty
      this.total_drvd_qty = 0;
      this.selectedItems.forEach(element => {
        this.total_drvd_qty += Number(element.box);
      });
      
      this.total_qty = this.selectedItems.length;
      if(this.selectedItems.length !=0){
        this.vanTransBtn1 = true;
      }
      this.existingArray=this.selectedItems;
      this.findTotalSum();
      


      this.vanTranLogId = res.vanTranLogId;
    



   

   

   

    });
    

  }

  
  removeFromLog(logId) {

    var remopstk = confirm("Are you sure you want to delete this Van Transfer from Log ? ");
    if (remopstk) {

      let searchval = new FormData();
       searchval.append("vanTranLogId", logId);
      this.apiService.removeFromVanTransferLog(searchval).subscribe((res1) => {
      this.apiService.getVanTransferLogs('').subscribe((res) => {
         this.logList = res;

         console.log("message");
         console.log(res1);
         this.coreService.showMessage(res1.message);
      });

      });

    }
  }

  closeFunction(){
    this.isEdit =false;
    this.vanTranLogId =0;

  }

  newForm(){
    this.purchaseValidation =false;
    //this.clearForm();
    this.router.navigate(['/van/transfer']);
    this.ngOnInit();

  }
  vanChange(van_id,vancash=null){

    this.van_balance=0;
    if(this.show_balance){
      let vans_cash_ledger=(vancash)?vancash:this.van_all.find(x => x.van_id === van_id).van_cash_ledger_id;
      this.balanceloading = true;
      const searchval = new FormData();
      searchval.append('van_ledger_id', vans_cash_ledger);
      searchval.append('van_id', van_id);
      searchval.append('period_type', 't');
      this.apiService.vanDistributorsRep2(searchval).subscribe((res) => {
        this.van_balance = res.data.balance;
        this.balanceloading = false;
    

      });
    }
  }
}
