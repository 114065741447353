import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-delivery-challan-settings',
  templateUrl: './delivery-challan-settings.component.html',
  styleUrls: ['./delivery-challan-settings.component.css']
})
export class DeliveryChallanSettingsComponent implements OnInit {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '150px',
    minHeight: '150px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'http://127.0.0.1:8000/api/saless/upimgs',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  types = [
    { id: 1, name: 'With Stock' },
    { id: 0, name: 'Without Stock' },
  ];

  dlvry_chlln_shw_qtn_no_types = [
    { id: 1, name: 'Show' },
    { id: 0, name: 'Hide' },
  ];

  default_item_creation: any;
  pageLoadingImg: boolean;
  settings: any;
  default_delivery_challan_creation: any;
  delivery_challan_default_terms : any;
  dlvry_chlln_shw_qtn_no: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }
  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.getSalesSetting();
  }

  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.default_delivery_challan_creation = this.settings.delivery_challan_stock;
        this.delivery_challan_default_terms = this.settings.delivery_challan_default_terms;
        this.dlvry_chlln_shw_qtn_no = this.settings.dlvry_chlln_shw_prnt_qtn_no;

      } else { 
        this.default_delivery_challan_creation = 0;
        this.delivery_challan_default_terms = '';
        this.dlvry_chlln_shw_qtn_no='';

      }
    });

  }
  update(formdata: { value: any; }) {
    this.pageLoadingImg = true;

    this.apiService.dlChallanSetting(formdata.value).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.coreService.showMessage('Setting Saved');
      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }

  langChange(){
    this.translate.get(['Common.With_Stock', 'ACCOUNTS.without_stock','HOME.show','HOME.hide']).subscribe((res: string) => {    
     

      this.types = [
        { id: 1, name: res['Common.With_Stock']},
        { id: 0, name: res['ACCOUNTS.without_stock'] },
  
      ];

      this.dlvry_chlln_shw_qtn_no_types = [
        { id: 1, name: res['HOME.show']},
        { id: 0, name: res['HOME.hide']},
      ];
      
    });

  }
}
