import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OfferComponent } from './offer/offer.component';
import { OfferDashComponent } from './offer-dash/offer-dash.component';
import { CustProductwiseOfferComponent } from './offer-dash/cust-productwise-offer/cust-productwise-offer.component';

const routes: Routes = [
  { path: 'offer', component:  OfferComponent},
  { path: 'offer_module', component:  OfferDashComponent},
  { path: 'customer_product_wise_offer', component:  CustProductwiseOfferComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OfferRoutingModule { }
