import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { CoreService } from '../../service/core.service';


@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css']
})

export class MasterComponent implements OnInit {
  userType:any;
  up: any;
  cmpny: any;
  usr_username:any;
  show_van = false;
  show_godown = false;
  default_item_creation: any;
  multipleCurrency= false;
  clientSettings: any;
  offer: boolean;
  constructor(private apiService: ApiService,
    private coreService: CoreService) { }

  ngOnInit() {
    this.getPrdSetting();
    this.coreService.getToken(); 
    this.up = this.coreService.getUserPrivilage();

  this.userType = this.coreService.getUserData('user_type');
  this.usr_username =  this.coreService.getUserData('usr_username');
  this.getEnbaleDisableModuleSettingBykey();
  this.getClientSetting();
  this.cmpny = this.coreService.getUserData('cmpny');
  }
  getPrdSetting() {
    this.apiService.getPrdSetting().subscribe((res) => {
      if (res['data']) {
        this.default_item_creation = res['data'].default_item_creation;
      } else { 
        this.default_item_creation = 1;
      }
    });
  }
  getEnbaleDisableModuleSettingBykey() {
    this.apiService.getEnbaleDisableModuleSettingBykey().subscribe((res) => {
      if (res['data']) {
        this.show_van = (res['data']['enable_van']) ? true: false;
        this.show_godown = (res['data']['enable_godown']) ? true: false;
      } 
    });
  }

  getClientSetting() {
   
    this.apiService.getAllClientSettings().subscribe((res) => {
      if (res['data']) {
        this.clientSettings = res['data'];
        this.multipleCurrency = (res['data']['multiple_currency_support']['cs_value']) ? true : false;
        // for offer module
        
        this.offer = Object.values(this.clientSettings).some(
          (item) =>
            (item as { cs_settings_type: number; cs_value: number }).cs_settings_type === 1 &&
            (item as { cs_settings_type: number; cs_value: number }).cs_value === 1
        );
        
      } 
    
    });
  }
}
