import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-customer-popup',
  templateUrl: './customer-popup.component.html',
  styleUrls: ['./customer-popup.component.css']
})

export class CustomerPopupComponent implements OnInit {
  slvalErrors = <any>{};
  files: File[] = [];
  all_files: any = [];
  selectedFiles: any = [];
  @Input() cust_id;
  @Input() last_cust_code;
  @Input() customerAttachments;
  @Input() customerDiscLimit;
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  @Output() customerUpdated = new EventEmitter();
  curForm = 'Create';
  customer = {
    id: '',
    ledger_id: '',
    name: '',
    alias: '',
    code: '',
    email: '',
    mobile: '',
    vat_no: '',
    cr_no: '',
    op_bal: 0,
    op_bal_old: 0,
    brch_id: 0,
    usr_type: 0,
    is_general_ledger: 0,
    op_bal_type: false,
    inv_limit_type: false,
    storage_eqp: false,
    inv_limit: null,
    default_currency_id: 0,
    cust_flag: false,
    add: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    del: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    due_day: '',
    van_line: 0,
    price_group: 1,
    note: '',
    credit_limit: 0,
    default_sales_agent: 0,
    gd_id:-1,
    default_cus_category: 0,
    default_cus_sub_category: 0,
    enable_sale_below_mrp: 0,
    cust_approval_flag: 0,
    cust_is_disc_limit:0,
    cust_disc_limit_perc:0,
    is_e_invoice: false,
    einv_scheme_id: 'CRN',
    einv_scheme_no: '',
    einv_reg_name: '',
    einv_vat_no: '',
    einv_city: '',
    einv_city_sub_div: '',
    einv_street: '',
    einv_plot_identfctn: '',
    einv_build_no: '',
    einv_postal_code: '',
    is_cust_loc_editable: 0,
    cust_loc_lat: '',
    cust_loc_long: ''
  };
  is_geolocation_activated = false;
  resultobj: any = {};
  customer_category: any;
  customer_sub_category :any;
  filteredSubCategories: any[] = [];
  selectedCategory: any;
  isSameAddr = false;
  time = Date.now();
  AllFiles = [];
  storage = {
    '0': 'No',
    '1': 'Yes'
  };

  is_sup: any;
  userType: any;
  branch_all: any;
  sales_agents: any;
  sale_agent: any;
  documentPath = "";
  enable_disable_sales_below_mrp = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];

  cust_approval_flag_types = [
    { id: 0, name: 'Unapproved' },
    { id: 1, name: 'Approved' },
  ];
  country_dec: string;
  sales_settings: any;
  inv_limit: any;
  equipment = [
    { id: 0, name: 'No' },
    { id: 1, name: 'yes' },


  ];

  cr_limit = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Greater Than 0' },
  ];

  due_limits = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Greater Than 0' },
  ];
  cust_status = [
    { id: 0, name: 'Suspend' },
    { id: 1, name: 'Active' },
  ];

  scheme_types = [
    { id: 'TIN', name: 'Tax Identification Number' },
    { id: 'CRN', name: 'Commercial registration number' },
    { id: 'MOM', name: 'Momra license' },
    { id: 'MLS', name: 'MLSD license' },
    { id: 'SAG', name: 'Sagia license' },
    { id: 'NAT', name: 'National ID' },
    { id: 'GCC', name: 'GCC ID' },
    { id: 'IQA', name: 'Iqama Number' },
    { id: 'PAS', name: 'Passport ID' },
    { id: 'OTH', name: 'Other ID' },
  ];

  up: any;
  vanLineList: any = [];
  priceGroupList: any = [];
  isSubmitInProg = false;
  tabIndex = 0;
  currencies: any;
  checkIsGL: boolean;
  other_branch_dt: any;
  status: any;
  statuss: any;
  cust_approval_status: any;
  cust_approval_flag_check: any;
  customer_id: any;
  customerDocuments: any = [];
  publicPath = "";
  cmpny: any;
  base_url: string;
  selected_customer_category: any;
  is_code_autogen: boolean=false;
  godown_all: any;
 
  constructor(private apiService: ApiService, private coreService: CoreService, private translate1: TranslateService) { }

  ngOnChanges(changes) {


    this.base_url = this.apiService.PHP_API_SERVER;
    this.cmpny = this.coreService.getUserData('cmpny');
    this.publicPath = (this.apiService.PHP_API_SERVER.indexOf('127.0.0.1') !== -1) ? '' : '/public';
    this.documentPath = this.apiService.PHP_API_SERVER + this.publicPath + '/uploads/' + this.cmpny + '/' + 'CustomerDocuments';
  
    
    this.clearForm();
    if (this.cust_id != 0) {
      this.isSubmitInProg = true;
      this.curForm = 'Update';
      this.getSingleItem(this.cust_id);
      let searchval = new FormData();

      searchval.append("cust_id", this.cust_id);
      this.apiService.isCustomerDeletable(searchval).subscribe((res) => {
        // this.delete_ledger_id = res['ledger_id'];
        this.other_branch_dt = res['other_branch'];
        this.status = res['ledger_id']


        // console.log(this.delete_ledger_id);
      });
      // console.log(id);
      // let searchval = new FormData();

      // searchval.append("cust_id", this.cust_id);
      // this.apiService.isCustomerDeletable(searchval).subscribe((res) => {
      //   this.delete_ledger_id = res['ledger_id'];
      //   // console.log(this.delete_ledger_id);
      // });
    } else {
      this.curForm = 'Create';
    }
  }

  getSingleItem(id) {
    this.apiService.getCustomer(this.cust_id).subscribe((res) => {
      this.isSubmitInProg = false;
      this.is_sup = res.data.is_sup;
      const data = res.data;

      this.cust_approval_flag_check = data.cust_approval_flag;
      this.customerDocuments = data.customer_documents;
      this.customer_id = data.cust_id;
      this.selected_customer_category = data.cust_category_id;
      if(this.selected_customer_category){
       this.filterSubCategories(this.selected_customer_category);
      }

      // console.log("aaaaaaa");
     //  console.log(data);


      console.log(res, 'res');
      this.customer = {
        id: data.cust_id,
        ledger_id: data.ledger_id,
        name: data.name,
        alias: data.alias,
        code: data.cust_code,
        email: data.email,
        mobile: data.mobile,
        vat_no: data.vat_no,
        cr_no: data.cr_no,
        cust_flag: data.cust_flag,
        default_currency_id: data.default_currency_id,
        is_general_ledger: data.display_in_all_branch,
        op_bal: Math.abs(data.opening_balance),
        op_bal_old: data.opening_balance,
        op_bal_type: (data.opening_balance < 0) ? true : false,
        inv_limit_type: (data.inv_due_limit_no) ? true : false,
        storage_eqp: (data.is_storage_eqp) ? true : false,
        inv_limit: (data.inv_due_limit_no) ? data.inv_due_limit_no : '',
        brch_id: Number(data.branch_id),
        usr_type: 0,
        is_e_invoice: (data.is_e_invoice == 1) ? true : false,
        einv_scheme_id: data.einv_scheme_id,
        einv_scheme_no: data.einv_scheme_no,
        einv_reg_name: data.einv_reg_name,
        einv_vat_no: data.einv_vat_no,
        einv_city: data.einv_city,
        einv_city_sub_div: data.einv_city_sub_div,
        einv_street: data.einv_street,
        einv_plot_identfctn: data.einv_plot_identfctn,
        einv_build_no: data.einv_build_no,
        einv_postal_code: data.einv_postal_code,
        add: {
          addr: data.cust_home_addr,
          zip: data.zip,
          city: data.city,
          country: data.country,
          state: data.state,
          state_code: data.state_code,
          fax: data.fax
        },
        del: {
          addr: data.dflt_delvry_addr,
          zip: data.dflt_delvry_zip,
          city: data.dflt_delvry_city,
          country: data.dflt_delvry_country,
          state: data.dflt_delvry_state,
          state_code: data.dflt_delvry_state_code,
          fax: data.dflt_delvry_fax
        },
        due_day: data.due_days,
        van_line: data.van_line_id,
        price_group: data.price_group_id,
        note: data.note,
        credit_limit: data.cust_credit_limit,
        default_sales_agent: data.default_sales_agent,
        gd_id: data.cust_gd_id,
        default_cus_category: data.cust_category_id,
        default_cus_sub_category: data.cust_subcat_id,
        enable_sale_below_mrp: data.enable_sale_below_mrp,
        cust_approval_flag: data.cust_approval_flag,
        cust_is_disc_limit:data.cust_is_disc_limit,
        cust_disc_limit_perc:data.cust_disc_limit_perc,
        is_cust_loc_editable: data.cust_loc_editable,
        cust_loc_lat: data.cust_loc_lat,
        cust_loc_long: data.cust_loc_long
      };
       this.is_geolocation_activated = (data.cust_loc_lat != '' && data.cust_loc_long != '') ? true : false;
    });
  }

  ngOnInit() {
    console.log('ngOnChanges this.last_cust_code');
    console.log(this.last_cust_code);
    this.country_dec = this.coreService.setDecimalLength();
    this.translate1.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.is_sup = 0;
    this.getVanLine();
    this.up = this.coreService.getUserPrivilage();
    this.userType = this.coreService.getUserData('user_type');
    this.searchBranch('');
    this.getAllAgents();
    this.getSalesSetting();
    this.getAllCustomerCategory();
    this.selected_customer_category !== undefined ?  this.filterSubCategories(this.selected_customer_category) :  this.filterSubCategories(null)
    this.apiService.listAllCurrency().subscribe((res) => {
      this.currencies = res.data;
    });
    this.apiService.getAllClientSettings().subscribe((res) => {
      if (res['data']) {
        this.is_code_autogen = (res['data']['cust_code_auto_generate']['cs_value']) ? true : false;
        this.checkIsGL = (res['data']['general_ledger_enable']['cs_value']) ? true : false;
      }
    });

  }
  ngAfterContentInit(){

    this.searchGdwn('');
   
    

  }

  getAllAgents() {
    this.apiService.getAllAgents().subscribe((res) => {
      this.sales_agents = res['data'];
    });
  }

  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
    });
  }

  getVanLine() {
    this.apiService.getAllVanlines().subscribe((res) => {
      this.vanLineList = res.data;
    });
    this.apiService.getAllPriceGroup().subscribe((res) => {
      this.priceGroupList = res.data;
    });
  }

  setOpeningbalance() {
    this.customer.op_bal = 0
  }
  searchGdwn(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      this.godown_all.push({ "gd_id": 0, 'gd_name': this.coreService.getUserData('br_default_shop_name') });
    });

  }


  searchVanLine(search: string) {
    const searchval = new FormData();
    searchval.append('vanline_name', search);
    this.apiService.getVanlinesName(searchval).subscribe((res) => {
      // console.log(res);
      this.vanLineList = res.data;
    });
  }

  getAllCustomerCategory() {
    this.apiService.listCustCategory().subscribe((res) => {
      this.customer_category = res['data'];
      this.filteredSubCategories = this.customer_sub_category;
    });
  }

  filterSubCategories(selectedCategoryId: number | null) {
    this.customer.default_cus_sub_category = 0; 
    if (this.customer.default_cus_category || selectedCategoryId) {
      let searchval = new FormData();
      searchval.append("csc_cat_id", selectedCategoryId ? selectedCategoryId.toString() : this.customer.default_cus_category.toString());
      this.apiService.listCustSubCategory(searchval).subscribe((res) => {
        this.customer_sub_category = res['data'];
        this.filteredSubCategories = this.customer_sub_category;
      });
    }

  }


  updateAddress() {
    if (this.isSameAddr) {
      this.customer.del = JSON.parse(JSON.stringify(this.customer.add));
    } else {
      // this.customer.del = {
      //   addr: '',
      //   zip: '',
      //   city: '',
      //   country: '',
      //   state: '',
      //   state_code: '',
      //   fax: ''
      // }
    }
  }

  langChange() {
    this.translate1.get(['Common.Approved', 'Common.Unapproved', 'Common.Disable', 'Common.Enable', 'Common.Suspend', 'Common.Active']).subscribe((res: string) => {


      this.enable_disable_sales_below_mrp = [
        { id: 0, name: res['Common.Disable'] },
        { id: 1, name: res['Common.Enable'] },

      ];

      this.cust_approval_flag_types = [
        { id: 0, name: res['Common.Unapproved'] },
        { id: 1, name: res['Common.Approved'] },

      ];
      this.cust_status = [
        { id: 0, name: res['Common.Suspend'] },
        { id: 1, name: res['Common.Active'] }
      ];
    });

  }

  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.sales_settings = res['data'];

        this.inv_limit = this.sales_settings.inv_due_limit;
        this.cust_approval_status = this.sales_settings.ss_van_cust_approval_enable;
      } else {

      }
      // this.updatePayment();
    });


  }

  validateAndSubmit() {

    // const searchval = new FormData(formgroup);

    // console.log("searchval");
    // console.log(searchval);

    if (this.validateFile()) {

      this.isSubmitInProg = true;
      let callFunction = '';
      if (this.curForm === 'Create') {
        callFunction = 'createCustomer';
      }
      if (this.curForm === 'Update') {
        callFunction = 'updateCustomer';
      }
      if (this.userType == 'ADMIN') {
        this.customer.usr_type = 1;
      } else {
        this.customer.usr_type = 0;

      }

      this.apiService[callFunction](this.customer).subscribe((res) => {
        // console.log(this.staff);
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          if (this.curForm === 'Create') {
            this.last_cust_code = res.last_cust_code;
          }
          $('.custPopCloseBtn').click();
          // this.getInfo(this.curpage);
          this.customerUpdated.emit();
          this.coreService.showMessage(res.message);
          this.clearForm();
          this.resultobj = {};

          this.customer_id = res.cust_id;

          const formData = new FormData();
          formData.append("cust_id", this.customer_id);
          formData.append("filedata", JSON.stringify(this.AllFiles));

          // Append all selected files to FormData
          for (let i = 0; i < this.selectedFiles.length; i++) {
            formData.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name);
          }
          if (this.customerAttachments && this.customer_id && this.selectedFiles.length > 0) {

            this.apiService.addCustAttachedFile(formData).subscribe((res) => {
              if (res.error != null) {
                this.resultobj = res.error;
              } else {
                this.selectedFiles = [];
                this.customer_id = "";
                this.AllFiles = [];
                this.slvalErrors = {};
              }

            });

          }





        }

      });
    }
  }

  onFileSelect(event: any,l) {
    // this.slvalErrors = {};
    for (let i = 0; i < event.target.files.length; i++) {
     

      if (event.target.files[i].size > 2e+6) {
        // console.error('File is too large. Over 2MB');
        alert('File is too large,Canot be upload. Over 2MB');


        // this.slvalErrors = {};
        let retVal = true;
        this.slvalErrors['file_type_' + l] = { msg: 'File is too large,Canot be upload. Over 2MB' };
        this.AllFiles[l].fileSizeError =true;
          retVal = false;
        return retVal;
      }
      else{
        // this.slvalErrors['file_type_' + l] = {};
        this.files.push(event.target.files[i]);
        this.selectedFiles.push(event.target.files[i]);
        this.AllFiles[l].fileSizeError =false;
      }


    }
  }

  clearForm() {
    this.tabIndex = 0;
    this.curForm = 'Create';
    this.customer = {
      id: '',
      ledger_id: '',
      name: '',
      alias: '',
      code: '',
      email: '',
      mobile: '',
      vat_no: '',
      cr_no: '',
      op_bal: 0,
      op_bal_old: 0,
      brch_id: 0,
      usr_type: 0,
      is_general_ledger: 0,
      op_bal_type: false,
      inv_limit_type: false,
      storage_eqp: false,
      inv_limit: null,
      default_currency_id: 0,
      cust_flag: false,
      add: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      del: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      due_day: '',
      van_line: 0,
      price_group: 1,
      note: '',
      credit_limit: 0,
      default_sales_agent: 0,
      gd_id:-1,
      default_cus_category: 0,
      default_cus_sub_category: 0,
      enable_sale_below_mrp: 0,
      cust_approval_flag: 0,
      cust_is_disc_limit:0,
      cust_disc_limit_perc:0,
      is_e_invoice: false,
      einv_scheme_id: 'CRN',
      einv_scheme_no: '',
      einv_reg_name: '',
      einv_vat_no: '',
      einv_city: '',
      einv_city_sub_div: '',
      einv_street: '',
      einv_plot_identfctn: '',
      einv_build_no: '',
      einv_postal_code: '',
      is_cust_loc_editable: 0,
      cust_loc_lat: '',
      cust_loc_long: ''
    };
    this.is_geolocation_activated = false;
  }

  addAsSupplier(customer_id) {
    this.isSubmitInProg = true;
    let searchval = new FormData();
    searchval.append("customer_id", customer_id);
    this.apiService.addAsSupplier(searchval).subscribe((res) => {

      this.isSubmitInProg = false;


      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        this.closeModal.nativeElement.click();

        this.coreService.showMessage(res.message);
        this.resultobj = {};


        this.clearForm();

      }
    });
  }
  disableOp(is_inter_ledger) {
    if (is_inter_ledger) {
      this.customer.op_bal = 0;
    }
  }
  translate() {
    console.log(this.customer.name);

    if (this.customer.name) {
      let searchval = new FormData();
      // "prd_name" here because the function is going to same product translate function:dont be confused:)
      searchval.append("prd_name", this.customer.name);
      this.apiService.translateProductName(searchval).subscribe((res) => {

        this.customer.alias = res['data'];
      });
    }
  }

  addNewFileRow() {
    this.AllFiles.push({ file_type: "", fileSizeError:false,  file_name: "", time: Date.now() });
    return true;
  }

  removeFile(l) {
    if(this.AllFiles.length == 1){
      this.slvalErrors= {};
    }
    else{
      this.slvalErrors['file_type_' + l] = {};
    }
    
    this.AllFiles.splice(l, 1);
  }

  removeDocument(i, id) {

    if (confirm('Do you wish to void this File?')) {
      this.customerDocuments.splice(i, 1);
      this.apiService.deleteCustomerDoc(id).subscribe((res) => {
        this.coreService.showMessage(res.message);
      });
    }
  }
  addNewFileRowone(l) {

    if (this.AllFiles.length > (l + 1)) {
      $('.s_input_' + (l + 1)).focus();
      $('.s_input_' + (l + 1)).select();

    }

  }


  validateFile() {

    if(!this.slvalErrors){
      this.slvalErrors = {};
    }
    let retVal = true;
    this.AllFiles.forEach((element1, l) => {
      if (element1.file_type == '') {
        this.slvalErrors['file_type_' + l] = { msg: 'Required' };
        //return false;
        retVal = false;
      }

      if (element1.file_name == '') {
        this.slvalErrors['file_name_' + l] = { msg: 'Required' };
        //return false;
        retVal = false;
      }

      if (element1.fileSizeError == true) {
        retVal = false;
      }
      
    })
    return retVal;

  }
  OBchange(){
    this.resultobj['mov_wallet']=0;
  }


}
