import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-payable-detaild',
  templateUrl: './payable-detaild.component.html',
  styleUrls: ['./payable-detaild.component.css']
})
export class PayableDetaildComponent implements OnInit {
  
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,) { }

  payable_list: any;
  country_dec: number=2;
  pgend: boolean;
  pgstart: boolean;
  pageLoadingImg: boolean;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  exportLoader: boolean;
  fileName = 'oustanding_payable.xlsx';
  payablelist: any;
  branch_display_name: any;
  branch_display_code: any;

  ngOnInit() {
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.country_dec=this.coreService.setDecimalLength();
    this.apiService.getOutstandingPayable('',1).subscribe((res) => {
      this.pageLoadingImg = false;
      this.payable_list = res;
      console.log(' this.payable_list ', this.payable_list );
      
      this.curpage = res['current_page'];
      console.log('curpage',this.curpage);
      
      this.lastpage = res['last_page'];
      this.pageFrom = res['from'];
      // this.totalData = res['total'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
  
    });

    
  }
  pageNext( pageno: any){
    this.pageLoadingImg = true;
    this.apiService.getOutstandingPayable('', pageno).subscribe((res) => {
      this.pageLoadingImg = false;
      this.payable_list = res;
      console.log(' this.payable_list ', this.payable_list );
      
      this.curpage = res['current_page'];
      console.log('curpage',this.curpage);
      this.lastpage = res['last_page'];
      this.pageFrom = res['from'];
      // this.totalData = res['total'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
  
    });
   

  }

  searchInp(e){

    let searchval = new FormData();
    searchval.append("search", e);
    this.apiService.getOutstandingPayable(searchval, 1).subscribe((res: any) => {
      this.payable_list = res;
      this.pageLoadingImg = false;

    });

  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }
  exportexcel(): void {
     
      /* table id is passed over here */
      const element = document.getElementById('export-group');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      /* save to file */
      XLSX.writeFile(wb, this.fileName);
      this.exportLoader = false;
  
    }
  exportEXCL(){
    this.exportLoader = true;
    this.exportexcel();
  }

}
