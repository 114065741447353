import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../service/core.service';
import { ApiService } from 'src/app/service/api.service';


@Component({
  selector: 'app-rep-van',
  templateUrl: './rep-van.component.html',
  styleUrls: ['./rep-van.component.css']
})
export class RepVanComponent implements OnInit {
  up: any;
  cmpny: any;
  settings: any;
  van_selfie_attendance_enable: any;

  constructor(private coreService: CoreService,private apiService: ApiService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
    this.cmpny = this.coreService.getUserData('cmpny');
    this.getSalesSetting();
  }

  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.van_selfie_attendance_enable =this.settings.ss_van_selfie_attndnc_enable;
      
      } 
    });


  }

}
