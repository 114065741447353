import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-qtn-logistic-temp1',
  templateUrl: './qtn-logistic-temp1.component.html',
  styleUrls: ['./qtn-logistic-temp1.component.css']
})
export class QtnLogisticTemp1Component implements OnInit {
  @Input()print_header_footer_display
  @Input()saleQtnData;
  @Input()print_style;
  @Input()qr_inv;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()payTypes;
  @Input()cmpny;
  @Input()branch_country;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()footImgUrl;
  @Input()sales_print_name;
  @Input()hide_item_discount;
  @Input()hide_vat;
  @Input()sales_settings;
  @Input()hide_prnt_alias;
  country_dec: any;
  base_url:string;

  // pc / dip = inch
  // inch * 25.4 = mm
  // in mm milli meter ( pixcel / dip * 25.4)
  // 130px means 130 / 96 * 25.4 =  34.3958
  // headerheightInmm = 34.4;

  // 73.5mm (278px) maximum height allowed 
  headerheightInmm = 34.4;

  // 130px means 130 / 96 * 25.4 =  31.75
  /// 73.5mm (278px) maximum height allowed 
  footerheightInmm = 31.75;

   hide_print_header_footer: any;
  hide_print_header_footer_value = [
    { label: 'Show', value: true },
    { label: 'Hide', value: false }
  ];

  cntryWiseVat={
    '1':'VAT 15%',
    '2':'VAT 5%',
    '3':'VAT 5%',
    '4':'VAT 5%',
    '6':'VAT 10%',
  }

  pagelabelBottomInmm = 26.46; // 100px
  pagelabelLeftInmm = 92.60;
  dispalyPageNo = false;
  pageLabel = "Page [page_no] of [page_total_no]";
  constructor(private coreService: CoreService,private apiService: ApiService) { }

  ngOnInit() {
    this.hide_print_header_footer=this.print_header_footer_display;
    this.country_dec=this.coreService.setDecimalLength();
   
    
    console.log(this.savedTemplate.page_no_display);
    this.base_url = this.apiService.PHP_API_SERVER;
  }

  ngOnChanges(changes) {
    if (changes['saleQtnData'] && this.saleQtnData) {
      console.log('this.print_header_footer_display ngOnChanges');
      console.log(this.print_header_footer_display);
      this.hide_print_header_footer=this.print_header_footer_display;

      console.log("branch_country" + this.branch_country);
      

      this.headerheightInmm = this.savedTemplate.header_height;
      this.footerheightInmm = this.savedTemplate.footer_height;
      this.pagelabelBottomInmm = this.savedTemplate.botom_h;
      this.pagelabelLeftInmm = this.savedTemplate.left_h;
      if (this.savedTemplate.page_no_display) {
        this.dispalyPageNo = true;
        this.pageLabel = this.savedTemplate.page_format;
      }

    }
  }

  startPrint(){

    if(this.dispalyPageNo){
      const contentHeight = document.getElementById("printContentArea").scrollHeight;
      var dpi = this.getDPI();
      var contentHeightinmm = (contentHeight/dpi * 25.4) - this.headerheightInmm - this.footerheightInmm;
      var correctionpx = 0;
      var correctionpxinmm = (correctionpx/dpi * 25.4);
      // 297 mm A4 height 
      var singlePageAvlSpaceInmm = 297 - this.headerheightInmm - this.footerheightInmm - correctionpxinmm;

      var totalPage = Math.ceil(contentHeightinmm / singlePageAvlSpaceInmm);

      // var totalPage = Math.ceil(document.getElementById("printContentArea").scrollHeight / 1123);
      // console.log(' dpi ' + dpi);
      // console.log(' content height in px : ' + contentHeight);
      // console.log(' content height in mm: ' + contentHeightinmm);
      // console.log(' page avialabe height in mm: ' + singlePageAvlSpaceInmm);
      // console.log(' total page : ' + totalPage);

      // $('.ttlpage').html('' +totalPage);
      for (var i = 1; i <= totalPage; i++) {
        var pageNumberDiv = document.createElement("div");
        var pageNodisplay = this.pageLabel.replace("[page_no]", "" + i).replace("[page_total_no]", "" + totalPage)
        var pageNumber = document.createTextNode(pageNodisplay);
        pageNumberDiv.style.position = "absolute";
        pageNumberDiv.style.color = "#000";
        //297mm A4 pageheight; 0,5px unknown needed necessary correction value; additional wanted 40px margin from bottom(own element height included)
        pageNumberDiv.style.top = "calc((" + i + " * (297mm - 0.5px)) - " + this.pagelabelBottomInmm + "mm)"; 
        pageNumberDiv.style.height = "16px";
        pageNumberDiv.appendChild(pageNumber);
        // if(i != 1){
          $('#print-sales').append(pageNumberDiv);
        // }
        pageNumberDiv.style.left = "calc(100% - (" + pageNumberDiv.offsetWidth + "px + " + this.pagelabelLeftInmm + "mm))";
      }
    }

    $('#printButton').click();
  }

  getDPI(){  
      var div = document.createElement('div');
        div.style.display = 'block';
        div.style.height = '100mm';
        div.setAttribute("id","my_mm");
        document.body.appendChild(div);
        var px = $('#my_mm').height();
      return Math.round(px/3.93901);
  }

}
