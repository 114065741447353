

import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-production-void',
  templateUrl: './production-void.component.html',
  styleUrls: ['./production-void.component.css']
})


export class ProductionVoidComponent implements OnInit {
  visibility = true;
  prdn_date = new Date();
  step3 = false;
  step2 = false;
  step1 = false;
  searchExisting = true;
  calculationDisplay = true;
  netcost: any;
  prodform_id: any;
  ttlCommAmount: any;
  output_qty: number;
  prdAll = [];
  filterProdAll = [];
  selProdIds: any = {};
  formula_all: any;
  prdIngAll = [];
  filterIngrdProd = [];
  selIngProdIds: any = {};

  staffs = [];
  filterStaffs = [];
  selStaffs: any = {};
  prdn_id:any;

  commAll = 0;
  comsnCommAmnt = 0;
  resultobj: any = {};

  prodFormula = {
    prdn_date: new Date(),
    productnId: 0,
    productnDate: 0,
    formula_id: '',
    formula_name: '',
    productOut: [],
    productIngd: [],
    isProdCommsn: false,
    // 0 for all and 1 for individual productIngd: [{
    staffs: [],
    insp_staff: null,
    ttlComm: 0,
    ttlPurchase: 0,
    miscExp: 0,
    commision: 0,
    sub:0,
    prod:0,
    prdn_purch_amount:0,
    prdn_cost:0,

  };

  info = [];
  isSubmitInProg = false;
  pageLoadingImg = false;

  curpage = 1;
  lastpage = 1;
  perpage = 5;
  totalItem = 0;
  godown_all = [];
  curForm = 'Create';
  searchStkBatch: any;
  up: any;
  country_dec: string;
  constructor(private apiService: ApiService, private coreService: CoreService,
    private datePipe: DatePipe, private modalService: NgbModal,private translate: TranslateService) { }
  ngOnInit() {
    this.visibility = false;
    this.up = this.coreService.getUserPrivilage();

    this.country_dec=this.coreService.setDecimalLength();
  }

  voidProduction(prdn_id: any) {

    var cnfrm = confirm("Are you sure you want to void this production ?");
    if (cnfrm) {

    const searchval = new FormData();
    searchval.append('prdn_id', prdn_id);
    this.apiService.voidProduction(searchval).subscribe((res) => {
      if (res.error != null) {
        this.resultobj = res.error;
        this.resultobj = this.coreService.getValidation(this.resultobj);

      } else {
        this.clearForm();
        this.coreService.createfunct(searchval, this.resultobj, res.message);

      }
   
    });
  }

  }
  listGodowns() {
    let searchval = new FormData();
    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godown_all = res['data'];


    });

  }
  // search godown
  productionPreview(prdn_id) {
    this.prodFormula = {
      prdn_date: new Date(),
      productnId: 0,
      productnDate: 0,
      formula_id: '',
      formula_name: '',
      productOut: [],
      productIngd: [],
      isProdCommsn: false,
      // 0 for all and 1 for individual productIngd: [{
      staffs: [],
      insp_staff: null,
      ttlComm: 0,
      ttlPurchase: 0,
      miscExp: 0,
      commision:0,
      sub:0,
      prod:0,
      prdn_purch_amount:0,
      prdn_cost:0,
    };
    let searchval = new FormData();
    searchval.append("prdn_id", prdn_id);
    this.apiService.productionVoidPreview(searchval).subscribe((res) => {
      this.prodFormula = res['data'];
      this.prodFormula.prdn_date = new Date(res.data.prdn_date);
      if(res['data'] != "" ){
      this.visibility = true;
    }
  

    });

  }
  clearForm() {
    this.visibility = false;
    this.prodFormula = {
      prdn_date: new Date(),
      productnId: 0,
      productnDate: 0,
      formula_id: '',
      formula_name: '',
      productOut: [],
      productIngd: [],
      isProdCommsn: false,
      // 0 for all and 1 for individual productIngd: [{
      staffs: [],
      insp_staff: null,
      ttlComm: 0,
      ttlPurchase: 0,
      miscExp: 0,
      commision:0,
      sub:0,
      prod:0,
      prdn_purch_amount:0,
      prdn_cost:0,
    };
  }

}
