import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { StockRoutingModule} from './stock-routing.module';
import { ComfunctModule} from '../.././common/comfunct/comfunct.module';


// share
import {ErpMaterialModule} from '../../material-module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';


//translation
import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
 }

 import {APP_BASE_HREF} from '@angular/common'; 
 import { AppComponent } from '../../app.component';

// validations
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


//components
import { StockDashComponent } from './stock-dash/stock-dash.component';
import { EditstockComponent } from './stock-dash/editstock/editstock.component';
import { OpeningStockComponent } from './stock-dash/opening-stock/opening-stock.component';
import { OpeningStockRemoveComponent } from './stock-dash/opening-stock-remove/opening-stock-remove.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { StockRatesComponent } from './stock-dash/stock-rates/stock-rates.component';
import { BranchStockRatesComponent } from './stock-dash/branch-stock-rates/branch-stock-rates.component';
import { MissingStockComponent } from './stock-dash/missing-stock/missing-stock.component';
import { ExcessStockComponent } from './stock-dash/excess-stock/excess-stock.component';
import { StockVerifyComponent } from './stock-dash/stock-verify/stock-verify.component';
import { ImportOpeningStockComponent } from './stock-dash/import-opening-stock/import-opening-stock.component';
import { CopyStockDifferentBranchComponent } from './stock-dash/copy-stock-different-branch/copy-stock-different-branch.component';
import { PriceGroupComponent } from './stock-dash/price-group/price-group.component';
import { PriceGroupRatesComponent } from './stock-dash/price-group-rates/price-group-rates.component';
import { WarrantyStockComponent } from './stock-dash/warranty-stock/warranty-stock.component';
import { PriceListComponent } from './stock-dash/price-list/price-list.component';
import { UpdateBranchComponent } from './update-branch/update-branch.component';
import { StockRateByExcelComponent } from './stock-dash/stock-rate-by-excel/stock-rate-by-excel.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { UpdateRatePopupComponent } from './stock-dash/update-rate-popup/update-rate-popup.component';
import { PriceGroupPopupComponent } from './stock-dash/price-group-popup/price-group-popup.component';

@NgModule({
  declarations: [StockDashComponent, EditstockComponent, OpeningStockComponent, OpeningStockRemoveComponent, StockRatesComponent, BranchStockRatesComponent, MissingStockComponent, ExcessStockComponent, StockVerifyComponent, ImportOpeningStockComponent, CopyStockDifferentBranchComponent, PriceGroupComponent, PriceGroupRatesComponent, WarrantyStockComponent,PriceListComponent,UpdateBranchComponent, StockRateByExcelComponent, UpdateRatePopupComponent, PriceGroupPopupComponent], 
  imports: [ 
    CommonModule, 
    StockRoutingModule,
    ErpMaterialModule, 
    NgSelectModule,     
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule, 
    ComfunctModule,
    SharedModule,
    DateInputsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }) 
  ],
  exports:[UpdateRatePopupComponent,PriceGroupPopupComponent],
  providers: [{provide: APP_BASE_HREF, useValue : '/' }, DatePipe] ,
  bootstrap: [AppComponent]
  
})
export class StockModule {}
