import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { User } from  '../../../model/user.model';
import { CoreService } from '../../../service/core.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  userType : any;
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  users :any;
  usr_name: any;
usr_username: any;
usr_password: any;
c_password: any;
usr_type: any;
usr_nickname: any;
usr_phone: any;
usr_mobile: any;
usr_email: any;
usr_van_line:any;
usr_address: any;
usersel: any;
pageLoadingImg:boolean;
 result = ''; 
  selectedUser: any = { usr_id :null ,usr_email:null, error:null,message:null,usr_name:null,usr_type:null,photo: null};
  values: string[];
  usertypes:number[];
  branch_all:string[];
  resultobj: {};
  defLedger:any = [];
  defBankLedger:any = [];
  usr_cash_ledger_id:any;branch_id:any;
  up: any;
  godowns: any = [];
  usr_default_godown_id:any;
  photo_err: string;
  photo: any;
  edit_img:any;
  url: any;
  base_url: string;
  sel_photo: any;
  vanLineList: any =  [];
  usr_bank_ledger_id:any;
  user_type: any;
  user_id: any;

  edit_user_types = [
    { type_id: 2, user_type: 'BRANCH ADMIN' },
    { type_id: 3, user_type: 'BRANCH USER' }
  ];
  show_component: boolean;

  constructor(private apiService: ApiService,private coreService: CoreService) { }

  ngOnInit() {
    // this.usr_default_godown_id = -1;
    // this.usr_cash_ledger_id = 0;
    this.userType =  this.coreService.getUserData('user_type');
    this.url = 'assets/theme/assets/images/team-1.jpg';
    this.coreService.getToken();  
    this.pageLoadingImg = false;
    this.getVanLine();
    this.userTypes();  
    this.searchBranch('');  
      
    this.up = this.coreService.getUserPrivilage();
    this.apiService.listGodown().subscribe((res) => {
      this.godowns = res['data'];
    });

    this.searchDefaultLedger("");
    this.searchDefaultBankLedger("");
    this.base_url = this.apiService.PHP_API_SERVER;

    this.user_type=this.coreService.getUserData('user_type');
    this.user_id=this.coreService.getUserData('usr_id');
    if(this.userType == 'ADMIN'){
      if(this.user_id==1){
        this.show_component=true;
      }else{
        this.show_component=false;
      }
      
    }else{
      this.show_component=true;
    }
    
    
     if(this.user_type=='BRANCH USER'||this.user_type=='VAN USER')
     {
      this.listUsers1();
     }else{
      this.listUsers();
     }
  }
  getVanLine() {
    this.apiService.getAllVanlines().subscribe((res) => {
      this.vanLineList = res.data;
    });
  }
  searchVanLine(search: string) {
    const searchval = new FormData();
    searchval.append('vanline_name', search);
    this.apiService.getVanlinesName(searchval).subscribe((res) => {
      // console.log(res);
      this.vanLineList = res.data;
    });
  }

  listUsers() {
    let searchval = new FormData();
    searchval.append("status", '1');
    this.apiService.getUsersList(searchval, 1).subscribe((res) => {

      this.users = res['data']['data'];
      
      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }

  listUsers1() {
    let searchval = new FormData();
    searchval.append("status", '1');
    searchval.append("user_tpe_id", this.user_id);
    this.apiService.getUsersList(searchval, 1).subscribe((res) => {

      this.users = res['data']['data'];
    });
  }
  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  pageNext(formdata: { value: any; }, pageno: any) {

    let searchval = new FormData();
    this.apiService.getUsersList(searchval, 1).subscribe((res) => {
      this.users = res['data'];
      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
  }


  createUser(form){
    this.pageLoadingImg = true;
      let searchval = new FormData(form);
      // searchval.append("usr_type", this.usr_type);
      if(!this.usr_type){
        searchval.append("usr_type", '');
      }else{
        searchval.append("usr_type", this.usr_type);
      }

      if(!this.usr_default_godown_id){
        searchval.append("usr_default_godown_id", '-1');
      }else{
        searchval.append("usr_default_godown_id", this.usr_default_godown_id);
      }
      if(!this.usr_cash_ledger_id){
        searchval.append("usr_cash_ledger_id", '0');
      }else{
        searchval.append("usr_cash_ledger_id", this.usr_cash_ledger_id);
      }      
      if(!this.usr_bank_ledger_id){
        searchval.append("usr_bank_ledger_id", '0');
      }else{
        searchval.append("usr_bank_ledger_id", this.usr_bank_ledger_id);
      }
      
      searchval.append("branch_id", this.branch_id);
      if(!this.usr_van_line){
        searchval.append("usr_van_line", '0');
      }else{
        searchval.append("usr_van_line", this.usr_van_line);
      }
      
      
      this.apiService.createUser(searchval).subscribe((user: any)=>{
        this.pageLoadingImg = false;

        if(user.error!=null)
       {       
       this.result=user.error;
      }
      else
     {   
      this.listUsers();      
     this.coreService.createfunct(form,this.result,'User Added Success'); 
     $('#ueditf').hide();  
     this.result = '';     
    }
 
      });
  
    }

  updateUser(form)
  {
  console.log('godown--'+this.selectedUser.usr_default_godown_id);
  // if(this.selectedUser.usr_default_godown_id===null||this.selectedUser.usr_default_godown_id===''){
  //   var default_gd= '-1';
  // }else{
  //   default_gd=this.selectedUser.usr_default_godown_id;
  // }
  // console.log('default_gd--'+default_gd);
    this.pageLoadingImg = true;
    let searchval = new FormData(form);
    searchval.append("usr_cash_ledger_id", this.selectedUser.usr_cash_ledger_id);
    searchval.append("usr_bank_ledger_id", this.selectedUser.usr_bank_ledger_id);
    searchval.append("usr_default_godown_id", (this.selectedUser.usr_default_godown_id===null||this.selectedUser.usr_default_godown_id==='')?'-1':this.selectedUser.usr_default_godown_id);
    searchval.append("usr_van_line", this.selectedUser.usr_van_line);
    if((this.userType =='BRANCH ADMIN') && (this.selectedUser.usr_type==2 || this.selectedUser.usr_type==3)){
      searchval.append("usr_type", this.selectedUser.usr_type);
    }
   this.apiService.updateUser(searchval).subscribe((user: any)=>{  
    this.pageLoadingImg = false;

        if(user.error!=null)
        {
        
        console.log(user.error);
        this.result=user.error;
       }
       else
      {      
        if(this.user_type=='BRANCH USER'||this.user_type=='VAN USER')
        {
          this.listUsers1();
          }else{
          this.listUsers();
        }     
        this.coreService.updatefunct(form,this.result,user.message);            
        this.values = [];              
        this.resultobj = {};
        this.result = "";
        $('.close').click();

     }

    });
   
  }

  //form search
 searchUser(search: string)
{
 let searchval = new FormData();      
      searchval.append("usr_name", search);    
       this.apiService.getUserByName(searchval).subscribe((res) => {
        this.values = res['data'];                  
       });
}

searchDefaultLedger(search: string){
  let searchval = new FormData();      
  searchval.append("ledger_name", search); 
  searchval.append("ledger_grp", '3');    

   this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
    this.defLedger = res['data'];                  
   });
}
searchDefaultBankLedger(search: string){
  let searchval = new FormData();      
  searchval.append("ledger_name", search); 
  searchval.append("ledger_grp", '4');    

   this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
    this.defBankLedger = res['data'];                  
   });

}

searchBranch(search: string)
{
 let searchval = new FormData();      
      searchval.append("branch_name", search);    
       this.apiService.getBranchByName(searchval).subscribe((res) => {
        this.branch_all = res['data'];                    
       });
}

activate(vanlineId) {
  this.apiService.changeUserStatus(vanlineId).subscribe((res) => {
    this.coreService.showMessage(res.message);
  });
}
setValues(data)
{
  this.selectedUser = data;
  // console.log(data);
  this.selectedUser.photo = data.photo;
  this.selectedUser.usr_cash_ledger_id = Number(data.usr_cash_ledger_id);
  this.selectedUser.usr_bank_ledger_id = Number(data.usr_bank_ledger_id);
  // this.selectedUser.usr_default_godown_id = Number(data.usr_default_godown_id);
  // this.selectedUser.usr_enc_rate_code = Number(data.usr_enc_rate_code);
  this.sel_photo = this.base_url + '/images/users/' + data.photo;
  
}

removeName()
{
  this.values = [];
}


  selectUser(user: User){  
    if(user){  
    this.result = '';
    $("#ueditf").show(); 
    this.selectedUser = user;
    this.selectedUser.usr_type = Number(this.selectedUser.usr_type);
    console.log(this.selectedUser);
    console.log(this.usertypes);
    } 
    this.values = [];   
  }

 

  userTypes()
  {

    if(this.coreService.getUserData('user_type')  == 'ADMIN')
    {
      this.apiService.readUsrTypes().subscribe((users: User[])=>{
        this.usertypes =  users['data']; 
       }); 
    }
    else
    {
      this.apiService.readsubUsrTypes().subscribe((users: User[])=>{
        this.usertypes =  users['data']; 
       }); 
    }
    

  
  }

  tabClick(tab: { index: number; }) {   
   this.result = ''; 
}
photoChangeEvent(fileInput: any) {
  this.photo_err = null;
  this.photo = '';
  if (fileInput.target.files && fileInput.target.files[0]) {
    // Size Filter Bytes
    const max_size = 2048000;
    const allowed_types = ['image/png', 'image/jpeg'];
    const max_height = 200;
    const max_width = 200;
    if (fileInput.target.files[0].size > max_size) {
      this.photo_err =
        'Maximum size allowed is ' + max_size / 1000 / 1024 + 'MB';
        this.url = 'assets/theme/assets/images/team-1.jpg';
      return false;
    }

    if (allowed_types.indexOf(fileInput.target.files[0].type) !== -1) { } else {
      this.photo_err = 'Only Images are allowed ( JPG | PNG )';
      this.url = 'assets/theme/assets/images/team-1.jpg';
      return false;
    }
    this.photo = fileInput.target.files[0];
    
    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0]);
      reader.onload = () => {
        this.url = reader.result;   
      };
  }
}
photoChangeEventEdit(fileInput: any) {
  this.photo_err = null;
  this.edit_img = '';
  if (fileInput.target.files && fileInput.target.files[0]) {
    // Size Filter Bytes
    const max_size = 2048000;
    const allowed_types = ['image/png', 'image/jpeg'];
    const max_height = 200;
    const max_width = 200;
    if (fileInput.target.files[0].size > max_size) {
      this.photo_err =
        'Maximum size allowed is ' + max_size / 1000 / 1024 + 'MB';
        // this.url = 'assets/theme/assets/images/team-1.jpg';
      return false;
    }

    if (allowed_types.indexOf(fileInput.target.files[0].type) !== -1) { } else {
      this.photo_err = 'Only Images are allowed ( JPG | PNG )';
      // this.sel_photo = 'assets/theme/assets/images/team-1.jpg';
      return false;
    }
    this.edit_img = fileInput.target.files[0];
    
    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0]);
      reader.onload = () => {
        this.sel_photo = reader.result;   
      };
  }
}



}
