import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-van-itemwise-sales-rep',
  templateUrl: './van-itemwise-sales-rep.component.html',
  styleUrls: ['./van-itemwise-sales-rep.component.css']
})
export class VanItemwiseSalesRepComponent implements OnInit {

  exportLoader: boolean;
  pageLoadingImg: boolean;
  repDate: any;
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  searchProducts: any[];
  cur_lang: any;
  categories: any;
  subcatagories: any;
  vans: any;
  rept_type = [
    // { id: 1, name: 'Transfer & Returns' },
    { id: 2, name: 'Sales' },
    { id: 3, name: 'Sales Return' },
  ];
  values: any;
  tot_items: any;
  tot_amt: any;
  country_dec: number=2;
  branch_display_name: any;
  summary: any;
  fileName = 'van_itemwise.xlsx';
  Expsummary: any;
  exptot_items: any;
  exptot_amt: any;
  prdName: any;
  branch_address: any;
  ExprepDate: any;
  exp_repDate: any;
  pgend: boolean;
  pgstart: boolean;
  curpage: any;
  lastpage: any;
  pageFrom: any;
  period_type: string;
  branch_all: any;
  userType: any;
  filter_branch_id: number;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.country_dec=this.coreService.setDecimalLength();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
          this.langChange();
        });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    this.filter_branch_id = 1;
    this.pageLoadingImg = true;
    this.period_type = 't';

    let searchval = new FormData();
    searchval.append("period_type", 't');
    this.apiService.vanuserSalereport(searchval,1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res['Date'];
      this.summary = res['data'];
      this.pageFrom = res['from'];
      this.curpage =  res['current_page'];
      this.lastpage = res['last_page'];

      this.tot_items =  res['tot_result']
      // this.total_qty = res['total']['total_qty'];
      this.tot_amt =  res['total_amount']

     // console.log(res['Date']);
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });


    
  }
  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.sales','Common.sales_return']).subscribe((res: string) => {    
     
      this.rept_type = [
        // { id: 1, name: 'Transfer & Returns' },
        { id: 2, name:  res['Common.sales'] },
        { id: 3, name:  res['Common.sales_return'] },
      ];
      this.periodTypes = [
        
        { id: 't', name: res['Common.today'] },
        { id: 'ld', name: res['Common.last_day'] },
        { id: 'lw', name: res['Common.last_week'] },
        { id: 'lm', name: res['Common.last_maonth'] },
        { id: 'ly', name: res['Common.last_yr'] },
        { id: 'c', name: res['Common.cust_date'] },
        { id: '', name: res['Common.all'] }
            
          ];
    });

  


  }
  pageNext(formdata: { value: any; }, pageno: any) {
console.log('formdata',formdata);

    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.vanuserSalereport(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res['Date'];
      this.summary = res['data'];
      this.pageFrom = res['from'];
      this.curpage =  res['current_page'];
      this.lastpage = res['last_page'];

      this.tot_items =  res['tot_result']
      // this.total_qty = res['total']['total_qty'];
      this.tot_amt =  res['total_amount']

     // console.log(res['Date']);
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    }
  
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.searchProducts = res.data;
    });
  }

  //form search  for category
 searchCat(search: string) {
  let searchval = new FormData();
  searchval.append("cat_name", search);
  this.apiService.getCatbyName(searchval).subscribe((res) => {
    this.categories = res['data'];     
  });  
}
selectSubCats(catid: number) {
  this.apiService.getAllSubCatbyId(catid).subscribe((res: any) => {
    this.subcatagories = res['data'];
    
  });
}
searchVans(search: string) {
  const searchval = new FormData();
  searchval.append('van_name', search);
  this.apiService.searchVans(searchval).subscribe((res) => {
    this.vans = res['data'];
  });

}
searchUser(search: string)
      {
       let searchval = new FormData();      
            searchval.append("usr_name", search);    
             this.apiService.getUserByName(searchval).subscribe((res) => {
              this.values = res['data'];                  
             });
      }
      getAllBranch() {
        this.apiService.readAllBranch().subscribe((res) => {
          this.branch_all = res['data'];
          if(this.userType=='ADMIN' ||this.userType == 'AUDITOR') 
          {
            this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
          }
        });
      }
      getFormattedUnitName(unitName: string): string {
        return unitName ? unitName.replace(/[0-9]/g, '') : '';
      }

exportexcel(): void {
           
  /* table id is passed over here */
  const element = document.getElementById('export-group');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, this.fileName);
  this.exportLoader = false;          
        
}
exportEXCL(Expformdata: { value: any; }){
  this.exportLoader = true;

  // debugger;
  Expformdata.value.export = 'export';

  if (Expformdata.value.period_type === 'c') {
    Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
    Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
  }

  this.Expsummary = [];

  this.apiService.vanuserSalereport(Expformdata.value, 1).subscribe((res: any) => {
    this.pageLoadingImg = false;
    // if(res['data']['data']){

    this.Expsummary = res['data'];
    this.exp_repDate = res.Date;
    // this.Exptotal_payment = res['data']['total_payment'];
    // this.Exptotal_pay_amnt = res['data']['total_pay_amnt'];

    this.exptot_items =  res['tot_result']
    // this.total_qty = res['total']['total_qty'];
    this.exptot_amt =  res['total_amount']

    


  setTimeout(() => {
    this.exportexcel();
  }, 3000);


  Expformdata.value.export = '';
}); 
    }

  
generatePdf(Expformdata: { value: any; }) {

      this.exportLoader = true;
    
        // debugger;
        Expformdata.value.export = 'export';
    
        if (Expformdata.value.period_type === 'c') {
          Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
          Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
        }
    
        this.Expsummary = [];
    
        this.apiService.vanuserSalereport(Expformdata.value, 1).subscribe((res: any) => {
          this.pageLoadingImg = false;
          // if(res['data']['data']){
    
          this.Expsummary = res['data'];
      this.ExprepDate = res.Date;
        if (res.data.company){
          this.branch_display_name=res.data.company.branch;

        }
        this.exptot_items =  res['tot_result']
        // this.total_qty = res['total']['total_qty'];
        this.exptot_amt =  res['total_amount']

          const heads = [
            this.translate.instant('Common.prd_name'),
            this.translate.instant('Common.invoice_date'),
            this.translate.instant('HOME.transaction_type'),
            this.translate.instant('Van.code'),
            this.translate.instant('Van.van_usr_name'),
            this.translate.instant('HOME.Invoice_no'),
            this.translate.instant('HOME.cust_num'),
            this.translate.instant('Common.customer_name'),
            this.translate.instant('Common.sync_code'),
            this.translate.instant('Common.Item_Description'),
            this.translate.instant('HOME.UOM'),
            this.translate.instant('HOME.qty'),
            this.translate.instant('Common.amnt_wtht_vat'),
            this.translate.instant('Common.Vat_Amount'),
            this.translate.instant('Table.total_amount'),
            this.translate.instant('HOME.CATAGORY'),
            this.translate.instant('HOME.SUB CATAGORY')
          ];
    
            const lblXPos = 10;
            const headerHeight = 15;
            const valueXPos = 55;
            const data = [];
        
            this.Expsummary.forEach((item,i)=>{
              if (this.translate.currentLang === "Arabic") {
                this.prdName = item.prd_alias || '';
            } else {
                this.prdName = item.prd_name || '';
            }
           
            
              data.push([
                this.prdName,
                item.date,
                item.transtyp,
                item.van_code,
                item.usr_name,
                item.inv_no,
                item.cust_phone,
                item.cust_name,
                item.prd_code,
                item.prd_desc,
                item.unit_name,
                (item.qty),
                // (item.amnt_without_vat).toFixed(this.country_dec),
                (item.transtyp == 'Sales Return' ? '-'+  item.amnt_without_vat.toFixed(this.country_dec): item.amnt_without_vat.toFixed(this.country_dec)),
                // (item.tax).toFixed(this.country_dec),
                (item.transtyp == 'Sales Return' ? '-'+  item.tax.toFixed(this.country_dec): item.tax.toFixed(this.country_dec)),
                // (item.amnt).toFixed(this.country_dec),
                (item.transtyp == 'Sales Return' ? '-'+  item.amnt.toFixed(this.country_dec): item.amnt.toFixed(this.country_dec)),
                item.cat_name,
                item.subcat_name,

              ])
            })
        
            let doc = this.excelService.getPdfObj();
        
            var addressLines = this.branch_address.split('\r\n');
            var addressText = addressLines.join(', ');
        
            // doc.setFont('Amiri');
            // doc.setFontSize(12);
            // doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
        
            doc.setFont('Amiri');
            doc.setFontSize(9);
            doc.text(addressText, 71, headerHeight + 2);
    
            doc.setFontSize(10);
            doc.text(`Branch`, lblXPos, headerHeight + 10);
            doc.setFontSize(10);
            doc.text(':  ' + this.branch_display_name ,  valueXPos, headerHeight + 10);
    
            if(this.ExprepDate){
              doc.setFontSize(10);
              doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 15);
              doc.setFontSize(10);
              doc.text(':  ' + this.ExprepDate.date1 + '   to   ' + this.ExprepDate.date2  ,  valueXPos, headerHeight + 15);
            }
    
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 20);
            doc.setFontSize(10);
            doc.text(':  ' + `${this.translate.instant('HOME.itm_vth_van_usrsale_rep')}`,  valueXPos, headerHeight + 20);
        
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Table.totl_item')}`, lblXPos, headerHeight + 25);
            doc.setFontSize(10);
            doc.text(':  ' + this.currencyPipe.transform(this.tot_items, '', '', '1.1-1'),  valueXPos, headerHeight + 25);
    
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Table.total_amount')}`, lblXPos, headerHeight + 30);
            doc.setFontSize(10);
            doc.text(':  ' + (this.tot_amt).toFixed(this.country_dec),  valueXPos, headerHeight + 30);
            
            doc = this.excelService.addTableToPdf(doc, headerHeight + 35, lblXPos, heads, data, null);
           
            doc.save(`Item with van user sale report detail.pdf`);
    
            this.exportLoader = false;
    
        Expformdata.value.export = '';
      });
      
    }

}
