import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../service/core.service';
import { ApiService } from '../../../../service/api.service';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-rep-top-products',
  templateUrl: './rep-top-products.component.html',
  styleUrls: ['./rep-top-products.component.css']
})
export class RepTopProductsComponent implements OnInit {

 
  pageLoadingImg: boolean;
  curpage: any;
  lastpage: any;
  pgstart: boolean;
  pgend: boolean;
  datas: string[];
  values: string[];
  isnoData : boolean;
  staffs:string[];
  filterStaffs:any;
  repDate:any;date1:any;date2:any;qty1:any;qty_id:any;qty2:any;crate1:any;comm_rate:any;crate2:any;
  elseBlocknoend:any;period_type:any;elseBlocknostart:any;prd_formula:any;usr_id:any;filter:any;
  formula_all: any;
  exportLoader: boolean;
  fileName= 'topselling_products.xlsx';
  Expsummary: any;
  userType: any;
  branch_all: any;
  cur_lang: string;
  products: any[];
  

 constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.readIncre(1);
   // this.getAllSatff();
  //  this.listProductFormula();
  this.userType = this.coreService.getUserData('user_type');
  this.getAllBranch();
  this.cur_lang = sessionStorage.getItem("baseLang");
  this.apiService.cur_lang.subscribe(value =>{
    this.cur_lang = value;
  })
  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];

    });
  }

  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
   

    });
  }

  readIncre(pageno)
  {
    this.pageLoadingImg = true;
    this.isnoData = false;
    let searchval = new FormData();
    this.apiService.topProducts(searchval, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.datas = res['data']['data'];      
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.repDate = res['data']['Date'];

      if(this.datas.length==0)
      this.isnoData = true;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
  }

  pageNext(formdata: { value: any; }, pageno: any) {
    formdata.value.filter = 1;   
    this.pageLoadingImg = true;
    this.isnoData = false;
    this.apiService.topProducts(formdata.value, pageno).subscribe((res: any) => {      
      this.datas = res['data']['data'];      
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.repDate = res['data']['Date'];

      this.pageLoadingImg = false;
      if(this.datas.length==0)
      this.isnoData = true;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;
  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  filterDate = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  // filter  types
  filterTypes = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' }
  ];

   //form search
   searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.values = res['data'];
    });
  }
  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }


  export(formdata: { value: any; }){
  this.exportLoader = true;
   
    // debugger;
    formdata.value.export = 'export';
    this.apiService.topProducts(formdata.value, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      // this.ExprepDate = res.data.Date;
      this.Expsummary = res['data'];
      
  
      // this.Exptot_items = res['data']['total']['tot_items'];
      // this.Exptotal_qty = res['data']['total']['total_qty'];
      // this.Exptot_purch_amt = res['data']['total']['tot_purch_amt'];
          
    setTimeout(() => {
      this.exportexcel(); 
  }, 3000);
  
  formdata.value.export = '';
 

  });




  }

   // search product
   selectProd(name: string) {

    console.log("test lang");
    console.log(this.translate.currentLang);

    const searchval = new FormData();
    searchval.append('prod_name', name);
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      
      searchval.append('alias_search', '1');
    }
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.products = res.data;
    });
  }

  // listProductFormula() { 
  //   this.productionService.getAllProductionFormula(1, 5000).subscribe((res) => {
  //     this.formula_all = res.data.data;
  //   });
  // }
  // searchFormula(search: string) {
  //   const searchval = new FormData();
  //   searchval.append('formula_name', search);
  //   this.productionService.searchProductionFormula(searchval).subscribe((res) => {
  //     this.formula_all = res.data;
  //   });
  // }

  // getAllSatff() {
  //   this.apiService.listAllStaff().subscribe((res) => {
  //     this.staffs = res.data;     
  //   });
  // }

}
