import { Component, OnInit  , ViewChild, ElementRef} from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { DatePipe } from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { empty } from 'rxjs';
@Component({
  selector: 'app-return-transfer',
  templateUrl: './return-transfer.component.html',
  styleUrls: ['./return-transfer.component.css']
})
export class ReturnTransferComponent implements OnInit {

  @ViewChild('fieldName4', { static: false }) fieldName4: ElementRef;
  @ViewChild('fieldName5', { static: false }) fieldName5: NgSelectComponent;
@ViewChild('fieldName3', { static: false }) fieldName3: ElementRef;

  display_qty: any;
  return_qty_inbase: any;
  tran_rate: any; 
  total_stock: any;
  display_stock: any;
  // pageNext: any;
  product_summary: any;
  purch_no: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  vantran_van_id: any;
  vantran_godown_id: any;
  vantranret_vantran_id: any;
  prd_id: any;
  data: any;
  default_unit: any;
  prd_base_unit: number;
  tran_id: number;
  current_stock: number;
  vanId: number;
  last_rate: any;
  last_rate_display:any;
  return_qty: any;
  showrate: any=false;

  vantran_date: any;
  return_date:any;
  van_all = [];
  vantran_purch_price: number;
  prd_barcode: any;
  vantransub_qty: any;
  vantran_purch_amount: any;
  vantransub_unit_id: number;
  total_qty = 0;
  NgForm: any;
  total_amount = 0;
  sel_gd_id = 0;
  tranArray: any;
  slno = 0;
  prd_units = [];

  prd_all = [];
  selectedItems = [];
  resDup: any;
  resultobj: any = {};
  printError = [];

  prd_name_search: any;
  alias: any;
  vanlines: any[];
  VanLine: any[];
  preview_tran: any[];
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  sl_no: number;
  box = 0;
  preview_id = 0;
  preview_date: any[];
  preview_gd: any[];
  preview_van: any[];
  preview_prize: any[];
  preview_items: any[];brcode:any;
  godown_all = [
    { "gd_id": 0, 'gd_name': this.coreService.getUserData('br_default_shop_name') }
  ];
  gdid: number;
  errObjArr = {
    tandateErr: null, tanvanErr: null,
    tangdErr: null
  };
  errObjTran = {
    fromErr: null, toErr: null,
    sameErr: null
  };

  pageLoadingImg:boolean;van_Id:any;prd_data_ean:any;
  rec_note: any;
  preview_branch: any;
  preview_tot_qty: any;
  total_base_qty: any;
  total_derived_qty: any;
  from: number;
  van_stock=0;
  preview_van_users:any;
  unit_van_stock: number;
  preview_time: any;
  vantranret_notes: any;
  cur_lang: string;
  country_dec: string;
  loading: boolean;
  prod_alias: any;
  g_settings: any;
  generalSearch_type: any;
  vanNullError: any;
  settings: any;
  tarnsfr_return_rate: any;
  is_seperate: any=0;
  van_difault_search: any;
  edit_ret_trnsfer: boolean=false;
  selctedProd: any;
  edit_prd_all: any;
  edit_item: boolean=false;
  up: any;

  constructor(private apiService: ApiService, private coreService: CoreService,
    private datePipe: DatePipe, private modalService: NgbModal, private translate: TranslateService) { }

  ngOnInit() {
    this.getGeneralSetting();
    this.getSalesSetting();
    this.up = this.coreService.getUserPrivilage();
    this.cur_lang = sessionStorage.getItem("baseLang");
this.aliasChecked()
  this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      this.aliasChecked()
    })
    this.getNxtVanTranId();
    this.listVan();
    this.listGodowns();
    this.searchVans(null);
    this.return_date = new Date();
    this.vantran_godown_id =   Number(this.coreService.getUserData('usr_default_godown_id'));

    this.country_dec=this.coreService.setDecimalLength();
    this.showrate = localStorage.van_ret_display&&localStorage.van_ret_display=='1' ? 1 : 0;
  }

  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      this.prod_alias = 'alias_true';
      this.alias='alias_checked';
    }else{
      this.prod_alias =null;
      this.alias=null;
    }
  }
  listGodowns() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godown_all = res['data'];
    });

  }
  // search godown
  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      this.godown_all.push({ "gd_id": 0, 'gd_name': this.coreService.getUserData('br_default_shop_name') });

    });

  }
  getNxtVanTranId() {
    let searchval = new FormData();
    this.apiService.getNxtVanRetTranId(searchval).subscribe((res) => {
      this.tran_id = res['data'].tran_id;
    });

  }
  listVan() {
    let searchval = new FormData();
    this.loading=true;
    this.apiService.ListAllVanReturns(searchval, 1).subscribe((res) => {
      this.loading=false;
      this.vanlines = res['data'];
      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.from = res['from'];
    });

  }


  pageNext(formdata: { value: any; }, pageno: any) { 

    let searchval = new FormData();
    this.apiService.ListAllVanReturns(searchval, pageno).subscribe((res) => {
      this.vanlines = res['data'];
      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.from = res['from'];


    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  getVanProducts(vanId: number,key:any) {   
    this.van_Id = vanId;
    this.prd_all = [];
    this.prd_id = [];
    this.last_rate = 0;
    this.last_rate_display = 0;
    this.return_qty = '';
    this.total_stock = 0;

    if(!vanId){
      this.vanNullError = "Please Select Van"
    } else {
      this.vanNullError = " "
    }

    this.vantran_purch_price = 0;
    this.prd_barcode = '';
    this.vantransub_qty = 0;
    this.current_stock = 0;

    let searchval = new FormData();
    
    if(key)
      searchval.append("search", key);
    if(this.prod_alias){
      searchval.append('alias_search', '1');
    }
// code to get all van products in van stock table even it is not transfered
    this.apiService.getVanProductsNew(searchval, vanId).subscribe((res) => {
      this.prd_all = res['data'];
    });

  }
  checkEnterClicked2(e) {
    if (e.keyCode === 13) {
      $("#plusButton2").click();
    }
  }

  searchProducts(search: string) {
    this.prd_data_ean =null;
    let searchval = new FormData();
    this.alias = $("input[name='prd_name_search']:checked").val()
    searchval.append("keyword", search);
    searchval.append("alias", this.alias);
    this.apiService.searchVanProducts(searchval).subscribe((res) => {
      this.prd_all = res['data']; 
      
    });
  }

  convertRate(vantransub_unit_id) {
    console.log('vantransub_unit_id');
    console.log(vantransub_unit_id);
    

    this.last_rate = this.tran_rate * (vantransub_unit_id.unit_base_qty);
    if(this.tarnsfr_return_rate){
      this.last_rate_display =this.tran_rate * (vantransub_unit_id.unit_base_qty);
    }else{
      this.last_rate_display =this.tran_rate * (vantransub_unit_id.unit_base_qty);
    }
    
    this.current_stock = this.total_stock / (vantransub_unit_id.unit_base_qty);
    this.display_stock = this.van_stock/(vantransub_unit_id.unit_base_qty) + " - " + vantransub_unit_id.unit_code;
    this.unit_van_stock = this.van_stock/(vantransub_unit_id.unit_base_qty);


  }

  barcodeSearchReturn(brcode: string,van_Id: string) {   
if(!van_Id)
{
  alert("Select Van First");
}
else
{
  let searchval = new FormData();
  searchval.append("brcode", brcode);
  searchval.append("van_id", van_Id);     
  this.apiService.searchVanProductsReturn(searchval).subscribe((res) => {      
   this.setValues(res['data'][0],'ean');
  console.log(res['data'][0]);
  this.prd_data_ean = res['data'][0];
    });
}

     
 
  }

  setValues(prd_data,mode) {

    // console.log(prd_data);
    if(mode=='product')
    this.prd_data_ean = null;
    
    if (empty(prd_data)) {
      this.prd_barcode = '';
      this.total_stock = '';
      this.prd_units = [];
      this.current_stock = 0;
      this.display_stock = '';
      this.last_rate = 0;
      this.tran_rate = 0;
      this.default_unit = [];
      this.van_stock=0;
    }
// console.log(prd_data);
    if(prd_data){
     // this.prd_id = prd_data.prd_name;
     this.prd_barcode = (this.translate.currentLang === 'English') ? 
     `${prd_data.prd_name} (${prd_data.prd_barcode})` :
     `${prd_data.prd_alias} (${prd_data.prd_barcode})`;
     console.log('prd_barcode',this.prd_barcode);
     
    this.total_stock = prd_data.total_stock_count;
    this.prd_units = prd_data.prd_units;
    this.current_stock = prd_data.total_stock_count;
    // this.display_stock = prd_data.total_stock_count + " - " + prd_data.base_unit.unit_display;
    this.display_stock = prd_data.van_stock + " - " + prd_data.base_unit.unit_display;
    this.van_stock=prd_data.van_stock;
    
    this.last_rate = prd_data.vantransub_purch_rate;
    if(this.tarnsfr_return_rate){
      this.last_rate_display= prd_data.bs_srate;
      this.tran_rate = prd_data.bs_srate;
    }else{
      this.last_rate_display= prd_data.vantransub_purch_rate;
      this.tran_rate = prd_data.vantransub_purch_rate;
    }
    
    
    if(prd_data.prd_default_unit){
      this.default_unit = prd_data.prd_default_unit;
      this.convertRate(this.default_unit);
    }else{
      this.default_unit = prd_data.base_unit;
    }
    
    this.fieldName3.nativeElement.focus();
    }
  
   
  }

  appendItem(prd_data, vantransub_unit_id) { 

    if(this.prd_data_ean)
    prd_data = this.prd_data_ean;   

    this.validateForm();

    if (this.printError.length <= 0) {

      this.display_qty = '';
      this.sl_no = (this.selectedItems.length) + 1;
      this.box = this.vantransub_qty;



      if (vantransub_unit_id.unit_base_qty != 1) {

        this.display_qty = vantransub_unit_id.unit_code;

        this.vantransub_qty = this.box * (vantransub_unit_id.unit_base_qty);
        //this.vantran_purch_price = this.vantran_purch_price / (vantransub_unit_id.unit_base_qty);

      }

      // if (vantransub_unit_id.unit_base_qty != 1) {
      //   this.display_qty = "(" + this.return_qty + " - " + vantransub_unit_id.unit_code + ")";
      // }

      this.last_rate = $("#last_rate").val();
      this.last_rate = this.last_rate / vantransub_unit_id.unit_base_qty;
    
      this.return_qty_inbase = this.return_qty * vantransub_unit_id.unit_base_qty;

      this.vantran_purch_amount = this.return_qty_inbase * this.last_rate;
      this.current_stock = this.current_stock - this.return_qty;
      this.total_stock = this.total_stock - this.return_qty_inbase;
      this.display_stock = this.current_stock + " - " + vantransub_unit_id.unit_code;

 

      if (this.selectedItems.length < 0) {
        var existingArray = [];
      }else {
        if(!this.is_seperate){
          existingArray = this.coreService.checkTransferReturnExist(this.selectedItems, prd_data.vantransub_branch_stock_id,
            vantransub_unit_id.sur_unit_id, this.last_rate);
        }
       
      }

      if (existingArray && existingArray.length > 0) {
        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).vantransub_qty += Number(this.return_qty_inbase);
      }
      else {
        this.selectedItems.push({
          "sl_no": this.sl_no, "prd_barcode": prd_data.prd_barcode, "prd_name": prd_data.prd_name,"prd_alias": prd_data.prd_alias,
          "vantransub_qty": this.return_qty_inbase,
          "box": this.box, "purchase_rate": this.vantran_purch_price, "purchase_amount": this.vantran_purch_amount,
          "base_unit_code": prd_data.base_unit.unit_code,
          "vantransub_stock_id": prd_data.bs_stock_id,
          "vantransub_unit_id": vantransub_unit_id.sur_unit_id,
          "vantransub_ret_rate": this.last_rate,
          "vantransub_id": prd_data.vantransub_id,
          "vantransub_prod_id": prd_data.vantransub_prod_id,
          "van_branchstock_id": prd_data.vantransub_stock_id,
          "vantransub_branch_stock_id": prd_data.vantransub_branch_stock_id,
          "vantransub_unitid":  vantransub_unit_id.sur_unit_id,
          "vantransub_purch_rate": prd_data.vantransub_purch_rate,
          "vantransub_avg_purch_rate": prd_data.vantransub_avg_purch_rate,
          "display_qty": this.display_qty,
          "unit_base": vantransub_unit_id.unit_base_qty,
          "unit_code": vantransub_unit_id.unit_code

        });
      }
      $('#gdtran-btn').show();
      this.total_qty = this.selectedItems.length;
      this.findTotalSum();
      this.prd_id='';
      this.prd_barcode='';
      this.brcode='';
      this.display_stock='';
      this.last_rate_display='';
      this.default_unit='';
  
      this.return_qty='';
      // this.fieldName4.nativeElement.focus();
     

      if(this.van_difault_search){
        this.fieldName5.focus();
      }else{
        this.fieldName4.nativeElement.focus();
      }
    }
   

  }
  popup(){
    this.clearForm();
    // setTimeout(() => {  
    //   if(this.van_difault_search){
    //     console.log('12');
        
    //     this.fieldName5.focus();
    //   }else{
    //     console.log('54');
        
    //     this.fieldName4.nativeElement.focus();
    //   }
    //  }, 500);
   
  }

  clearForm() {
    this.getNxtVanTranId();
    // this.vantran_van_id='';
    this.vantran_godown_id = Number(this.coreService.getUserData('usr_default_godown_id'));
    this.prd_id='';
    this.vantranret_vantran_id='';
    this.prd_barcode='';
    this.brcode='';
    this.display_stock='';
    this.last_rate_display='';
    this.default_unit='';
    this.vantran_van_id=0;

    this.return_qty='';

    setTimeout(() => {
      if(this.van_difault_search){
        this.fieldName5.focus();
      }else{
        this.fieldName4.nativeElement.focus();
      }
    }, 500);
    this.selectedItems = [];
    this.findTotalSum();
    this.edit_ret_trnsfer=false;
    this.edit_item=false;
  }
  validateForm() {

    this.printError = [];
    // (Number(this.return_qty) > Number(this.unit_van_stock))
    console.log(Number(this.return_qty) +'--'+ Number(this.van_stock));
    if (this.return_qty == undefined || this.return_qty == null || this.return_qty == 0) {
    
      this.printError.push({ 'vantransub_qtyErr': 'Invalid Input' });
      $('#return_qty').css("border", "1px solid red");
    } else {
      $('#return_qty').css("border", "1px solid #dedede");
    }
  }

  deleteItem(data: any) {
    var rmvitem = confirm("Are you sure you want to remove this item from list? ");
    if (rmvitem) {
    this.prd_id = [];

    this.total_qty = this.selectedItems.length;
    var type = data.sl_no,
      i;
    for (i = this.selectedItems.length - 1; i >= 0; --i) {
      if (this.selectedItems[i].sl_no == type) {
        this.selectedItems.splice(i, 1);
      }
    }
    if (this.selectedItems.length == 0)
      $('#gdtran-btn').hide();
    this.findTotalSum();
  }
  }


  vanTransfer(formData,rec_note) {



    this.validateTransfer(formData.value);
    if (this.printError.length <= 0) {
      this.pageLoadingImg = true;
      formData.value.vantranret_notes =this.vantranret_notes;
      formData.value.items = this.selectedItems;
      formData.value.vantran_price = this.total_amount;
      formData.value.total_qty = this.total_qty;
      formData.value.return_date = (formData.value.return_date) ?
      this.apiService.formatDate(formData.value.return_date) : formData.value.return_date;
      this.apiService.vanTransferReturn(formData.value).subscribe((res) => {
        this.pageLoadingImg = false;

        this.coreService.createfunct(formData, this.resultobj, res.message);
        this.selectedItems = [];
        this.total_qty = 0;
        this.total_amount = 0;
        console.log('success');
        $('#gdtran-btn').hide();
        this.ngOnInit();
        
      });
    }
    else {
    
    }
  }
 

  validateTransfer(formData) {

    this.printError = [];
    if (formData.return_date == "" || formData.return_date == undefined) {
      this.errObjArr.tandateErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      if (formData.vantran_date > new Date()) {
        this.errObjArr.tandateErr = "t";
        this.printError.push({ 'fieldErr': 'Required' });
      } else {
        this.errObjArr.tandateErr = "f";
      }
    }
    if (formData.vantran_van_id == "" || formData.vantran_van_id == undefined) {
      this.errObjArr.tanvanErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      this.errObjArr.tanvanErr = "f";
    }

    if (formData.vantran_godown_id == undefined) {
      this.errObjArr.tangdErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      this.errObjArr.tangdErr = "f";
    }
  }
  searchVans(search: string) {
    let searchval = new FormData();
    if (search) {
      searchval.append("van_name", search);
    }

    this.apiService.searchVans(searchval).subscribe((res) => {
      this.van_all = res['data'];

    });
  }
  findTotalSum() {
    this.total_amount = 0;
    this.selectedItems.forEach(element => {
      this.total_amount += Number(element.vantransub_ret_rate * element.vantransub_qty);

    });
  }
  rateDisplay(value){
    if(value){
      localStorage.setItem("van_ret_display",'1');
    }else{
      localStorage.setItem("van_ret_display",'0');
    }
   
  }
  

  getPreview(data) {


    this.preview_id = data.vantranret_id;
    this.preview_date = data.vantranret_date;
    this.preview_gd = data.gd;
    this.preview_van = data.van;
    this.preview_branch = data.branch;
    this.preview_time=data.vantranret_time;
    this.preview_prize = data.vantranret_price;
    console.log(this.preview_time);
    
    // this.preview_tot_qty = data.tot_qty;
    this.preview_tot_qty = data.total_qty;
    this.total_base_qty = data.total_base_qty;
    this.total_derived_qty = data.total_derived_qty;
    this.preview_items = data.items;
    this.preview_van_users = data.van_users;


  }

  getGeneralSetting() {

    this.apiService.getGeneralSetting().subscribe((res) => {

      // console.log(res['data']);
      this.g_settings = res['data'];
      this.generalSearch_type = this.g_settings.g_s_search_type;
      console.log(this.generalSearch_type);
      
    });

  }
  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data']; 
        this.tarnsfr_return_rate =this.settings.ss_van_return_default_rate;
        // settings to append or seperated on item grid
        this.is_seperate =this.settings.ss_van_transf_ret_duplicate_item;
        this.van_difault_search = this.settings.van_default_search; 
      }
    });
  }

  editReturnTransfer(data){
    this.edit_ret_trnsfer=true;
    // this.showTransfCont=true;
    this.tran_id=data.vantranret_id;
    this.return_date=new Date(data.vantranret_date);
    this.vantran_godown_id=data.vantranret_godown_id;
    this.vantran_van_id=0;
    this.vantran_van_id=data.vantranret_van_id;
    this.vantranret_notes=data.vantranret_notes
    this.total_amount=data.vantranret_price;
    this.vantranret_vantran_id=data.vantranret_vantran_id
    this.selectedItems=[];
    data.items.forEach((element, index) => {

      if(element.unit_type){
        this.display_qty='';
      }else{
        this.display_qty=element.base_unit_code;
      }
      this.selectedItems.push({
        
        "sl_no": index+1, 
        "prd_barcode": element.prd_barcode,
        "prd_id": element.vantranretsub_prod_id,
        "prd_name": element.prd_name,
        "prd_alias": element.prd_alias,
        "vantransub_qty": element.vantranretsub_qty,
        "box": element.unit_base_quantity,
        "base_unit_code": element.prd_base_unit_code,
        "purchase_rate": element.vantranretsub_purch_rate,
        "purchase_amount": element.vantranretsub_purch_rate,
         "vantransub_ret_rate":element.vantranretsub_rate,
        "vantransub_prod_id": element.vantranretsub_prod_id,
        "display_qty": this.display_qty,
        "vantransub_stock_id": element.vantranretsub_stock_id,
         "vantransub_branch_stock_id": element.vantransub_branch_stock_id,
        "vantransub_unit_id": element.vantranretsub_unit_id,
        "unit_base": element.unit_base_quantity,
   
        "vantransub_purch_rate": element.vantranretsub_purch_rate,
         "unit_code": element.prd_base_unit_code,
         "vantran_purch_price":element.vantranretsub_purch_rate,
      });
    });
  }

  editItem(data, i) {
    this.edit_item=true
    this.sl_no =data.sl_no;

    $('.edit_row').removeClass('sel_row');
    $('#myrow' + i).addClass('sel_row');
    if(this.selectedItems.length >0 && this.vantran_van_id)
    {
     
        const searchval = new FormData();
        searchval.append('prd_id', data.vantransub_prod_id);
        this.apiService.getVanProductsNew(searchval, this.vantran_van_id).subscribe((res) => {
          this.edit_prd_all = res['data'];
          if(this.edit_prd_all.length >0){
           this.prd_id =this.edit_prd_all[0];
           this.prd_barcode =this.edit_prd_all[0].prd_barcode;
           this.return_qty =data.vantransub_qty /data.unit_base;
           this.vantran_purch_price=data.vantran_purch_price ? data.vantran_purch_price : this.edit_prd_all[0].vantransub_avg_purch_rate;
           this.total_stock = this.edit_prd_all[0].total_stock_count;
           this.prd_units = this.edit_prd_all[0].prd_units;
           this.current_stock = this.edit_prd_all[0].total_stock_count;
           this.display_stock = this.edit_prd_all[0].van_stock + " - " + this.edit_prd_all[0].base_unit.unit_display;
           this.van_stock=this.edit_prd_all[0].van_stock;
           this.tran_rate =data.vantransub_ret_rate;
    

              this.edit_prd_all[0].prd_units.forEach((value, i) => {
              if (value.sur_unit_id == data.vantransub_unit_id) {

               this.default_unit = { "unit_code": value.unit_code, "unit_name": value.unit_name, "sur_unit_id": value.sur_unit_id, "unit_base_qty": value.unit_base_qty ,"purch_rate":0,"sale_rate":value.sur_unit_rate};

               if(this.default_unit){
                this.convertRate(this.default_unit);
               }

              };
             });
              this.fieldName3.nativeElement.focus();
           }
          
        });
       

    }

    
  
  }


  editAppendItem(prd_data, vantransub_unit_id){


    if (!vantransub_unit_id) {
      this.printError.push({ 'vantransub_unit_idErr': 'Required' });
      $('#vantransub_unit_id').css("border", "1px solid red");
    }
    else {
      $('#vantransub_unit_id').css("border", "1px solid #dedede");
    }
      if(this.prd_data_ean)
      prd_data = this.prd_data_ean;   
  
      this.validateForm();
  
      if (this.printError.length <= 0) {
  
        this.display_qty = '';
        this.box = this.vantransub_qty;

        if (vantransub_unit_id.unit_base_qty != 1) {
  
          this.display_qty = vantransub_unit_id.unit_code;
          this.vantransub_qty = this.box * (vantransub_unit_id.unit_base_qty);
  
        }

  
        this.last_rate = $("#last_rate").val();
        this.last_rate = this.last_rate / vantransub_unit_id.unit_base_qty;
        this.return_qty_inbase = this.return_qty * vantransub_unit_id.unit_base_qty;
        this.vantran_purch_amount = this.return_qty_inbase * this.last_rate;
        this.current_stock = this.current_stock - this.return_qty;
        this.total_stock = this.total_stock - this.return_qty_inbase;
        this.display_stock = this.current_stock + " - " + vantransub_unit_id.unit_code;

            if (!prd_data.prd_id) {
              $('#prdctSearch').css("border", "1px solid red");
            }else {
                 this.selectedItems.find(x => x.sl_no === this.sl_no).sl_no = this.sl_no;
                 this.selectedItems.find(x => x.sl_no === this.sl_no).prd_barcode = prd_data.prd_barcode;
                 this.selectedItems.find(x => x.sl_no === this.sl_no).prd_id = prd_data.prd_id;
                 this.selectedItems.find(x => x.sl_no === this.sl_no).prd_name = prd_data.prd_name;
                 this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_qty = this.return_qty_inbase;
                 this.selectedItems.find(x => x.sl_no === this.sl_no).box = this.box;
                 this.selectedItems.find(x => x.sl_no === this.sl_no).purchase_rate = this.vantran_purch_price;
                 this.selectedItems.find(x => x.sl_no === this.sl_no).purchase_amount = this.vantran_purch_amount;
                 this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_prod_id = prd_data.prd_id;
                 this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_stock_id = prd_data.vanstock_stock_id;
                 this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_branch_stock_id = prd_data.vantransub_branch_stock_id;
                 this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_unit_id = vantransub_unit_id.sur_unit_id;
                 this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_purch_rate = this.vantran_purch_price;
                 this.selectedItems.find(x => x.sl_no === this.sl_no).unit_base = vantransub_unit_id.unit_base_qty;
                  this.selectedItems.find(x => x.sl_no === this.sl_no).base_unit_code = prd_data.base_unit.unit_code;
                 this.selectedItems.find(x => x.sl_no === this.sl_no).unit_code = vantransub_unit_id.unit_code;
                 this.selectedItems.find(x => x.sl_no === this.sl_no).display_qty = this.display_qty;
                 this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_ret_rate = this.last_rate;
                 this.selectedItems.find(x => x.sl_no === this.sl_no).van_branchstock_id = prd_data.vantransub_stock_id;
         
        }


        this.total_qty = this.selectedItems.length;
        this.findTotalSum();
        this.prd_id='';
        this.prd_barcode='';
        this.brcode='';
        this.display_stock='';
        this.last_rate_display='';
        this.default_unit='';
        this.return_qty='';
        this.edit_item=false;
        $('#gdtran-btn').hide();
  
        if(this.van_difault_search){
          this.fieldName5.focus();
        }else{
          this.fieldName4.nativeElement.focus();
        }
      }
     
  
    }




  vanTransferReturnUpddate(formData,rec_note){

    this.validateTransfer(formData.value);
    if (this.printError.length <= 0) {

      this.pageLoadingImg = true;
      formData.value.vantranret_notes =this.vantranret_notes;
      formData.value.items = this.selectedItems;
      formData.value.vantran_price = this.total_amount;
      formData.value.total_qty = this.total_qty;
      formData.value.vantran_date = (formData.value.return_date) ?
      this.apiService.formatDate(formData.value.return_date) : formData.value.return_date;
      this.apiService.vanTransferReturnUpdate(formData.value).subscribe((res) => {
        this.pageLoadingImg = false;
        this.coreService.createfunct(formData, this.resultobj, res.message);
        this.selectedItems = [];
        this.total_qty = 0;
        this.vantran_van_id=0;
        this.total_amount = 0;
        this.edit_ret_trnsfer=false;
        this.edit_item=false;
        console.log('success');
        this.ngOnInit();
      });


      
    }




  }
  

}
