import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-qtn-general-print',
  templateUrl: './qtn-general-print.component.html',
  styleUrls: ['./qtn-general-print.component.css']
})
export class QtnGeneralPrintComponent implements OnInit {
  @Input()saleQtnData;
  @Input()print_style;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()is_prew_img;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()sales_print_name;
  @Input()sales_settings;
  @Input()base_url;
  @Input()hide_prnt_alias;
  @Input()enable_disable_qtn_note;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (changes['saleQtnData'] && this.saleQtnData) {
      this.is_prew_img = this.saleQtnData['sales_qtn_sub'].filter(x => x.prd_img_url);
    }
  }

}
