import { Component, OnInit } from '@angular/core';
import { Branch } from '../../../model/branch.model';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { DatePipe } from '@angular/common';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.css']
})
export class BranchComponent implements OnInit {
  branchData: any;
  branch_name: any;
  branch_code: any;
  branch_address: any;
  branch_notes: any;
  branch_password: any;
  branch_password_old: any;
  branchsel: any;
  result = '';
  pageLoadingImg: boolean;
  selData: any = { branch_id: null, error: null, branch_name: null, branch_code: null, message: null };
  branch_all: string[];
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  users: any;
  selectedUser: any;
  branch_open_date: any; confirm_password: any; userType: any; branch_address2: any; branch_reg_no: any; branch_tin: any; branch_fax: any;
  branch_mob: any; branch_phone: any; branch_email: any; branch_display_name: any;
  branch_logo: any;
  branchLogoUrl: any;
  country: any=[];
  br_decimel_length: any;
  br_country_id: any;
  count: any;
  user_id: any;
  show_component: boolean;
  is_e_invoice_enabled = false;
  zatca_invoice_type: any = [];
  branch_zatca_invoice_type = 2;
  branch_zatca_op_mode = 3;
  pageLoadingImg1 = false;
  scheme_types = [
    { id: 'CRN', name: 'Commercial registration number' },
    { id: 'MOM', name: 'Momra license' },
    { id: 'MLS', name: 'MLSD license' },
    { id: 'SAG', name: 'Sagia license' },
    { id: 'OTH', name: 'Other ID' },
  ];
  branch_scheme_id = 'CRN';
  is_onborad_complted: boolean;
  constructor(private datePipe: DatePipe, private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.coreService.getToken();
    this.apiService.getClientSettingBykey({ key: 'enable_e_invoice' }).subscribe((res) => {
      if (res['data']) {
        this.is_e_invoice_enabled = (res['data']['cs_value']) ? true : false;
      }
    });
    this.listUsers();
    this.selectCountry('');
    this.getBranchData();
    this.setBranchOpenDate();
    this.userType = this.coreService.getUserData('user_type');
    this.reciptPaymntcount();

    this.userType =  this.coreService.getUserData('user_type');
    this.user_id =  this.coreService.getUserData('usr_id');
    if(this.userType == 'ADMIN'){
      if(this.user_id==1){
        this.show_component=true;
      }else{
        this.show_component=false;
      }
      
    }else{
      this.show_component=true;
    }
  }

  langChange(){
    // this.translate.get(['Common.tax_invoice', 'Common.simplified_tax_invoice', 'Common.both']).subscribe((res: string) => {    
    //   this.zatca_invoice_type = [
    //     { id: 0, name: res['Common.tax_invoice'] + ' - (B2B)' },
    //     { id: 1, name: res['Common.simplified_tax_invoice'] + ' - (B2C)'},
    //     { id: 2, name: res['Common.both'] + ' - (B2B + B2C)'}
    //   ];
    // });

  }

  setBranchOpenDate() {
    let searchval = new FormData();
    this.apiService.getCompanyData(searchval).subscribe((res) => {

      this.branch_open_date = res['data']['cmp_finyear_start'];
      this.br_decimel_length = res.data.rate_decimal_length;
      this.br_country_id = res.data.country_id;

  
      this.branch_open_date = this.datePipe.transform(this.branch_open_date, 'dd/MM/yyyy');

      var from = this.branch_open_date.split("/");
      this.branch_open_date = new Date(from[2], from[1] - 1, from[0]);
 
    });
  }


  listUsers() {
    let searchval = new FormData();
    searchval.append("status", '1');
    this.apiService.getBranchList(searchval, 1).subscribe((res) => {

      this.users = res['data']['data'];

      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }
  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  pageNext(formdata: { value: any; }, pageno: any) {

    let searchval = new FormData();
    this.apiService.getBranchList(searchval, pageno).subscribe((res) => {
      this.users = res['data'];
      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
  }




  createBranch(formgroup: { value: Branch; }) {
    this.pageLoadingImg = true;

    this.apiService.createBranch(formgroup.value).subscribe((res: Branch) => {
      this.pageLoadingImg = false;


      if (res.error != null) {
        this.result = res.error;
      }
      else {
        this.listUsers();
        this.result = '';
        this.coreService.createfunct(formgroup, this.result, res.message);
      }

    });
  }


isReadOnly() {
  return this.count >= 1; // Replace 'condition' with your actual condition
}

  updateBranch(formgroup) {
    this.pageLoadingImg = true;
    // console.log(formgroup);
    // formgroup.values.branch_open_date = ;
    let searchval = new FormData(formgroup);
    if(this.userType!= 'ADMIN'){
      searchval.append('branch_open_date',this.apiService.formatDate(this.branchData.branch_open_date));
      searchval.append('br_country_id',this.branchData.br_country_id);
    }else{
      searchval.append('branch_open_date',this.apiService.formatDate(this.selData.branch_open_date));
      searchval.append('br_country_id',this.selData.br_country_id);

    }
    
    // console.log(searchval);
    this.apiService.updateBranch(searchval).subscribe((res: Branch) => {

      let profile = this.coreService.getLocalStorage('lcstg');
    
      if(this.userType!= 'ADMIN'){
        profile.branch_deci_length=this.branchData.br_decimel_length;
        profile.branch_country=this.branchData.br_country_id;
      }else{
        profile.branch_deci_length=this.selData.br_decimel_length;
        profile.branch_country=this.selData.br_country_id;
      }
      
      this.coreService.setLocalStorage('lcstg', JSON.stringify(profile));

      this.pageLoadingImg = false;
      this.listUsers();
      this.getBranchData();
      if (res.error != null) {
        this.result = res.error;
      }
      else {
        this.coreService.updatefunct(formgroup, this.result, res.message);
        $('#edit-branch').hide();
        this.result = '';
        this.branch_all = [];
        
        

      }

    });

  }

  // branch country
  selectCountry(search: string) {
    let searchval = new FormData();
    searchval.append("country_name", search);
    this.apiService.getCountry(searchval).subscribe((res) => {
      this.country = res['data'];
    });

   
    
  }

  // get decimel length
  getLength(country){
    let searchval = new FormData();
    searchval.append("country_id", country);
    this.apiService.getLength(searchval).subscribe((res) => {
      this.br_decimel_length = res.data.br_decimel_length?res.data.br_decimel_length:2;
      this.selData.br_decimel_length = res.data.br_decimel_length?res.data.br_decimel_length:2;
      this.branchData.br_decimel_length = res.data.br_decimel_length?res.data.br_decimel_length:2;
    });

   
  }

  //form search
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
    });
  }

  //Remove Name Suggestions

  removeName() {
    this.branch_all = [];
  }


  //search select
  selectBranch(res: Branch) {
    if (res) {
      this.selData = res;
      this.branch_password_old = this.selData.branch_password;
      $('#edit-branch').show();
    }
    this.branch_all = [];

  }
  getBranchData() {
    this.selectCountry('');
    let searchval = new FormData();
    searchval.append("user_branch_id", this.coreService.getUserData('branch_id'));
    this.apiService.getBranchData(searchval).subscribe((res) => {
      this.branchData = res['data'];

      this.branchData.branch_open_date = this.datePipe.transform(this.branchData.branch_open_date, 'dd/MM/yyyy');
      this.branchLogoUrl = this.apiService.PHP_API_SERVER+'/'+this.branchData.img_url;
      var from = this.branchData.branch_open_date.split("/");
      this.branchData.branch_open_date = new Date(from[2], from[1] - 1, from[0]);
      // console.log(this.branchData.branch_open_date);
      this.is_onborad_complted=false;
      if(this.branchData.branch_zatca_op_mode == 1 && this.branchData.branch_zatca_prod_secret !=''){
        this.is_onborad_complted=true;
      }
    });
  }

  reciptPaymntcount() {
    let searchval = new FormData();
    searchval.append("user_branch_id", this.coreService.getUserData('branch_id'));
    this.apiService.recieptPaymentCount(searchval).subscribe((res) => {
     this.count=res.data;

     
    });
  }
  tabClick(tab: Event) {
    this.result = '';
  }

  setValues(data) {

    this.selectCountry('');
    
    // reciept & payment count check
    let searchval = new FormData();
    searchval.append("user_branch_id", data.branch_id);
    this.apiService.recieptPaymentCount(searchval).subscribe((res) => {
     this.count=res.data;

     
    });

    this.selData = data;
    
    this.selData.branch_open_date = this.datePipe.transform(this.selData.branch_open_date, 'dd/MM/yyyy');

    var from = this.selData.branch_open_date.split("/");
    this.selData.branch_open_date = new Date(from[2], from[1] - 1, from[0]);
    console.log(this.selData.branch_open_date);

  }

  file(file) {
    // console.log($('#logo_img').val());

    //this.template.header_img = file;
    this.branch_logo = file.target.files[0];
    var render = new FileReader();

    var nn = render.readAsDataURL(file.target.files[0]);
    render.onload = (event: any) => {
      this.branchLogoUrl = event.target.result;
    }

  }

  disableBranch(data){
    var cnfrm = confirm("Are you sure you want to Disable  this Branch ?");
    if (cnfrm) {

      
      
      let searchval = new FormData();
      searchval.append("dis_branch_id", data.branch_id);
      this.apiService.disableBranch(searchval).subscribe((res: Branch) => {
        if (res.error != null) {
          
        }
        else {
          this.coreService.createfunct('', '', res.message);
          this.listUsers();
        }
        
      });

    }
  }

  zatcaOnboard(formgroup) {
    this.result = '';
    this.pageLoadingImg1 = true;
    let searchval = new FormData(formgroup);
    this.apiService.zatcaOnboard(searchval).subscribe((res: Branch) => {
      this.pageLoadingImg1 = false;
      this.listUsers();
      this.getBranchData();
      if (res.error != null) {
        this.result = res.error;
      } else {
        this.coreService.showMessage(res.message, 6000);
        $('.close').click();
        this.result = '';
        this.branch_all = [];
      }
    });
  }
  
  zatcaRenewToken(formgroup) {
    this.result = '';
    this.pageLoadingImg1 = true;
    let searchval = new FormData(formgroup);
    this.apiService.zatcaRenewToken(searchval).subscribe((res: Branch) => {
      this.pageLoadingImg1 = false;
      this.listUsers();
      this.getBranchData();
      if (res.error != null) {
        this.result = res.error;
      } else {
        this.coreService.showMessage(res.message, 6000);
        $('.close').click();
        this.result = '';
        this.branch_all = [];
      }
    });

  }
}
