
import { Component, OnInit } from '@angular/core';

import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-cust-productwise-offer',
  templateUrl: './cust-productwise-offer.component.html',
  styleUrls: ['./cust-productwise-offer.component.css']
})
export class CustProductwiseOfferComponent implements OnInit {
  customer: any;
  offers: any;
  pageLoadingImg: boolean;
  ErrorMsg: boolean;
  count: number;
  loading: boolean;
  pgend: boolean;
  pgstart: boolean;
  curpage: any;
  lastpage: any;
  pageFrom: any;

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {

    this.count = 0;
  }

  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }

  selectCustomer(cust_id){

    if(cust_id){
      this.offers=[];
      this.loading=true;
      const searchval = new FormData();
      searchval.append('cust_id', cust_id.cust_id);
      this.apiService.selectCustProductwiseOffer(searchval,1).subscribe((res) => {
        this.loading=false;
        this.offers = res.data.data;
        this.count = res.count;

        this.curpage = res['data']['current_page'];
        this.lastpage = res['data']['last_page'];
        this.pageFrom = res['data']['from'];
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
      });
    }
    

  }
  updateCustProdOffer(product_form){
    this.pageLoadingImg = true;
    this.apiService.addCustProdwiseOffer(product_form.value).subscribe((res) => {
      this.pageLoadingImg = false;
      if (!res.error) {
        this.ErrorMsg = false;
        this.coreService.showMessage('Updated Successfully');
      }
      else {
        this.ErrorMsg = true;
      }
    });

  }

  pageNext(cust_id, pageno: any) {
    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append('cust_id', cust_id.cust_id);
    this.apiService.selectCustProductwiseOffer(searchval, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.offers = res.data.data;
        this.count = res.count;


      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.pageFrom = res['data']['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }
}
