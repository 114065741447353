import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-sales-ret-general',
  templateUrl: './sales-ret-general.component.html',
  styleUrls: ['./sales-ret-general.component.css']
})
export class SalesRetGeneralComponent implements OnInit {

  @Input()salesRetPreview;
  @Input()print_style;
  @Input()qr_inv;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()payTypes;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()footImgUrl;
  @Input()tempImgUrl;
  @Input()sales_print_name;
  @Input()hide_item_discount;
  @Input()hide_vat;
  @Input()sales_settings;
  @Input()g_settings;
  country_dec: string;
  hide_prnt_itm_name: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate:TranslateService,) { }

  ngOnInit() {
    this.country_dec=this.coreService.setDecimalLength();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.langChange();
    });
  }

  ngOnChanges(changes) {

    if (changes['salesRetPreview'] && this.salesRetPreview) {
      this.hide_prnt_itm_name = this.g_settings.g_s_hide_prnt_itm_name;
    }
    
  }

}
